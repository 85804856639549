import { ref } from 'vue'
import axios from './Axios';
import offlineService, { offlineListApi } from './OfflineService';
import { addProcess } from './Processor';

class ListApi {
    constructor() {
        this.isLoading = ref(false);
        this.isSaving = false;
        if (!window.Api)
            window.Api = {};
        window.Api.List = this;
    }
    // TODO: Make less overkill:: .*
    async beforeSave() {
    }
    async afterLoad() {
    }
    async getAll() {
        this.isLoading.value = true;
        let result = offlineService.checkIsOffline ? offlineListApi.getAll() : (await axios.getBuffered('/api/lists')).data;
        await this.afterLoad(result);
        this.isLoading.value = false;
        return result;
    }
    async get(id) {
        this.isLoading.value = true;
        let result = offlineService.checkIsOffline ? await offlineListApi.get(id) : (await axios.getBuffered('/api/lists/' + id)).data;
        await this.afterLoad(result);
        this.isLoading.value = false;
        return result;
    }
    async getByName(name) {
        this.isLoading.value = true;
        let result = offlineService.checkIsOffline ? await offlineListApi.getByName(name) : (await axios.get('/api/lists/byName/' + encodeURIComponent(name))).data;
        await this.afterLoad(result);
        this.isLoading.value = false;
        return result;
    }
    async getByParentListItemId(id) {
        this.isLoading.value = true;
        let result = offlineService.checkIsOffline ? await offlineListApi.getByParentListItemId(id) : (await axios.get('/api/lists/byParentListItemId/' + encodeURIComponent(id))).data;
        await this.afterLoad(result);
        this.isLoading.value = false;
        return result;
    }
    async save(data) {
        // TODO: no offline yet
        this.isSaving = true;
        let process = addProcess("Save List: " + data.name);
        await this.beforeSave(data);
        let result = await axios.post('/api/lists/', data).finally(() => process());
        this.isSaving = false;
        return result.data;
    }
    async delete(data) {
        // TODO: no offline yet
        let key = data;
        if (typeof (data) === 'object') {
            key = data._id;
        }
        let process = addProcess("Delete List: " + data.name);
        let result = await axios.delete('/api/lists/' + key).finally(() => process());
        return result.data;
    }
    async beforeSaveItem() {
    }
    async afterLoadItem() {
    }
    async getItems() {
        // TODO: no offline yet
        this.isLoading.value = true;
        let result = await axios.getBuffered('/api/lists/item/');
        await this.afterLoadItem(result.data);
        this.isLoading.value = false;
        return result.data;
    }
    async getItem(id) {
        // TODO: no offline yet
        this.isLoading.value = true;
        let result = await axios.getBuffered('/api/lists/item/' + id);
        await this.afterLoadItem(result.data);
        this.isLoading.value = false;
        return result.data;
    }
    async getListItems(id) {
        this.isLoading.value = true;
        let result = offlineService.checkIsOffline ? await offlineListApi.getListItems(id) : (await axios.getBuffered('/api/lists/' + id + '/items')).data;
        await this.afterLoadItem(result);
        this.isLoading.value = false;
        return result;
    }
    async getListItemByValue(listId, value) {
        // TODO: no offline yet
        this.isLoading.value = true;
        let result = await axios.get('/api/lists/' + listId + '/itemByValue/' + encodeURIComponent(value));
        await this.afterLoadItem(result.data);
        this.isLoading.value = false;
        return result.data;
    }
    async getListItemByData(listId, data) {
        // TODO: no offline yet
        this.isLoading.value = true;
        let result = await axios.get('/api/lists/' + listId + '/itemByValue/' + encodeURIComponent(data));
        await this.afterLoadItem(result.data);
        this.isLoading.value = false;
        return result.data;
    }
    async saveItem(data,dontReorder) {
        // TODO: no offline yet
        this.isSaving = true;
        let process = addProcess("Save ListItem: " + data.value);
        await this.beforeSaveItem(data);
        let result = await axios.post('/api/lists/item/?reorder=' + (!dontReorder), data).finally(() => process());
        this.isSaving = false;
        return result.data;
    }
    async deleteItem(data) {
        // TODO: no offline yet
        let key = data;
        if (typeof (data) === 'object') {
            key = data._id;
        }
        let process = addProcess("Delete ListItem: " + data.value);
        let result = await axios.delete('/api/lists/item/' + key).finally(() => process());
        return result.data;
    }
}

export { ListApi }

let listApi = new ListApi();

let listSettings = {
    "site_infos": {
        dataFields:{
            '_': JSON.stringify({
                editorType: "dxTextArea",
                colSpan: 3,
                label: "Text",
                editorOptions: { minHeight: "200px" }
            })
        }
    },
    "project_deficiency": {
        allowChildren: true
    },
    ".project_deficiency": {
        allowImage: true,
        dataFields: {
            'Text': JSON.stringify({
                editorType: "dxTextArea",
                colSpan: 3,
                label: 'Text',
                editorOptions: { minHeight: "100px" }
            }),
            'Legal': JSON.stringify({
                editorType: "dxTextArea",
                colSpan: 3,
                label: 'Gesetzesgrundlagen',
                editorOptions: { minHeight: "100px" }
            }),
        },
    },
    "project_regional_council_location": {
        dataFields: {
            '_colCount': '6',
            'EMail': JSON.stringify({ colSpan: 3, label: 'E-Mail' }),
            'StreetAndNr': JSON.stringify({ colSpan: 3, label: 'Straße und Hausnummer' }),
            'City': JSON.stringify({ colSpan: 5, label: 'Ort' }),
            'ZipCode': JSON.stringify({ colSpan: 1, label: 'PLZ' }),
        },
    }
};

export { listSettings }

export default listApi;
