import { ref, watch } from 'vue'
import axios from './Axios';
import offlineService, { offlineProjectApi } from './OfflineService';
import { addProcess } from './Processor';
import { diffrenceObjects } from './Util';

class ProjectApi {
    constructor() {
        this.isDirty = ref(false);
        this.isLoading = ref(false);
        this.isSaving = false;

        if (!window.Api)
            window.Api = {};
        window.Api.ProjectAxi = axios;
        window.Api.Project = this;
    }
    // eslint-disable-next-line
    async beforeSave(data) {
    }
    // eslint-disable-next-line
    async afterLoad(data) {
    }
    async get(id) {
        this.isLoading.value = true;
        let result = offlineService.checkIsOffline ? await offlineProjectApi.get(id) : (await axios.get('/api/projects/' + id)).data;
        await this.afterLoad(result);
        let dataRef = this.attachWatchers(result);
        return dataRef;
    }
    async clearIndex() {
        this.isLoading.value = true;
        let result = (await axios.post("/api/projects/clearIndex")).data;
        console.log("[INDEX] cleared:", result);
        this.isLoading.value = false;
    }
    async createIndex() {
        this.isLoading.value = true;
        let result = (await axios.post("/api/projects/createIndex")).data;
        console.log("[INDEX] created:", result);
        this.isLoading.value = false;
    }
    async search(query, take, skip, sortFields, selectFields) {
        // NO OFFLINE NEEDED
        this.isLoading.value = true;
        let params = "?" + new URLSearchParams({ sortFields }).toString();
        if (typeof (take) === 'number') {
            params = "?" + new URLSearchParams({ skip: skip || 0, take, sortFields }).toString()
        }
        let result = (await axios.get('/api/projects/search/' + encodeURIComponent(query) + (selectFields ? ("/" + selectFields) : "") + params)).data;
        return result;
    }
    async getByNumber(number) {
        // NO OFFLINE NEEDED
        this.isLoading.value = true;
        let result = (await axios.get('/api/projects/byNumber/' + encodeURIComponent(number))).data;
        await this.afterLoad(result);
        let dataRef = this.attachWatchers(result);
        return dataRef;
    }
    async getAll() {
        this.isLoading.value = true;
        let result = offlineService.checkIsOffline ? await offlineProjectApi.getAll() : (await axios.getBuffered('/api/projects/')).data;
        await this.afterLoad(result);
        this.isLoading.value = false;
        return result;
    }
    async getMeta() {
        // TODO: no offline yet
        let result = offlineService.checkIsOffline ? await offlineProjectApi.getMeta(): (await axios.get('/api/projects/meta')).data;
        return result;
    }
    async createNew(defaultValues) {
        let data = {
            name: "",
            status: "active"
        };
        // ASK SERVER FOR DEFAULT

        // STUFF defaultValues can override
        if (defaultValues)
            Object.assign(data, defaultValues);
        // STUFF defaultValues cant override
        return this.attachWatchers(data);
    }
    async updateProtocolStateWhenOffline(data) {
        let process = addProcess("updateProtocolStateWhenOffline: " + data.name);
        let result = null;
        if (offlineService.checkIsOffline)
            result = await offlineProjectApi.save(result ? Object.assign(result, { _changed: '' }) : data).finally(() => process());
        else
            process()
        return result;
    }
    async save(data) {
        // TODO: no offline yet
        let process = addProcess("Save Project: " + data.name);
        await this.beforeSave(data);
        let result = await axios.post('/api/projects/', data).finally(() => process());
        return result.data;
    }
    attachWatchers(data) {
        if (Array.isArray(data))
            return data.map(i => this.attachWatchers(i));
        let dataRef = ref(data);
        this.isLoading.value = false;
        watch(dataRef.value, async () => {
            if (this.isSaving)
                return;
            this.isDirty.value = true;
            this.isSaving = true;
            let clone = structuredClone(dataRef.value);
            let newData = await this.save(dataRef.value);
            let diff = Object.keys(diffrenceObjects(newData, clone));                       
            if (diff.length > 5) {
                Object.assign(dataRef.value, newData);
            } else if (diff.length > 0) {
                let e = {};
                diff.forEach(k => e[k] = newData[k]);
                Object.assign(dataRef.value, e);
            }
            setTimeout(() => {
                this.isSaving = false;
            }, 1)
            this.isDirty.value = false;
        });
        return dataRef.value;
    }
}

let projectApi = new ProjectApi();
export { ProjectApi }


export default projectApi;