import { ref, watch } from 'vue'
import axios from './Axios';
import { addProcess } from './Processor';
import { parse } from '@tinyhttp/content-disposition'
import offlineService, { offlineAttachmentApi } from './OfflineService';
class AttachmentApi {
    constructor() {
        this.isDirty = ref(false);
        this.isLoading = ref(false);
        this.isSaving = false;
        this.cache = {};
        this.attachmentVersions = {};

        if (!window.Api) {
            window.Api = {};
        }
        window.Api.Attachment = this;
    }
    async get(id, noCache) {
        if (!noCache && id in this.cache && this.cache[id].value) {
            console.info("[Attachment]", "Using attachment from cache", id, this.cache[id].value);
            return this.cache[id];
        }
        this.isLoading.value = true;
        let result = offlineService.checkIsOffline ? await offlineAttachmentApi.get(id) : (await axios.get('/api/attachments/' + id)).data;
        let dataRef = this.attachWatchers(result);
        this.cache[id] = dataRef;
        return dataRef;
    }
    async getAll() {
        this.isLoading.value = true;
        // TODO: No offline yet
        let result = await axios.get('/api/attachments');
        let dataRef = this.attachWatchers(result.data);
        return dataRef;
    }
    async getByShort(id) {
        this.isLoading.value = true;
        // TODO: No offline yet
        let result = await axios.get('/api/attachments/byShort/' + id);
        let dataRef = this.attachWatchers(result.data);
        return dataRef;
    }
    async getByAssignment(assignmentType, assignment, name, noCache) {
        let cacheKey = [assignmentType, assignment, name].join("/");
        if (!noCache && cacheKey in this.cache && this.cache[cacheKey] && this.cache[cacheKey].value) {
            console.info("[Attachment]", "Using attachment from cache", assignmentType, assignment, name, this.cache[cacheKey].value);
            return this.cache[cacheKey];
        }
        this.isLoading.value = true;
        let result = offlineService.checkIsOffline ? await offlineAttachmentApi.getByAssignment(assignmentType, assignment, name) : (await axios.get('/api/attachments/byAssignment/' + assignmentType + '/' + assignment + '/' + encodeURIComponent(name))).data;
        let dataRef = this.attachWatchers(result);
        this.cache[cacheKey] = dataRef;
        return dataRef;
    }
    async getAllByAssignment(assignmentType, assignment, type) {
        this.isLoading.value = true;
        if (offlineService.checkIsOffline) {
            return this.attachWatchers(await offlineAttachmentApi.getAllByAssignment(assignmentType, assignment, type));
        }
        if (type) {
            let result = await axios.get('/api/attachments/byAssignmentType/' + assignmentType + '/' + assignment + '/' + type);
            return this.attachWatchers(result.data);
        }
        let result = await axios.get('/api/attachments/byAssignment/' + assignmentType + '/' + assignment);
        return this.attachWatchers(result.data);
    }
    async save(data) {
        let process = addProcess("Save Attachment: " + data._id);
        let result = null;
        if (!offlineService.checkIsOffline)
            result = (await axios.post('/api/attachments/', data)).data;
        result = await offlineAttachmentApi.save(result ? Object.assign(result, {_changed:''}) : data,result?true:false);
        process();
        return result;
    }
    async saveBlob(id, blob) {
        let process = addProcess("Save Blob: " + id);
        let result = null;
        if (!offlineService.checkIsOffline)
            result = (await axios.post('/api/attachments/file/' + id, blob, { headers: { 'Content-Type': blob.type } })).data;
        let offlineResult = await offlineAttachmentApi.saveBlob(id, blob, result?true:false)
        this.incrementVersion(id)
        process();
        return result || offlineResult;
    }
    async getBlob(id) {
        let result = offlineService.checkIsOffline ? await offlineAttachmentApi.getBlob(id) : (await axios.get('/api/attachments/file/' + id, { responseType: 'blob' })).data;
        return result;
    }
    async getPdfAssignment(id) {
        return (await axios.get('/api/attachments/pdfAttachment/' + id)).data;
    }
    async getThumbnailBlob(id, width, height, trim, onePage) {
        if (offlineService.checkIsOffline)
            return await offlineAttachmentApi.getBlob(id);

        let v = this.getVersion(id).value;
        let searchParams = { v, width, height,trim,onePage };
        if (!searchParams.width || !searchParams.height) {
            delete searchParams.width;
            delete searchParams.height;
        }
        if (!searchParams.trim) {
            delete searchParams.trim;
        }
        if (!searchParams.onePage) {
            delete searchParams.onePage;
        }

        return (await axios.get("/api/attachments/thumb/" + id + "?" + (new URLSearchParams(searchParams).toString()), { responseType: 'blob' })).data;
    }
    async download(id) {
        let result = await axios.get("/api/attachments/file/" + id, { responseType: 'blob' })
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";

        a.download = parse(result.headers['content-disposition']).parameters.filename;
        a.href = URL.createObjectURL(result.data);
        a.click();
        a.remove();
    }
    async downloadMultiple(ids) {
        for (let id of ids) {
            this.download(id);
        }
    }
    async delete(id) {
        let process = addProcess("Delete Attachment: " + id);
        if (id.startsWith("m_")) {
            await offlineAttachmentApi.delete(id);
        } else {
            (offlineService.checkIsOffline) ? await offlineAttachmentApi.delete(id) : await axios.delete('/api/attachments/' + id);
        }        
        process();
    }
    onVersionChange(id, cb) {
        let e = this.getVersion(id);
        return watch(e, cb);
    }
    getVersion(id) {
        if (id in this.attachmentVersions) {
            return this.attachmentVersions[id];
        }
        this.attachmentVersions[id] = ref(0);
        return this.attachmentVersions[id];
    }
    incrementVersion(id) {
        this.getVersion(id).value++;
    }
    attachWatchers(data) {
        if (Array.isArray(data))
            return data.map(i => this.attachWatchers(i));
        let dataRef = ref(data);
        this.isLoading.value = false;
        watch(dataRef.value, async () => {
            if (this.isSaving)
                return;
            this.isDirty.value = true;
            this.isSaving = true;
            let newData = await this.save(dataRef.value);
            Object.assign(dataRef.value, newData);
            setTimeout(() => {
                this.isSaving = false;
            }, 1)
            this.isDirty.value = false;
        });
        return dataRef.value;
    }
}

export { AttachmentApi }

let attachmentApi = new AttachmentApi();

export default attachmentApi