<template>
    <div v-if="laterWorkTypeData" class="area-content-body admin-project-type-template">
        <div class="admin-project-type-template-list">
            <div class="admin-project-type-template-head">
                <DxButton icon="back" @click="back" hint="Zurück"/>
                <span class="admin-project-type-template-title">Späteren Arbeiten Vorlage | </span>
                <HeaderFieldEditor inputHint="Name" ref="NameField" :allowEdit="true" v-model:dataSource="laterWorkType.name" />
                <DxButton icon="trash" @click="deleteTemplate" hint="Vorlage löschen" />
                <DxButton ref='saveButton' icon="save" :disabled="true" @click="saveCategory" hint="Vorlage speichern"/>
            </div>
            <div class="admin-list">
                <div v-for="templateCategory in laterWorkTemplate.categories" :key="templateCategory.id" class="admin-list-item" @click.prevent="selectCategory(templateCategory.id)" :class="{'active':category&&category.id===templateCategory.id,'admin-list-item-removed':!laterWorkType.selectedCategories.includes(templateCategory.id)}">
                    <div v-if="laterWorkType.selectedCategories.includes(templateCategory.id)" class="admin-list-item-button material-symbols-outlined" @click.prevent.stop="deleteCategory(templateCategory)" title="Kategorie löschen">delete</div>
                    <div v-if="!laterWorkType.selectedCategories.includes(templateCategory.id)" class="admin-list-item-button material-symbols-outlined" @click.prevent.stop="addCategory(templateCategory)" title="Kategorie wiederherstellen">restore</div>
                    <div class="admin-list-item-content">{{templateCategory.name}}</div>
                </div>
            </div>
        </div>
        <div v-if="category && laterWorkType" class="admin-project-type-hazard-group" :class="{'hazard-group-removed':!laterWorkType.selectedCategories.includes(category.id)}">
            <sub-categories-list
                :assignmentOverride="{assignment:'laterWorkTypeImages',assignmentType:'system',type:laterWorkType._id}"
                :altAssignmentOverrides="[{assignment:'laterWorkTemplateImages',assignmentType:'system',type:laterWorkType._id}]"
                :items="category.subCategories"
                :value-expr="'id'" 
                :show-selection-controls="true" 
                :enabledExpression="(h)=>!laterWorkType.removedSubCategories.includes(h.id)"
                @security-added="addSecurity" 
                @security-removed="removeSecurity"
                @subCategory-added="addSubCategory"
                @subCategory-removed="removeSubCategory"
                :read-only-fields="['name','safetyRegulation','note']"  />
        </div>
    </div>
    <div v-else>
        Keine Daten
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import SubCategoriesList from '../components/sub-categories-list.vue'
    import HeaderFieldEditor from '../components/header-field-editor.vue'
    import laterWorkTypeApi from '../services/LaterWorkTypeApi'
    import laterWorkTemplateApi from '../services/LaterWorkTemplateApi'    
    import DxButton from 'devextreme-vue/button'
    import { confirm } from 'devextreme/ui/dialog'    
    import { isEqual } from 'lodash'

    export default defineComponent({
        components: {
            SubCategoriesList,
            HeaderFieldEditor,            
            DxButton
        },
        data() {
            return {
                laterWorkTemplate: null,
                laterWorkType: null,
                laterWorkTypeOrig: null,
                laterWorkTypeData: null,
                category: null,
                allowEdit: null,
                hasChanges: false,
                isNew: false
            };
        },
        async beforeRouteUpdate() {
            if (this.hasChanges) {
                return await confirm("Es gibt ungespeicherte Änderungen - sollen diese verworfen werden?", "Änderungen verwerfen?")
            }
            return true;
        },
        watch: {
            async '$route.params'() {
                this.fetchData();
            },
            'laterWorkType': {
                handler() {
                    if (this.laterWorkType && this.laterWorkTypeOrig) {
                        this.hasChanges = !isEqual(this.laterWorkTypeOrig,this.laterWorkType);
                        if(this.$refs.saveButton){
                            this.$refs.saveButton.instance.option("disabled",!this.hasChanges);
                        }
                    }
                },
                deep: true
            },
            'category.subCategories': {
                handler() {
                    if(!this.category || !this.category.subCategories )
                        return;
                    for(let subCategory of this.category.subCategories ){
                        let origCategory = this.laterWorkTemplate.categories.find(g=>g.id === this.category.id);
                        let origSubCategory = origCategory.subCategories .find(h=>h.id === subCategory.id);
                        let fieldsToCheck = ['occurrenceId','dangerIds','planId'];
                        let adjustments = {};
                        for(let fieldToCheck of fieldsToCheck){
                            if(subCategory[fieldToCheck] !== origSubCategory[fieldToCheck]){
                                adjustments[fieldToCheck] = subCategory[fieldToCheck];
                            }
                        }
                        let currentAdjustments = structuredClone(this.laterWorkType.subCategoryAdjustments);
                        for(let k in currentAdjustments[subCategory.id]){
                            if(currentAdjustments[subCategory.id][k] === null)
                                delete currentAdjustments[subCategory.id][k]
                        }
                        if(Object.keys(adjustments).length === 0 && subCategory.id in currentAdjustments){
                            delete this.laterWorkType.subCategoryAdjustments[subCategory.id]
                        }else if(Object.keys(adjustments).length > 0 && JSON.stringify( currentAdjustments[subCategory.id]) != JSON.stringify(adjustments)){
                            this.laterWorkType.subCategoryAdjustments[subCategory.id]=adjustments;
                        }
                    }
                },
                deep: true
            }
        },
        async mounted() {
            this.fetchData();
        },
        methods: {
            async fetchData() {         
                if (this.$route.params.id) {
                    if (this.$route.params.id.startsWith("new:")) {
                        this.add(this.$route.params.id.substr(4));
                        return;
                    }
                    this.laterWorkType = structuredClone(await laterWorkTypeApi.get(this.$route.params.id));
                    this.laterWorkTypeOrig = structuredClone(this.laterWorkType);
                    this.laterWorkTemplate = structuredClone(await laterWorkTemplateApi.get(this.laterWorkType.laterWorkTemplateId));
                    this.laterWorkTypeData = structuredClone(this.laterWorkTemplate);
                    let removedSecurities = this.laterWorkType.removedSecurities;
                    
                    if (this.$route.params.category) {
                        this.category = this.laterWorkTypeData.categories.find(g => g.id === this.$route.params.category);
                        if (this.category) {
                            for (let subCategory of this.category.subCategories ) {
                                if(!subCategory.securities)
                                    subCategory.securities=[];
                                subCategory.securities = Array.from(subCategory.securities.filter(m => !removedSecurities.includes(m.id)).map(m => m.id));
                                subCategory.allowedOptions = this.laterWorkTemplate.categories.find(g => g.id === this.$route.params.category).subCategories .find(h => h.id == subCategory.id).securities;
                                if(subCategory.id in this.laterWorkType.subCategoryAdjustments){
                                    for(let key in this.laterWorkType.subCategoryAdjustments[subCategory.id]){
                                        if(this.laterWorkType.subCategoryAdjustments[subCategory.id][key] !== null && subCategory[key] != this.laterWorkType.subCategoryAdjustments[subCategory.id][key]){   
                                            subCategory[key]=this.laterWorkType.subCategoryAdjustments[subCategory.id][key];
                                        }                                            
                                    }
                                }
                            }
                        }                            
                    } else {
                        let g = this.laterWorkTemplate.categories.find(() => true);
                        if (g)
                            this.selectCategory(g.id)
                    }
                } else {
                    this.laterWorkTemplate = null;
                    this.category = null;
                }
            },
            back() {
                this.$router.push("/Admin/Templates");
            },
            async deleteTemplate() {
                if (await confirm("Soll der Projekttyp gelöscht werden?", "Vorlage Löschen?")) {
                    await laterWorkTypeApi.delete(this.laterWorkType);
                    this.back();
                }
            },
            async add(laterWorkTemplateId) {
                let tmpl = await laterWorkTemplateApi.get(laterWorkTemplateId);
                let item = await laterWorkTypeApi.save({ name: 'Neu', laterWorkTemplateId: laterWorkTemplateId, selectedCategories: tmpl.categories.map(hg=>hg.id) });
                this.$router.push("/Admin/LaterWorkTypes/" + item._id);
                let checkI = setInterval(()=>{
                    if(this.$refs.NameField){
                        this.$refs.NameField.focus(true);
                        clearInterval(checkI);
                    }                    
                },10);
            },
            async selectCategory(id) {
                if (!this.laterWorkType)
                    throw new Error("No laterWorkType!", this.laterWorkType);
                this.$router.push("/Admin/LaterWorkTypes/" + this.laterWorkType._id + "/" + id)
            },
            async deleteCategory(i) {
                if (!this.laterWorkType.selectedCategories)
                    throw new Error("No Removed!", this.laterWorkType);
                if (this.laterWorkType.selectedCategories.findIndex(g => g === i.id) == -1)
                    throw new Error("Not Removed!", i.id);
                this.laterWorkType.selectedCategories.splice(this.laterWorkType.selectedCategories.findIndex(g => g === i.id), 1);     
            },
            async addCategory(i) {
                if (!this.laterWorkType.selectedCategories)
                    throw new Error("No Removed!", this.laterWorkType);
                if (this.laterWorkType.selectedCategories.findIndex(g => g === i.id) >= 0)
                    throw new Error("Already Removed!", i.id);
                this.laterWorkType.selectedCategories.push(i.id);        
            },
            async addSubCategory(subCategory) {
                if (!this.category)
                    throw new Error("No Category!", this);
                if (this.laterWorkType.removedSubCategories .includes(subCategory.id))
                    this.laterWorkType.removedSubCategories .splice(this.laterWorkType.removedSecurities.indexOf(subCategory.id), 1);
            },
            async removeSubCategory(subCategory) {
                if (!this.category)
                    throw new Error("No Category!", this.laterWorkType);
                if (!this.laterWorkType.removedSubCategories .includes(subCategory.id))
                    this.laterWorkType.removedSubCategories .push(subCategory.id);
            },
            async addSecurity(i) {
                if (!this.category)
                    throw new Error("No Category!", this);
                if (this.laterWorkType.removedSecurities.includes(i))
                    this.laterWorkType.removedSecurities.splice(this.laterWorkType.removedSecurities.indexOf(i),1);
            },
            async removeSecurity(i) {
                if (!this.category)
                    throw new Error("No Category!", this);
                if (!this.laterWorkType.removedSecurities.includes(i))
                    this.laterWorkType.removedSecurities.push(i);
            },
            async saveCategory() {
                if (!this.laterWorkType)
                    throw new Error("No laterWorkTemplate!", this);
                await laterWorkTypeApi.save(this.laterWorkType);
                await this.fetchData();
            }
        },
    });
</script>

<style>
    .admin-project-type-template-head {
        display: grid;
        grid-template-columns: max-content max-content minmax(0,1fr) max-content max-content;
        align-items: center;
        margin-bottom: 10px;
    }

        .admin-project-type-template-head > * {
            margin: 0 5px;
        }

        .admin-project-type-template-head .header-field-editor {
        }

    .admin-project-type-template {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: minmax(0,1fr);
    }

    .admin-project-type-hazard-group {
        display: grid;
        grid-template-rows: minmax(0,1fr) max-content;
        grid-template-columns: minmax(0,1fr);
        padding-right: 10px;
    }

    .hazards-list {
        overflow: auto;
        padding: 0 20px;
        background-color: var(--color-background-light);
        border-radius: var(--default-border-radius);
    }

    .admin-project-type-template-list {
        display: grid;
        grid-template-rows: max-content minmax(0,1fr);
        grid-template-columns: minmax(0,1fr);
    }

    .admin-project-type-template-list {
    }

    .admin-list-item {
        display: grid;
        align-items: center;
        grid-template-columns: max-content minmax(0,1fr) max-content;
        grid-auto-flow: column;
        padding: 5px;
    }

        .admin-list-item.active {
            background-color: var(--color-background-light);
        }

        .admin-list-item:hover {
            background-color: var(--color-background-lighter);
        }

    .admin-list-item-button {
        transition: all 0.1s;
        padding: 3px;
        color: var(--color-text);
        background: none;
        border-radius: var(--default-border-radius);
        opacity: 0;
    }

    .admin-list-item:hover .admin-list-item-button {
        opacity: 1;
    }

    .admin-list-item-button:hover {
        background: var(--color-background-light);
    }

    .admin-list-item-content {
        padding: 0 10px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .header-field-editor {
        font-size: 1rem;
        padding: 6px 11px;
        margin: 4px;
    }

    .admin-list-item-removed {
        color: var(--color-error)
    }
    .hazard-group-removed {
        opacity: 0.2;
        pointer-events: none;
        user-select: none;
    }
</style>
