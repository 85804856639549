<template>
    <div class="area-content-head head-overview">
        <div class="head-toolbar-large">
            <router-link :to="'/Admin/'+item.context" v-for="item in items" v-bind:key="item.context" class="head-toolbar-item" :class="{'disabled':!item.enabled}">
                <div class="head-toolbar-item-icon material-symbols-outlined">{{item.icon}}</div>
                <div v-if="!item.subLabel" class="head-toolbar-item-label">{{item.label}}</div>
                <div v-if="item.subLabel" class="head-toolbar-item-label-main">{{item.label}}</div>
                <div v-if="item.subLabel" class="head-toolbar-item-label-sub">{{item.subLabel}}</div>
            </router-link>
        </div>
        <div class="head-placeholder"></div>
        <head-component />
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import headComponent from '../components/head-component.vue';

    const items = [
        {
            label: "Mitarbeiter",
            subLabel: "Verwaltung",
            icon: "manage_accounts",
            context: "",
            enabled: true,
        },
        {
            label: "Mandanten",
            subLabel: "Verwaltung",
            icon: "groups",
            context: "Tenants",
            enabled: true,
        },
        {
            label: "Template",
            subLabel: "Verwaltung",
            icon: "contract_edit",
            context: "Templates",
            enabled: true
        },
        {
            label: "Dropdown",
            subLabel: "Verwaltung",
            icon: "expand_circle_down",
            context: "Lists",
            enabled: true
        }
    ]

    export default defineComponent({
        components: {
            headComponent
        },
        data() {
            return {
                items
            };
        },
        created() {
        },
        methods: {
        }
    });
</script>

<style>
    .area-content-head.head-overview {
        border: none;
    }

</style>
