<template>
    <div v-if="$root.project&&formData" class="area-content-body projects-sige-plan-planning">
        <div class="projects-sige-plan-planning-main">
            <div class="project-head" :class="{dirty:$root.isDirty}">
                <header-field-editor class="project-number-edit" :input-hint="'Projekt-Nr.'" v-model:data-source="$root.project.number" :allow-edit="false" />
                <div class="v-line"></div>
                <span class="headline">Finale Unterlage</span>
                <div>
                    <span class="head-toolbar-button-compact" @click="generateDocument" title="Dokument erstellen">description</span>
                </div>
                <div>
                    <span class="head-toolbar-button-compact" @click="generatePdf" title="PDF erstellen">picture_as_pdf</span>
                </div>
                <attachments-button />
            </div>
            <div class="projects-sige-plan-planning-scroll-contain">
                <div class="admin-list">
                    <div class="sige-template-select-contain">
                        <dx-select-box v-if="!planningExists" v-model:value="formData.laterWorkTypeId" :items="laterWorkTypes" @value-changed="getDataFromTemplate" display-expr="name" value-expr="_id" placeholder="Vorlage auswählen" />
                        <dx-button v-else-if="!formData || !formData.categories || formData.categories.length == 0" @click="getData">Daten aus Planungsphase übernehmen</dx-button>
                        <div v-else></div>
                        <div v-show="laterWorkTemplate && formData.categories && laterWorkTemplate.categories.some(hg=>!formData.categories.find(fhg=>fhg.id===hg.id))" class="head-toolbar-button-compact material-symbols-outlined" @click.prevent.stop="showRestore()" title="Wiederherstellen">auto_delete</div>
                    </div>
                    <div v-for="templateCategory in formData.categories" :key="templateCategory.id" class="admin-list-item" @click.prevent="selectCategory(templateCategory.id)" :class="{'active':category&&category.id===templateCategory.id}">
                        <div class="admin-list-item-button material-symbols-outlined" @click.prevent.stop="deleteCategory(templateCategory)" title="Kategorie löschen">delete</div>
                        <div class="admin-list-item-content">{{templateCategory.name}}</div>
                        <div v-show="laterWorkTemplate && formData.categories && laterWorkTemplate.categories.find(hg=>hg.id===templateCategory.id).subCategories.some(h=>!templateCategory.subCategories.find(fh=>fh.id===h.id))" class="admin-list-item-button material-symbols-outlined" @click.prevent.stop="showRestore(templateCategory)" title="Wiederherstellen">auto_delete</div>
                    </div>
                </div>

                <image-viewer :attachment="{assignment:$root.project._id,assignmentType:'project',name:'ProjectLaterWorkCoverSheet.png'}" allowed-types="image/*" :allow-upload="true" :allow-drop="true" :allow-edit="true" :allow-clear="true" :allow-full-screen="true" height="200px"></image-viewer>


                <dx-popup v-model:visible="restoreVisible" :width="900" :height="450" :hide-on-outside-click="true" :show-close-button="true" title="Elemente wiederherstellen">
                    <template #content>
                        <div v-if="restoreFromCategory && laterWorkTemplate && restoreVisible">
                            <dx-button v-for="subCategory in laterWorkTemplate.categories.find(hg=>hg.id===restoreFromCategory.id).subCategories.filter(h=>!restoreFromCategory.subCategories.find(fh=>fh.id===h.id))" :key="subCategory.id" :text="subCategory.name" icon="undo" @click="restoreSubCategory(restoreFromCategory,subCategory)" hint="Unterkategorie wiederherstellen" />
                        </div>
                        <div v-else-if="laterWorkTemplate && restoreVisible">
                            <dx-button v-for="category in laterWorkTemplate.categories.filter(hg=>!formData.categories.find(fhg=>fhg.id===hg.id))" :key="category.id" :text="category.name" icon="undo" @click="restoreCategory(category)" hint="Kategorie wiederherstellen" />
                        </div>
                    </template>
                </dx-popup>
            </div>
        </div>
        <div v-if="category" class="admin-project-type-hazard-group">
            <sub-categories-list v-model:items="category.subCategories"
                                 :read-only-fields="['name','safetyRegulation','note']"
                                 :show-selection-controls="true"
                                 :get-allowed-options="getAllowedSubCategoryOptions"
                                 :assignmentOverride="{assignment:$root.project._id,assignmentType:'project',type:'laterWork'}"
                                 :altAssignmentOverrides="[{assignment:'laterWorkTypeImages',assignmentType:'system',type:formData.laterWorkTypeId},{assignment:'laterWorkTemplateImages',assignmentType:'system',type:laterWorkTemplate._id}]" />
        </div>
    </div>
    <div v-else class="area-content-body projects-sige-plan-planning">
        Daten werden geladen...
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import HeaderFieldEditor from '../components/header-field-editor.vue';
    import ImageViewer from '../components/image-viewer.vue';
    import AttachmentsButton from '../components/project-attachments-button.vue';
    import SubCategoriesList from '../components/sub-categories-list.vue';
    import { addProcess, afterProcessingAsync } from '../services/Processor';
    import projectLaterWorkApi from '../services/ProjectLaterWorkApi';
    import projectLaterWorkPlanningApi from '../services/ProjectLaterWorkPlanningApi';
    import laterWorkTypeApi from '../services/LaterWorkTypeApi';
    import { confirm } from 'devextreme/ui/dialog'
    import laterWorkTemplateApi from '../services/LaterWorkTemplateApi';
    import { isEqual } from 'lodash';
    import { DxPopup } from 'devextreme-vue/popup';
    import { DxSelectBox } from 'devextreme-vue/select-box';
    import { DxButton } from 'devextreme-vue/button';
    import attachmentApi from '../services/AttachmentApi';
import notify from 'devextreme/ui/notify';

    export default defineComponent({
        components: {
            SubCategoriesList,
            HeaderFieldEditor,
            AttachmentsButton,
            DxPopup,
            DxButton,
            DxSelectBox,
            ImageViewer
        },
        watch: {
            async '$route.params'() {
                if (this.$route.path.match(/\/final(\/|$)/i))
                    this.fetchData();
            },
            '$root.project': {
                async handler() {
                    this.fetchData();
                }
            },
            'formData': {
                async handler() {
                    if (this.projectLaterWork && this.formData) {
                        if (!isEqual(this.projectLaterWork, this.formData)) {                            
                            if (this.process)
                                this.process();

                            this.process = addProcess("Save ProjectLaterWork", true);
                            Object.assign(this.projectLaterWork, structuredClone(this.formData));
                            this.process();
                        }
                    }
                },
                deep: true
            }
        },
        data() {
            return {
                formData: null,
                process: null,
                planningExists: false,
                projectLaterWork: null,
                laterWorkTemplate: null,
                laterWorkTypes: [],
                category: null,
                restoreFromCategory: null,
                restoreVisible: false
            };
        },
        async created() {
            if (this.$root.project && this.$route.params.id == this.$root.project._id) {
                this.fetchData();
            } else {
                this.formData = null;
                this.$root.loadProject(this.$route.params.id);
            }
        },
        methods: {
            async getDataFromTemplate() {
                let laterWorkType = await laterWorkTypeApi.get(this.formData.laterWorkTypeId);
                let laterWorkTemplate = structuredClone(await laterWorkTemplateApi.get(laterWorkType.laterWorkTemplateId));
                this.formData.categories = laterWorkTemplate.categories.filter(hg => laterWorkType.selectedCategories.includes(hg.id));
                for (let category of this.formData.categories) {
                    if (!category.subCategories)
                        continue;
                    category.subCategories = category.subCategories.filter(h => !laterWorkType.removedSubCategories.includes(h.id));
                    for (let subCategory of category.subCategories) {
                        if (!subCategory.securities)
                            continue;
                        subCategory.securities = subCategory.securities.filter(m => !laterWorkType.removedSecurities.includes(m.id))
                        if (subCategory.id in laterWorkType.subCategoryAdjustments) {
                            for (let k in laterWorkType.subCategoryAdjustments[subCategory.id]) {
                                if (laterWorkType.subCategoryAdjustments[subCategory.id][k] !== null)
                                    subCategory[k] = laterWorkType.subCategoryAdjustments[subCategory.id][k];
                            }
                        }
                    }
                }
                this.formData.projectId = this.$route.params.id;
                this.projectSiGePlan = structuredClone(await projectLaterWorkApi.save(this.formData))
                this.$nextTick(async () => {
                    await afterProcessingAsync()
                    this.fetchData();
                })  
            },
            async getData() {
                let planningData = structuredClone(await projectLaterWorkPlanningApi.get(this.$route.params.id));
                let currentData = await projectLaterWorkApi.get(this.$route.params.id);
                if (currentData) {
                    planningData._id = currentData._id;
                }
                await projectLaterWorkApi.save(planningData);
                await this.fetchData();
            },
            async fetchData() {
                this.$root.fileDirectory = "laterWork";
                this.laterWorkTypes = await laterWorkTypeApi.getAll();
                if (this.$route.params.id) {
                    this.planningExists = structuredClone(await projectLaterWorkPlanningApi.get(this.$route.params.id))!=null;
                    this.projectLaterWork = await projectLaterWorkApi.get(this.$route.params.id);
                    if (this.projectLaterWork == null) {
                        this.formData = { laterWorkTypeId: null };
                        return;
                    }
                    this.formData = structuredClone(this.projectLaterWork);
                    let laterWorkType = this.laterWorkTypes.find(p => p._id == this.formData.laterWorkTypeId)
                    if (laterWorkType) {
                        this.laterWorkTemplate = await laterWorkTemplateApi.get(laterWorkType.laterWorkTemplateId)
                    }
                    if (this.$route.params.category && this.formData) {
                        this.category = this.formData.categories.find(g => g.id === this.$route.params.category);
                    } else {
                        if (this.formData.categories) {
                            let g = this.formData.categories.find(() => true);
                            if (g)
                                this.selectCategory(g.id)
                        }
                    }
                }
            },
            async deleteCategory(i) {
                if (!this.formData.categories)
                    throw new Error("No Categorys!", this.formData);
                if (await confirm("Soll die Gruppe gelöscht werden?", "Gruppe Löschen?")) {
                    let index = this.formData.categories.findIndex(g => g.id === i.id)
                    this.formData.categories.splice(index, 1);
                    if (this.category.id == i.id)
                        this.selectCategory("");
                }
            },
            async restoreCategory(category) {
                if (!this.formData.categories)
                    throw new Error("No Categorys!", this.formData);
                if (this.formData.categories.find(hg => hg.id === category.id))
                    throw new Error("Already exists!", [this.formData, category]);
                this.formData.categories.push(structuredClone(category));
                // Sort by index in template
                this.formData.categories.sort((a, b) => this.laterWorkTemplate.categories.findIndex(hg => hg.id === a.id) - this.laterWorkTemplate.categories.findIndex(hg => hg.id === b.id))

            },
            async restoreSubCategory(category, subCategory) {
                if (!this.formData.categories)
                    throw new Error("No Categorys!", this.formData);
                if (!this.formData.categories.find(hg => hg.id === category.id))
                    throw new Error("Category does not exist!", [this.formData, category]);
                let templateCategory = this.laterWorkTemplate.categories.find(hg => hg.id === category.id);
                if (category.subCategories.find(h => h.id === subCategory.id))
                    throw new Error("Already exist!", [category, subCategory]);
                category.subCategories.push(structuredClone(subCategory));
                // Sort by index in template
                category.subCategories.sort((a, b) => templateCategory.subCategories.findIndex(h => h.id === a.id) - templateCategory.subCategories.findIndex(h => h.id === b.id))
            },
            async selectCategory(id) {
                // If we already moved away from the page, dont route anymore!
                if (!this.$route.path.match(/\/final(\/|$)/i))
                    return;
                this.$router.push("/Projects/" + this.$root.project._id + "/final/" + id)
            },
            getAllowedSubCategoryOptions(subCategory) {
                if (this.laterWorkTemplate) {
                    let templateSubCategory = this.laterWorkTemplate.categories.map(hg => hg.subCategories.find(h => h.id === subCategory.id)).find(notEmpty => notEmpty);
                    if (!templateSubCategory || !templateSubCategory.securities)
                        return subCategory.securities;
                    return templateSubCategory.securities.filter(m => subCategory.securities?!subCategory.securities.find(hm => hm.id === m.id):true).concat(subCategory.securities);
                }
            },
            showRestore(category) {
                this.restoreVisible = true;
                this.restoreFromCategory = category;
            },
            async generateDocument() {
                let pId = this.$root.project._id
                try{
                    let att = await projectLaterWorkApi.generateDocument(pId);
                    this.$root.toast("Finale Unterlage", [{
                        icon: "download",
                        hint: "Unterlage herunterladen",
                        onClick: async (t) => {
                            await attachmentApi.download(att._id);
                            t.remove();
                        }
                    }, {
                        icon: "picture_as_pdf",
                        hint: "PDF Erstellen",
                        onClick: async (t) => {
                            this.generatePdf(null, pId)
                            t.remove();
                        }
                    }], 5000);
                }catch{
                    notify("Fehler beim Erstellen der Unterlage","error");
                }                
            },
            async generatePdf(e, id) {
                console.log("[NO UNUSED] pdf", e);
                let att = await attachmentApi.getByAssignment("project", id || this.$root.project._id, "FinaleUnterlage.docx");
                if (!att) {
                    att = await projectLaterWorkApi.generateDocument(id ||this.$root.project._id);
                }
                let pdf = await attachmentApi.getPdfAssignment(att._id);
                this.$root.toast("Finale Unterlage PDF", [{
                    icon: "download",
                    hint: "Unterlage PDF herunterladen",
                    onClick: async (t) => {                        
                        await attachmentApi.download(pdf._id);
                        t.remove();
                    }
                }], 5000);
                /*
                 {
                        icon: "open_in_new",
                        hint: "Unterlage öffnen",
                        onClick: async (t) => {
                            open("/api/attachments/pdf/" + att._id, "_blank");
                            t.remove();
                        }
                    }
                */
            }
        }
    });
</script>

<style>
    /* STYLING FOR THIS ITEM IS MAINLY DONE IN ProjectsSiGePlan.vue */
</style>