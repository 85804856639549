<template>
    <dx-popup v-model:visible="popupVisible"
              :close-on-outside-click="true"
              title="Protokoll versendet"  width="60%" height="80%">
        <template #content>
            <div class="email-list">
                <h3>Das Protokoll wurde erfolgreich versendet an:</h3>
                <ul>
                    <li v-for="email in validEmails" :key="email">{{ email }}</li>
                </ul>
            </div>
            <div v-if="invalidEmails.length > 0" class="invalid-emails">
                <h3>Diese Empfängeradressen waren ungültig:</h3>
                <ul>
                    <li v-for="email in invalidEmails" :key="email">{{ email }}</li>
                </ul>
            </div>
        </template>
    </dx-popup>
</template>

<script>
    import { DxPopup } from 'devextreme-vue';

    export default {
        components: {
            DxPopup
        },
        data() {
            return {
                popupVisible: false               
            };
        },
        props: {          
            validEmails: {
                type: Array,
                required: true
            },
            invalidEmails: {
                type: Array,
                required: true
            }
        }
    };

</script>

<style scoped>
    .email-list h3, .invalid-emails h3 {        
        font-size: 18px;
        margin-bottom: 10px;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        margin: 5px 0;        
        font-size: 16px;
    }

    .invalid-emails {
        margin-top: 20px;
        background-color: #777777;
        border-left: 5px solid #e34747;
        padding: 10px;
    }
</style>