import axios from 'axios';
import { authService } from './AuthService'

axios.interceptors.request.use(
    async config => {
        let token = await authService.getAccessToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
let bufferCache = {};

axios.getBuffered = async (url, config) => {
    if (url in bufferCache)
        return await bufferCache[url];
    bufferCache[url] = axios.get(url, config);
    let result = await bufferCache[url];
    delete bufferCache[url];
    return result;
}

export default axios;
