import { createRouter, createWebHistory } from 'vue-router'
import { authService } from '../services/AuthService.js';
import ProjectsHead from '../views/ProjectsHead.vue'
import ProjectsRoot from '../views/ProjectsRoot.vue'
import ProjectsInfo from '../views/ProjectsInfo.vue'
import ProjectsNotice from '../views/ProjectsNotice.vue'
import ProjectsProtocol from '../views/ProjectsProtocol.vue'
import ProjectsLaterWorkPlanning from '../views/ProjectsLaterWorkPlanning.vue'
import ProjectsSiGePlan from '../views/ProjectsSiGePlan.vue'
import ProjectsSiGePlanPlanning from '../views/ProjectsSiGePlanPlanning.vue'
import ProjectsFinal from '../views/ProjectsLaterWork.vue'

import MainDataHead from '../views/MainDataHead.vue'
import MainDataOrganizations from '../views/MainDataOrganizations.vue'
import MainDataOrganization from '../views/MainDataOrganization.vue'

import AdminHead from '../views/AdminHead.vue'

import AdminUsers from '../views/AdminUsers.vue'

import AdminLists from '../views/AdminLists.vue'
import AdminList from '../views/AdminList.vue'

import AdminTemplates from '../views/AdminTemplates.vue'
import AdminTemplateFiles from '../views/AdminTemplateFiles.vue'
import AdminTemplateMails from '../views/AdminTemplateMails.vue'
import AdminTenants from '../views/AdminTenants.vue'
import AdminSettings from '../views/AdminSettings.vue'

import AdminProjectTypeTemplates from '../views/AdminProjectTypeTemplates.vue'
import AdminProjectTypes from '../views/AdminProjectTypes.vue'

import AdminLaterWorkTemplates from '../views/AdminLaterWorkTemplates.vue'
import AdminLaterWorkTypes from '../views/AdminLaterWorkTypes.vue'

import MobileRoot from '../views/MobileRoot.vue'
import MobileProject from '../views/MobileProject.vue'

import ProjectsMapHead from '../views/ProjectsMapHead.vue'

export const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            components: {
                default: ProjectsRoot,
                head: ProjectsHead
            },
            meta: { title: 'Home', requiresAuth: true }
        },
        {
            path: '/Projects',
            components: {
                default: ProjectsRoot,
                head: ProjectsHead
            },
            meta: { title: 'Projekte', requiresAuth: true }
        },
        {
            path: '/Projects/:id',
            components: {
                default: ProjectsInfo,
                head: ProjectsHead
            },
            meta: { title: 'Projekt - Info', requiresAuth: true }
        },
        {
            path: '/Projects/:id/notice',          
            components: {
                default: ProjectsNotice,
                head: ProjectsHead
            },
            meta: { title: 'Projekt - Vorankündigung', requiresAuth: true }
        },
        {
            path: '/Projects/:id/protocol',
            components: {
                default: ProjectsProtocol,
                head: ProjectsHead
            },
            meta: { title: 'Projekt - Protokoll', requiresAuth: true }
        },
        {
            path: '/Projects/:id/later-work-planning/:category?',
            components: {
                default: ProjectsLaterWorkPlanning,
                head: ProjectsHead
            },
            meta: { title: 'Projekt - Planungsphase Spätere Arbeiten', requiresAuth: true }
        },
        {
            path: '/Projects/:id/sige-plan/:group?',
            components: {
                default: ProjectsSiGePlan,
                head: ProjectsHead
            },
            meta: { title: 'Projekt - SiGe-Plan', requiresAuth: true }
        },
        {
            path: '/Projects/:id/sige-plan-planning/:group?',
            components: {
                default: ProjectsSiGePlanPlanning,
                head: ProjectsHead
            },
            meta: { title: 'Projekt - Planungsphase SiGe-Plan', requiresAuth: true }
        },
        {
            path: '/Projects/:id/final/:category?',
            components: {
                default: ProjectsFinal,
                head: ProjectsHead
            },
            meta: { title: 'Projekt - Final', requiresAuth: true }
        },
        {
            path: '/MainData/Organizations',
            components: {
                default: MainDataOrganizations,
                head: MainDataHead
            },
            children: [
                {
                    path: ':id/:subId?',
                    component: MainDataOrganization
                }
            ],
            meta: { title: 'Stammdaten', requiresAuth: true }
        },
        {
            path: '/Admin',
            components: {
                default: AdminUsers,
                head: AdminHead
            },
            meta: { title: 'Verwaltung - Benutzer', requiresAuth: true }
        },
        {
            path: '/Admin/Settings',
            components: {
                default: AdminSettings,
                head: AdminHead
            },
            meta: { title: 'Verwaltung - Einstellungen', requiresAuth: false }
        },
        {
            path: '/Admin/Lists',
            components: {
                default: AdminLists,
                head: AdminHead
            },
            children: [
                {
                    path: ':id',
                    component: AdminList

                }
            ],
            meta: { title: 'Verwaltung - Listen', requiresAuth: true }
        },
        {
            path: '/Admin/Templates',
            components: {
                default: AdminTemplates,
                head: AdminHead
            },
            meta: { title: 'Verwaltung - Vorlagen', requiresAuth: true }
        },
        {
            path: '/Admin/Templates/Files',
            components: {
                default: AdminTemplateFiles,
                head: AdminHead
            },
            meta: { title: 'Verwaltung - Vorlagen - Dateien', requiresAuth: true }
        },
        {
            path: '/Admin/Templates/Mails',
            components: {
                default: AdminTemplateMails,
                head: AdminHead
            },
            meta: { title: 'Verwaltung - Vorlagen - Mails', requiresAuth: true }
        },
        {
            path: '/Admin/ProjectTypeTemplates/:id?/:group?',
            components: {
                default: AdminProjectTypeTemplates,
                head: AdminHead
            },
            meta: { title: 'Verwaltung - Projekttypvorlagen', requiresAuth: true }
        },
        {
            path: '/Admin/ProjectTypes/:id?/:group?',
            components: {
                default: AdminProjectTypes,
                head: AdminHead
            },
            meta: { title: 'Verwaltung - Projekttypen', requiresAuth: true }
        },
        {
            path: '/Admin/LaterWorkTemplates/:id?/:category?',
            components: {
                default: AdminLaterWorkTemplates,
                head: AdminHead
            },
            meta: { title: 'Verwaltung - Spätere Arbeiten Vorlagen', requiresAuth: true }
        },
        {
            path: '/Admin/LaterWorkTypes/:id?/:category?',
            components: {
                default: AdminLaterWorkTypes,
                head: AdminHead
            },
            meta: { title: 'Verwaltung - Spätere Arbeiten', requiresAuth: true }
        },
        {
            path: '/Admin/Tenants',
            components: {
                default: AdminTenants,
                head: AdminHead
            },
            meta: { title: 'Verwaltung - Mandanten', requiresAuth: true }
        },
        {
            path: '/Map',
            components: {
                default: ProjectsRoot,
                head: ProjectsMapHead
            },
            meta: { title: 'Projektkarte', requiresAuth: true },
            props: {
                default: { mapView: 'split' }
            }
        },
        {
            path: '/Map/full',
            components: {
                default: ProjectsRoot,
                head: ProjectsMapHead
            },
            meta: { title: 'Projektkarte - Vollbild', requiresAuth: true },
            props: {
                default: { mapView: 'full' }
            }
        },
        {
            path: '/Mobile',
            components: {
                default: MobileRoot
            },
            meta: { title: 'Mobile App', requiresAuth: true }
        }, {
            path: '/Mobile/:id/:deficiency?/:location?',
            components: {
                default: MobileProject
            },
            meta: { title: 'Mobile App', requiresAuth: true }
        },
        {
            path: '/login',
            beforeEnter: () => {
                authService.login();
            },
            components: {
                default: null,
                head: null,
            },
            meta: { title: 'Log-In' }
        },
        {
            path: '/logout',
            beforeEnter: (to,from,next) => {
                authService.postLogoutOperations().then(() => {
                    next("/");
                });                
            },            
            components: {
                default: null,
                head: null,
            },
            meta: { title: 'Logged out'}
        },
    ]
})

router.beforeEach(async (to, from, next) => {

    console.log("checking authentication...");
    if (to.matched.some(record => record.meta.requiresAuth)) {        
        var authStatus = await authService.checkUserAuthentication();
        if (!authStatus || !authStatus.isAuthenticated) {
            console.log("Not authenticated. Redirect to:", to.fullPath);
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            });
            return;
        }        
    }

    document.title = "SiGeMaster" + (to.meta.title== null? "" : (" - "+to.meta.title));
    next();
})
