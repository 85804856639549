<template>
    <div class="area-content-body projects-root" :class="{'projects-root-split':mapView=='split','projects-root-full':mapView=='full'}">
        <DxDataGrid v-show="!mapView||mapView=='split'" id="projectRootGrid"
                    ref="grid"
                    :remoteOperations="true"
                    :stateStoring="gridStateStoring"
                    :focused-row-key="focusedRowKey"
                    :focused-row-enabled="true"
                    :auto-navigate-to-focused-row="autoNavigateToFocusedRow"
                    :allow-column-reordering="true"
                    :sync-lookup-filter-values="false"
                    :columnChooser="gridColumnChooser"
                    @cellPrepared="(e)=>{if(e.column.dataField =='active'){ e.cellElement.classList.add('cell-'+e.value) } }"
                    :scrolling="gridScrolling"
                    :data-source="shownProjects"
                    :allow-column-resizing="true"
                    column-resizing-mode="nextColumn"
                    @rowDblClick="changeProject">

         

            <DxColumn data-field="number" caption="Projekt-Nr." :sort-index="0" sort-order="asc" />
            <DxColumn data-field="name" caption="Projekt-Bezeichnung" />
            <DxColumn data-field="clientId" cell-template="contactTemplate" caption="Auftraggeber" :allow-editing="false" />
            <DxColumn data-field="clientOrganizationId" cell-template="organizationTemplate" caption="Auftraggeber Firma" :allow-editing="false" />
            <DxColumn data-field="coordinatorId" cell-template="userTemplate" caption="Koordinator" :allow-editing="false" />

            <DxColumn data-field="status" caption="Status" :lookup="{dataSource:$root.getLookupDs('status'),valueExpr:'id',displayExpr:'name'}"></DxColumn>
            <template #contactTemplate="{ data }">
                <ContactLookup v-if="data.value" :value="data.value" :view="true"></ContactLookup>
                <div v-else></div>
            </template>
            <template #organizationTemplate="{ data }">
                <OrganizationLookup v-if="data.value" :value="data.value" :view="true"></OrganizationLookup>
                <div v-else></div>
            </template>
            <template #userTemplate="{ data }">
                <UserLookup v-if="data.value" :value="data.value" :view="true"></UserLookup>
                <div v-else></div>
            </template>
            <DxSearchPanel :visible="true" width="300" placeholder="Volltext Suche..."/>
            <DxFilterRow :visible="true" />

            <DxColumn data-field="start" data-type="date" format="dd.MM.yyyy" caption="Start" :allow-editing="false" :visible="false" />
            <DxColumn data-field="end" data-type="date" format="dd.MM.yyyy" caption="Ende" :allow-editing="false" :visible="false" />
            <DxColumn data-field="streetAndNr" caption="Straße und Hausnummer" :allow-editing="false" :visible="false" />
            <DxColumn data-field="zipCode" caption="PLZ" :allow-editing="false" :visible="false" />
            <DxColumn data-field="city" caption="Stadt" :allow-editing="false" :visible="false" />
            <DxColumn data-field="shortDescription" caption="Kurze Beschreibung" :allow-editing="false" :visible="false" />
            <DxColumn data-field="longDescription" caption="Lange Beschreibung" :allow-editing="false" :visible="false" />
            <DxColumn data-field="lastChanged" caption="Letzte Änderung" format="dd.MM.yyyy" :allow-editing="false" :visible="false" />
            <DxColumn data-field="buildingOwnerId" cell-template="contactTemplate" caption="Bauherrschaft" :allow-editing="false" :visible="false" />
            <DxColumn data-field="buildingOwnerOrganizationId" cell-template="organizationTemplate" caption="Bauherrschaft Firma" :allow-editing="false" :visible="false" />
            <DxColumn data-field="architectId" cell-template="contactTemplate" caption="Architekt" :allow-editing="false" :visible="false" />
            <DxColumn data-field="architectOrganizationId" cell-template="organizationTemplate" caption="Architekt Firma" :allow-editing="false" :visible="false" />
            <DxColumn data-field="substituteCoordinatorId" cell-template="userTemplate" caption="Stellvertretender Koordinator" :allow-editing="false" :visible="false" />
            <DxColumn data-field="projectManagerId" cell-template="userTemplate" caption="Projektleiter" :allow-editing="false" :visible="false" />
            <DxColumn data-field="internalDescription" caption="Interne Beschreibung" :allow-editing="false" :visible="false" />
            <DxColumn data-field="active" caption="Aktiv" :allow-editing="false" :visible="false" />
            <DxColumn data-field="archived" caption="Archiviert" :allow-editing="false" :visible="false" />
        </DxDataGrid>
        <map-component ref="map" v-if="initMap" v-show="mapView=='split'||mapView=='full'" @project-changed="shownProjects.reload();" :query="currentQuery"></map-component>
        <div v-else></div>
        <div v-if="loading" class="no-data-message"></div>
    </div>
</template>
<script lang="js">
    import { defineComponent, watchEffect } from 'vue';
    import DxDataGrid, {
        DxColumn,
        DxSearchPanel,
        DxFilterRow
    } from 'devextreme-vue/data-grid';
    import ContactLookup from '../components/contact-lookup.vue';
    import UserLookup from '../components/user-lookup.vue';
    import OrganizationLookup from '../components/organization-lookup.vue';
    import projectApi from '../services/ProjectApi';
    import 'devextreme/data/array_store';
    import listApi from '../services/ListApi';
import DataSource from 'devextreme/data/data_source';
    import { luceneConverter } from '../services/Util';
    import MapComponent from '../components/map-component.vue'
    export default defineComponent({
        name:"ProjectsRoot",
        components: {
            DxDataGrid,
            DxColumn,
            ContactLookup,
            OrganizationLookup,
            UserLookup,
            DxSearchPanel,
            DxFilterRow,
            MapComponent
        },
        props: {
            mapView: String
        },
        data() {
            return {
                gridStateStoring: this.getStateStoring(),
                gridColumnChooser: { enabled: true },
                gridScrolling: { mode: 'virtual', preloadEnabled: true },
                loading: false,
                autoNavigateToFocusedRow: false,
                projectCount: -1,
                shownProjects: null,
                showArchived: false,
                focusedRowKey: null,
                currentQuery: null,
                random: null,
                initMap: false
            };
        },
        created() {
            // fetch the data when the view is created and the data is
            // already being observed
            this.initMap = this.mapView == 'split' || this.mapView == 'full'
            console.log("[INFO] Api-URL:", process.env.VUE_APP_API_URL);
            this.shownProjects = new DataSource({
                load: async (loadOptions) => {
                    let sort = loadOptions.sort ? loadOptions.sort.filter(s=>s.selector != "_id").map(s => (s.desc ? "!" : "") + luceneConverter.toPascalCase(s.selector)).join(",") : "";
                    console.log("loadOptions")
                    if (loadOptions.filter) {
                        let query = luceneConverter.toLucene(loadOptions.filter);
                        this.currentQuery = query;
                        let result = await projectApi.search(`Archived:${(this.showArchived ? "1" : "0")} AND (${query})`, loadOptions.take, loadOptions.skip, sort)
                        this.projectCount = (result.data.length == 0 && loadOptions.skip == 0)?0:result.totalHits;
                        console.log("this.projectCount",this.projectCount)
                        return {
                            data: result.data, totalCount: result.totalHits
                        };
                    } else {
                        this.currentQuery = "_id:*";
                        let result = await projectApi.search(`Archived:${(this.showArchived ? "1" : "0")}`, Math.min(100,loadOptions.take),loadOptions.skip, sort);
                        this.projectCount =(result.data.length == 0 && loadOptions.skip == 0)?0: result.totalHits;
                        console.log("this.projectCount",this.projectCount)
                        return {
                            data: result.data, totalCount: result.totalHits
                        }
                    }
                },
                byKey: async (key) => {                  
                    try {
                        var project = await projectApi.get(key);
                        if (!project) {
                            console.error("project not found", key);
                        }
                        return project;
                    }
                    catch (ex) {
                        console.error("error while fetching project by key", key, ex);
                        return null;
                    }
                    //return this.projects.find(p => p._id == key);
                },
                key: "_id"
            });
            //this.fetchData();
        },
        mounted() {
            watchEffect(async () => {
                if (this.$root.$refs.head?.setArchived) {
                    this.$root.$refs.head.setArchived = false;
                    let key = this.$refs.grid.instance.option("focusedRowKey");
                    let project = await projectApi.get(key)
                    if (project) {
                        project.archived = !project.archived;
                        projectApi.save(project);
                        this.updateFilter();
                    }
                }
            })
            watchEffect(() => {
                if (this.showArchived == this.$root.$refs.head.showArchived)
                    return;
                this.showArchived = this.$root.$refs.head.showArchived
                this.updateFilter();
            })
        },
        watch: {
            '$route.params': "routeChanged",
            mapView(v, o) {
                if (!this.initMap) {
                    this.initMap = this.mapView == 'split' || this.mapView == 'full'
                }
                if (!o && v) {
                    if (!this.$refs.map) {
                        this.$nextTick(() => {
                            this.$refs.map.updateProjects();
                        })                        
                    }                    
                }
            }
        },
        /*
        beforeRouteUpdate(to, from) {
            // return false if not saved or sth
            // always create notification why
            return true;
        },
        */
        methods: {
            changeProject(row) {
                this.$router.push("/Projects/" + row.data._id);
            },
            updateFilter() {
                console.log("updateFilter")
                this.shownProjects.reload()
            },
            getStateStoring() {
                let key = "project-grid:" + this.$root.currentUser;
                return {
                    enabled: true,
                    type: "custom",
                    async customLoad() {
                        let settingsList = await listApi.getByName("sys_settings");
                        let item = await listApi.getListItemByValue(settingsList._id, key);
                        if (item)
                            return JSON.parse(item.data);
                        return null;
                    },
                    async customSave(e) {
                        let settingsList = await listApi.getByName("sys_settings");
                        let item = await listApi.getListItemByValue(settingsList._id, key);
                        if (item) {
                            let prev = item.data
                            item.data = JSON.stringify({ columns: e.columns });
                            if (prev == item.data) {
                                return item;
                            }
                            await listApi.saveItem(item);
                        }
                        else {
                            return await listApi.saveItem({ listId: settingsList._id, value: key, data: JSON.stringify({ columns: e.columns }) });
                        }
                    }
                }
            },
            routeChanged(/* to, from */) {
                //this.fetchData();
            },
            async fetchData() {
                this.projectCount = -1;
                this.$root.project = null;
                this.loading = true;
                this.$root.fileDirectory = null;
                this.showArchived = this.$root.$refs.head && this.$root.$refs.head.showArchived;
                this.updateFilter();
                this.loading = false;
            },
            test(){
                this.$router.push("/MainData")
            }
        },
    });
</script>
<style>
    .projects-root {
        background-color: var(--color-dx-dark);
        display: grid;
        grid-template-columns: 1fr 0;
        grid-template-rows: minmax(0, 1fr);
    }
    .projects-root-full {
        grid-template-columns: 1fr;
    }
    .projects-root-split {
        grid-template-columns: 1fr 1fr;
    }

    
    div#projectRootGrid {
        height: 100%;
    }
    .hide-element{
        display:none;
    }
</style>
