<template>
    <dx-lookup v-if="!view && !tags" :showClearButton="allowEmpty||false" :data-source="items" :searchEnabled="true" display-expr="value" value-expr="_id" v-model:value="editableValue" @value-changed="manualChange" :wrap-item-text="wrapItemText||false" >
        <DxDropDownOptions :show-title="false" :hide-on-outside-click="true" />
    </dx-lookup>
    <dx-tag-box v-else-if="!view && tags" :data-source="items" display-expr="value" value-expr="_id" v-model:value="editableValue" @change="manualChange" />
    <div v-else>{{text}}</div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import {
        DxLookup,DxDropDownOptions
    } from 'devextreme-vue/lookup';
    import {
        DxTagBox,
    } from 'devextreme-vue/tag-box';
    
    import listApi from "../services/ListApi";

    let lists = null;
    let listItems = {};

    export default defineComponent({
        components: {
            DxLookup,
            DxDropDownOptions,
            DxTagBox
        },
        props: ['list', 'parentList', 'label', 'value', 'onValueChanged', 'onItemChanged', 'onParentChildChanged', 'allowEmpty', 'onManualChange', 'view', 'tags', 'display', 'default', 'initSave', 'wrapItemText', 'filteredIds'],
        data() {
            return {
                items: [],
                editableValue: null,
                text:null,
                initializing: false
            };
        },
        watch:{
            editableValue(newValue, oldValue) {
                if((this.initializing && !this.initSave) || this.view)
                    return;
                if (newValue != oldValue) {
                    this.$emit('update:value', this.editableValue);
                    if (this.onValueChanged)
                        this.onValueChanged(this.editableValue);
                    let item = this.items.find(i => i._id === this.editableValue);
                    if (this.onItemChanged)
                        this.onItemChanged(item)
                    if (this.onParentChildChanged) {
                        let parentChild = Object.entries(listItems).find(e => e[0].startsWith("c_") && e[1].some(li => li._id == this.editableValue))
                        if (parentChild) {
                            console.log("parentChildId", parentChild[0].substr(2))
                            this.onParentChildChanged(parentChild[0].substr(2));
                        }
                    }
                    
                    
                }
            }
        },        
        async created() {
            this.initializing = true;
            if(!lists)
                lists = await listApi.getAll();
                         
            this.editableValue = this.value;
         
            if (this.parentList) {
                let list = lists.find(l => l.internalName === this.parentList);
                if (!list) {
                    this.initializing = false;
                    return;
                }
                if (!(list._id in listItems))
                    listItems[list._id] = await listApi.getListItems(list._id);
                let allItems = [];
                for (let childItem of listItems[list._id]) {
                    if (!("c_" +childItem._id in listItems)) {
                        let childList = await listApi.getByParentListItemId(childItem._id);
                        listItems["c_" + childItem._id] = await listApi.getListItems(childList._id);
                    }
                    //console.log("listItems[childItem._id]", listItems["c_" +childItem._id])
                    allItems.push(...listItems["c_" +childItem._id]);
                }
                this.items = allItems.toSorted((a, b) => a.rank - b.rank);
            } else {
                let list = lists.find(l => l.internalName === this.list);
                if (!list) {
                    this.initializing = false;
                    return;
                }
                if (!(list._id in listItems))
                    listItems[list._id] = await listApi.getListItems(list._id);
                this.items = listItems[list._id].toSorted((a, b) => a.rank - b.rank);
            }
           
            if (this.filteredIds) {
                this.items = this.items.filter(i => this.filteredIds.includes(i._id)).toSorted((a, b) => a.rank - b.rank);
            } 

            if (!this.value && this.default){
                this.editableValue = this.items.find(i => i._id == this.default || i.value == this.default)._id
            }
            
            if (this.display) {
                this.text = this.display(this.items.find(i => i._id === this.editableValue));
            }else if (this.tags) {
                this.text = this.items.filter(i=>this.editableValue?.includes(i._id)??false).map(i=>i.value).join(", ")
            } else {
                this.text = this.items.find(i => i._id === this.editableValue)?.value;
            }
            this.initializing = false;          
        },
        methods: {
            manualChange(e) {
                if (this.onManualChange) {
                    if (e.value) {
                        if (e.event) {
                            this.onManualChange(e.value);
                        }                        
                    }
                    else
                        this.onManualChange(this.editableValue);
                } 
            },
        },
    });
</script>

<style>
</style>
