import 'devextreme/dist/css/dx.dark.css'
import { locale, loadMessages } from 'devextreme/localization';
import de from 'devextreme/localization/messages/de.json';
import { init } from './services/Init';



loadMessages(de)
locale("de");

init.initializeApp();

if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("/sw.js");
}

window.addEventListener('beforeinstallprompt', () => {
});

