<template>
    <div class="area-content-body">
        <DxDataGrid class="admin-users-grid" :focused-row-enabled="true"
                    :auto-navigate-to-focused-row="autoNavigateToFocusedRow"
                    key-expr="_id"
                    :data-source="users"
                    @cell-prepared="cellPrepared"
                    @saving="saving">
            <DxEditing :allow-updating="true"
                       :allow-deleting="true"
                       :allow-adding="true"
                       mode="row"
                       new-row-position="last"
                       :useIcons="true" />
            <DxColumn data-field="_id" :visible="false" :header-filter="{allowSearch:false}" :allow-search="false" :allow-filtering="false"></DxColumn>
            <DxColumn data-field="firstName" caption="Vorname" />
            <DxColumn data-field="lastName" caption="Nachname" />
            <DxColumn data-field="eMail" caption="Email" />
            <DxColumn data-field="phoneNumber" caption="Telefon" />
            <DxColumn data-field="mobileNumber" caption="Mobil" />
            <DxColumn data-field="_id" cell-template="signatureTemplate" caption="Unterschrift" :allow-editing="false" />
            <DxColumn data-field="address" caption="Adresse" />
            <DxColumn data-field="color" caption="Farbe" edit-cell-template="colorTemplate" cell-template="colorViewTemplate" />
            <DxColumn data-field="radius" caption="Reichweite" data-type="number"/>
            <template #colorTemplate="{data}">
                <DxColorBox :value="data.value" @value-changed="data.setValue($event.value)"></DxColorBox>
            </template>
            <template #colorViewTemplate="{data}">
                <div :style="{width: '100%', height: '19px',borderRadius: '9px',backgroundColor:data.value}"></div>
            </template>
            <template #signatureTemplate="{ data }">
                <ImageViewer v-if="data.value" :attachment="{assignment:data.value,assignmentType:'user',name:'Signature.png'}" height="32px" class="parent-class-remove" :minimal="true" allowed-types="image/*" :pop-up-on-click="true" :show-upload-in-full-screen="true" :show-delete-in-full-screen="true"></ImageViewer>
                <div v-else></div>
            </template>
            <DxColumn data-field="pl" caption="PL" />
            <DxColumn type="buttons"
                      :width="110">
                <DxButton name="edit" />
                <DxButton name="delete" />
                <DxButton hint="Zurücksetzen"
                          icon="refresh"
                          :disabled="isCloneIconDisabled"
                          @click="reset" />
            </DxColumn>
            <DxSearchPanel :visible="true" />
        </DxDataGrid>
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import DxDataGrid,{        
        DxColumn,
        DxEditing,
        DxSearchPanel,
        DxButton
    } from 'devextreme-vue/data-grid';
    import { DxColorBox } from 'devextreme-vue';
    import ImageViewer from "../components/image-viewer.vue";

    import userApi from '../services/UserApi';
import { confirm } from 'devextreme/ui/dialog';

    export default defineComponent({
        components: {
            DxDataGrid,
            DxColumn,
            DxEditing,
            ImageViewer,
            DxSearchPanel,
            DxButton,
            DxColorBox
        },
        data() {
            return {
                users:[]
            };
        },
        async created() {
            await this.fetchData();
        },
        methods: {
            async fetchData(){
                this.users = await userApi.getAll();
            },
            async reset(e) {
                if (await confirm(`Wollen Sie das Kennwort von '${e.row.data.firstName} ${e.row.data.lastName}' wirklich zurücksetzen?`, "Kennwort zurücksetzen")) {
                    await userApi.reset(e.row.key);
                }
            },
            async saving(e){
                for(let change of e.changes){
                    if(change.type === "update"){
                        let user = this.users.find(u=>u._id===change.key);
                        Object.assign(user,change.data);
                    } else if(change.type === "insert") {
                        await userApi.save(change.data);
                    } else if(change.type === "remove") {
                        await userApi.delete(change.key);
                    }
                }
                if(e.changes.some(c=>c.type==="insert")){
                    await this.fetchData();
                }
            },
            cellPrepared(e){
                if(e.column.dataField === '_id' && e.rowType === 'data'){
                    e.cellElement.style.padding = "0";
                }
            }

        },
    });
</script>

<style>
    .admin-users-grid .dx-datagrid {
        padding: 10px;
        background-color: #2a2a2a;
    }
</style>
