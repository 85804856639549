<template>
    <div v-if="projectTypeTemplate" class="area-content-body admin-project-type-template">
        <div class="admin-project-type-template-list">
            <div class="admin-project-type-template-head">
                <DxButton icon="back" @click="back" hint="Zurück"/>
                <span class="admin-project-type-template-title">Projekttyp Vorlage | </span>
                <HeaderFieldEditor inputHint="Name" :allowEdit="true" v-model:dataSource="projectTypeTemplate.name" />
                <DxButton v-if="false" icon="trash" @click="deleteTemplate" hint="Vorlage Löschen"/>
                <DxButton icon="add" @click="addGroup" hint="Gruppe hinzufügen" />
                <DxButton icon="save" @click="saveGroup" :disabled="!hasChanges" hint="Speichern" />
            </div>
            <div class="admin-list">
                <div v-for="hazardGroup in projectTypeTemplate.hazardGroups" :key="hazardGroup.id" class="admin-list-item" @click.prevent="selectGroup(hazardGroup.id)" :class="{'active':group&&group.id===hazardGroup.id}">
                    <div class="admin-list-item-button material-symbols-outlined" @click.prevent.stop="deleteGroup(hazardGroup)" title="Gruppe löschen">delete</div>
                    <div class="admin-list-item-content">{{hazardGroup.name}}</div>
                </div>
            </div>
        </div>
        <div v-if="group" class="admin-project-type-hazard-group hazard-group-with-name">
            <HeaderFieldEditor inputHint="Name" :allowEdit="true" v-model:data-source="group.name"></HeaderFieldEditor>
            <HazardsList v-model:items="group.hazards" :accept-custom="true"/>
            <DxButton icon="add" @click="addHazard" hint="Hizufügen"/>
        </div>
    </div>
    <div v-else>Keine Daten</div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import HazardsList from '../components/hazards-list.vue'
    import HeaderFieldEditor from '../components/header-field-editor.vue'
    import projectTypeTemplateApi from '../services/ProjectTypeTemplateApi'
    import DxButton from 'devextreme-vue/button'
    import { confirm } from 'devextreme/ui/dialog'
    import { guid } from '../services/Util';
    

    export default defineComponent({
        components: {
            HazardsList,
            HeaderFieldEditor,
            DxButton
        },
        data() {
            return {
                projectTypeTemplate: null,
                projectTypeTemplateOrig: null,
                projectTypeTemplates: [],
                group: null,
                allowEdit: null,
                hasChanges: false
            };
        },
        async beforeRouteUpdate() {
            if (this.hasChanges) {
                return await confirm("Es gibt ungespeicherte Änderungen - sollen diese verworfen werden?", "Änderungen verwerfen?")
            }
            return true;
        },
        watch: {
            async '$route.params'() {
                this.fetchData();
            },
            'projectTypeTemplate': {
                handler() {
                    if (this.projectTypeTemplate && this.projectTypeTemplateOrig) {
                        this.hasChanges = this.projectTypeTemplateOrig != JSON.stringify(this.projectTypeTemplate);
                    }
                },
                deep:true                
            },
            'projectTypeTemplate.hazardGroups'() {
                this.group = this.projectTypeTemplate?.hazardGroups?.find(g => g.id === this.$route.params.group);
            }
        },
        async created() {
            this.fetchData();
        },
        methods: {
            async fetchData() {
                if (this.$route.params.id) {
                    this.projectTypeTemplate = structuredClone(await projectTypeTemplateApi.get(this.$route.params.id));
                    this.projectTypeTemplateOrig = JSON.stringify(this.projectTypeTemplate);
                    if (this.$route.params.group) {
                        if (this.$route.params.group == "new") {
                            let g = { name: 'Neu', id: guid(), hazards: [{ name: '', id: guid() }] };
                            this.projectTypeTemplate.hazardGroups.push(g);
                            await projectTypeTemplateApi.save(this.projectTypeTemplate);
                            this.hasChanges = false;
                            this.selectGroup(g.id);
                        } else {
                            this.group = this.projectTypeTemplate.hazardGroups.find(g => g.id === this.$route.params.group);
                        }                        
                    } else {
                        let g = this.projectTypeTemplate.hazardGroups.find(() => true);
                        if(g)
                            this.selectGroup(g.id)
                    }
                } else {
                    this.projectTypeTemplate = null;
                    this.group = null;
                    this.projectTypeTemplates = await projectTypeTemplateApi.getAll();
                }       
            },
            back() {
                this.$router.push("/Admin/Templates");
            },
            async selectTemplate(i) {
                this.$router.push("/Admin/ProjectTypeTemplates/" + i.itemData._id)
            },
            async deleteTemplate() {
                if (await confirm("Soll die Vorlage gelöscht werden?", "Vorlage Löschen?")) {
                    await projectTypeTemplateApi.delete(this.projectTypeTemplate);
                    this.back();
                }                
            },
            async add() {
                let item = await projectTypeTemplateApi.createNew();
                item.name = 'Neu'
                setTimeout(() => { this.fetchData(); },1)
                
            },
            async selectGroup(id) {
                if (!this.projectTypeTemplate)
                    throw new Error("No projectTypeTemplate!", this.projectTypeTemplate);
                this.$router.push("/Admin/ProjectTypeTemplates/"+this.projectTypeTemplate._id+"/" + id)
            },
            async addGroup() {
                if (!this.projectTypeTemplate.hazardGroups)
                    throw new Error("No HazardGroups!", this.projectTypeTemplate);
                await this.selectGroup("new");                    
            },
            async deleteGroup(i) {
                if (!this.projectTypeTemplate.hazardGroups)
                    throw new Error("No HazardGroups!", this.projectTypeTemplate);
                if (await confirm("Soll die Gruppe gelöscht werden?", "Gruppe Löschen?")) {
                    let index = this.projectTypeTemplate.hazardGroups.findIndex(g => g.id === i.id)
                    this.projectTypeTemplate.hazardGroups.splice(index, 1);
                }         
            },
            async addHazard() {
                if (!this.group)
                    throw new Error("No Group!", this);
                this.group.hazards.push({ name: '', id: guid() })
            },
            async saveGroup() {
                if (!this.projectTypeTemplate)
                    throw new Error("No projectTypeTemplate!", this);
                await projectTypeTemplateApi.save(this.projectTypeTemplate);
                await this.fetchData();
            },
            async createType() {
                this.$router.push("/Admin/ProjectTypes/new:" + this.projectTypeTemplate._id)
            }
        },
    });
</script>

<style>
    .admin-project-type-template-head {
        display: grid;
        grid-template-columns: max-content max-content minmax(0,1fr);
        grid-auto-flow: column;
        grid-auto-columns: max-content;
        align-items: center;
        margin-bottom: 10px;
    }
    .admin-project-type-template-head > * {
        margin: 0 5px;
    }
        .admin-project-type-template-head .header-field-editor{
        }

        .admin-project-type-template {
            display: grid;
            grid-template-columns: 1fr 2fr;
            grid-template-rows: minmax(0,1fr);
        }
    .admin-project-type-hazard-group {
        display: grid;
        grid-template-rows: minmax(0,1fr) max-content;
        grid-template-columns: minmax(0,1fr);
        padding-right: 10px;
    }
        .admin-project-type-hazard-group.hazard-group-with-name {
            grid-template-rows: max-content minmax(0,1fr) max-content;
        }

        .hazards-list {
            overflow: auto;
            padding: 0 20px;
            background-color: var(--color-background-light);
            border-radius: var(--default-border-radius);
        }
    .admin-project-type-template-list {
        display: grid;
        grid-template-rows: max-content minmax(0,1fr);
        grid-template-columns: minmax(0,1fr);
    }

    .admin-project-type-template-list {
    }
        .admin-list-item {
            display: grid;
            align-items: center;
            grid-template-columns: max-content minmax(0,1fr);
            grid-auto-flow: column;
            padding: 5px;
        }
        .admin-list-item.active {
            background-color: var(--color-background-light);
        }
            .admin-list-item:hover {
                background-color: var(--color-background-lighter);
            }
    .admin-list-item-button {
        transition: all 0.1s;
        padding: 3px;
        color: var(--color-text);
        background: none;
        border-radius: var(--default-border-radius);
        opacity: 0;
    }
    .admin-list-item:hover .admin-list-item-button {
        opacity: 1;
    }
    .admin-list-item-button:hover {
        background: var(--color-background-light);
    }
    .admin-list-item-content {
        padding: 0 10px;
        overflow:hidden;
        text-overflow: ellipsis;
    }

    .header-field-editor{
        font-size: 1rem;
        padding: 6px 11px;
        margin: 4px;
    }


    .admin-project-type-hazard-group .dx-widget.dx-button.dx-button-mode-contained.dx-button-normal.dx-button-has-icon {
        margin-top: 10px;
    }
</style>
