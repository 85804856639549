<template>
    <div class="header-field-editor-container">
        <input class="header-field-editor" ref="editableInput" v-model="editableData" :readonly="!allowEdit" @blur="lostFocus" :placeholder="inputHint" />
        <div class="header-field-editor" ref="editableInputDiv" style="visibility:hidden;pointer-events: none;position:fixed;padding:2px;"></div>
    </div>    
</template>

<script lang="js">
        
    import { defineComponent} from 'vue';

    export default defineComponent({
        props: ['dataSource', 'allowEdit','inputHint'],
        data() {
            return {            
                editableData: this.dataSource
            }
        },   
        watch: {
            "editableData"() {
                this.updateSize();
            },
            "dataSource"() {
                this.editableData = this.dataSource;
            }
        },
        mounted() {
            this.updateSize();
        },
        methods: {     
            updateSize() {
                if (this.$refs.editableInputDiv && this.$refs.editableInput) {
                    this.$refs.editableInputDiv.innerText = this.editableData;
                    this.$refs.editableInput.style.maxWidth = this.$refs.editableInputDiv.clientWidth + "px";
                }
            },
            lostFocus() {       
                if (!this.allowEdit)
                    return;

                if (this.editableData != this.dataSource) {
                    this.$emit('update:dataSource', this.editableData);
                }
                this.editorVisible = false;
            },
            focus(select) {
                if (this.$refs.editableInput) {
                    this.$refs.editableInput.focus();
                    if (select)
                        this.$refs.editableInput.select();
                }                
            }
        }
    });
</script>

<style>
    .header-field-editor-container{
        display:grid;
    }

    .header-field-editor {
        display: inline-block;
        cursor: default;
        background: none;
        border: none;
        outline-color: transparent;
        color: var(--color-text);
        border-radius: var(--default-border-radius);
        margin: 0 5px;
        padding: 2px 4px;
        text-overflow: ellipsis;
        font-family: var(--font-text);
        min-width: 100px;
        max-width: max-content;
    }
        .header-field-editor:focus {
            outline: 2px solid var(--color-text);
        }
        .header-field-editor[readonly] {
            outline: 1px solid transparent;
        }
</style>
