<template>
    <div v-if="projectTypeData" class="area-content-body admin-project-type-template">
        <div class="admin-project-type-template-list">
            <div class="admin-project-type-template-head">
                <DxButton icon="back" @click="back" hint="Zurück" />
                <span class="admin-project-type-template-title">Projekttyp Vorlage | </span>
                <HeaderFieldEditor inputHint="Name" ref="NameField" :allowEdit="true" v-model:dataSource="projectType.name" />
                <DxButton v-if="false" icon="trash" @click="deleteTemplate" hint="Löschen"/>
                <DxButton ref='saveButton' icon="save" :disabled="true" @click="saveGroup" hint="Speichern" />
            </div>
            <div class="admin-list">
                <div v-for="hazardGroup in projectTypeTemplate.hazardGroups" :key="hazardGroup.id" class="admin-list-item" @click.prevent="selectGroup(hazardGroup.id)" :class="{'active':group&&group.id===hazardGroup.id,'admin-list-item-removed':!projectType.selectedHazardGroups.includes(hazardGroup.id)}">
                    <div v-if="projectType.selectedHazardGroups.includes(hazardGroup.id)" class="admin-list-item-button material-symbols-outlined" @click.prevent.stop="deleteGroup(hazardGroup)" title="Gruppe löschen">delete</div>
                    <div v-if="!projectType.selectedHazardGroups.includes(hazardGroup.id)" class="admin-list-item-button material-symbols-outlined" @click.prevent.stop="addGroup(hazardGroup)" title="Gruppe wiederherstellen">restore</div>
                    <div class="admin-list-item-content">{{hazardGroup.name}}</div>
                </div>
            </div>
        </div>
        <div v-if="group" class="admin-project-type-hazard-group" :class="{'hazard-group-removed':!projectType.selectedHazardGroups.includes(group.id)}">
            <HazardsList :items="group.hazards"
                         :value-expr="'id'" 
                         :show-selection-controls="true" 
                         :enabledExpression="(h)=>!projectType.removedHazards.includes(h.id)"
                         @measure-added="addMeasure" 
                         @measure-removed="removeMeasure"
                         @hazard-added="addHazard"
                         @hazard-removed="removeHazard"
                         :read-only-fields="['name','safetyRegulation','note']"  />
        </div>
    </div>
    <div v-else>
        Keine Daten
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import HazardsList from '../components/hazards-list.vue'
    import HeaderFieldEditor from '../components/header-field-editor.vue'
    import projectTypeApi from '../services/ProjectTypeApi'
    import projectTypeTemplateApi from '../services/ProjectTypeTemplateApi'    
    import DxButton from 'devextreme-vue/button'
    import { confirm } from 'devextreme/ui/dialog'    
    import { isEqual } from 'lodash'

    export default defineComponent({
        components: {
            HazardsList,
            HeaderFieldEditor,            
            DxButton
        },
        data() {
            return {
                projectTypeTemplate: null,
                projectType: null,
                projectTypeOrig: null,
                projectTypeData: null,
                group: null,
                allowEdit: null,
                hasChanges: false,
                isNew: false
            };
        },
        async beforeRouteUpdate() {
            if (this.hasChanges) {
                return await confirm("Es gibt ungespeicherte Änderungen - sollen diese verworfen werden?", "Änderungen verwerfen?")
            }
            return true;
        },
        watch: {
            async '$route.params'() {
                this.fetchData();
            },
            'projectType': {
                handler() {
                    if (this.projectType && this.projectTypeOrig) {
                        this.hasChanges = !isEqual(this.projectTypeOrig,this.projectType);
                        if(this.$refs.saveButton){
                            this.$refs.saveButton.instance.option("disabled",!this.hasChanges);
                        }
                    }
                },
                deep: true
            },
            'group.hazards': {
                handler() {
                    if(!this.group || !this.group.hazards)
                        return;
                    for(let hazard of this.group.hazards){
                        let origGroup = this.projectTypeTemplate.hazardGroups.find(g=>g.id === this.group.id);
                        let origHazard = origGroup.hazards.find(h=>h.id === hazard.id);
                        let fieldsToCheck = [ 'responsibilityCreateId','responsibilityControlId','responsibilityMaintenanceId' ];
                        let adjustments = {};
                        for(let fieldToCheck of fieldsToCheck){
                            if(hazard[fieldToCheck] !== origHazard[fieldToCheck]){
                                adjustments[fieldToCheck] = hazard[fieldToCheck];
                            }
                        }
                        let currentAdjustments = structuredClone(this.projectType.hazardAdjustments);

                        for(let k in currentAdjustments[hazard.id]){
                            if(currentAdjustments[hazard.id][k] === null)
                                delete currentAdjustments[hazard.id][k]
                        }
                        if(Object.keys(adjustments).length === 0 && hazard.id in currentAdjustments){
                            delete this.projectType.hazardAdjustments[hazard.id]
                        }else if(Object.keys(adjustments).length > 0 && JSON.stringify( currentAdjustments[hazard.id]) != JSON.stringify(adjustments)){
                            this.projectType.hazardAdjustments[hazard.id]=adjustments;
                        }
                    }
                },
                deep: true
            }
        },
        async mounted() {
            this.fetchData();
        },
        methods: {
            async fetchData() {         
                if (this.$route.params.id) {
                    console.log("fetchData")
                    if (this.$route.params.id.startsWith("new:")) {
                        this.add(this.$route.params.id.substr(4));
                        return;
                    }
                    this.projectType = structuredClone(await projectTypeApi.get(this.$route.params.id));
                    this.projectTypeOrig = structuredClone(this.projectType);
                    this.projectTypeTemplate = structuredClone(await projectTypeTemplateApi.get(this.projectType.projectTypeTemplateId));
                    this.projectTypeData = structuredClone(this.projectTypeTemplate);
                    let removedMeasures = this.projectType.removedMeasures;
                    
                    if (this.$route.params.group) {
                        this.group = this.projectTypeData.hazardGroups.find(g => g.id === this.$route.params.group);
                        if (this.group) {
                            for (let hazard of this.group.hazards) {
                                if(!hazard.measures)
                                    hazard.measures=[];
                                hazard.measures = Array.from(hazard.measures.filter(m => !removedMeasures.includes(m.id)).map(m => m.id));
                                hazard.allowedOptions = this.projectTypeTemplate.hazardGroups.find(g => g.id === this.$route.params.group).hazards.find(h => h.id == hazard.id).measures;
                                if(hazard.id in this.projectType.hazardAdjustments){
                                    for(let key in this.projectType.hazardAdjustments[hazard.id]){
                                        if(this.projectType.hazardAdjustments[hazard.id][key] !== null && hazard[key] != this.projectType.hazardAdjustments[hazard.id][key]){   
                                            hazard[key]=this.projectType.hazardAdjustments[hazard.id][key];
                                        }                                            
                                    }
                                }
                            }
                        }                            
                    } else {
                        let g = this.projectTypeTemplate.hazardGroups.find(() => true);
                        if (g)
                            this.selectGroup(g.id)
                    }
                } else {
                    this.projectTypeTemplate = null;
                    this.group = null;
                }
            },
            back() {
                this.$router.push("/Admin/Templates");
            },
            async deleteTemplate() {
                if (await confirm("Soll der Projekttyp gelöscht werden?", "Vorlage Löschen?")) {
                    await projectTypeApi.delete(this.projectType);
                    this.back();
                }
            },
            async add(projectTypeTemplateId) {
                let tmpl = await projectTypeTemplateApi.get(projectTypeTemplateId);
                let item = await projectTypeApi.save({ name: 'Neu', projectTypeTemplateId: projectTypeTemplateId, selectedHazardGroups: tmpl.hazardGroups.map(hg=>hg.id) });
                this.$router.push("/Admin/ProjectTypes/" + item._id);
                let checkI = setInterval(()=>{
                    if(this.$refs.NameField){
                        this.$refs.NameField.focus(true);
                        clearInterval(checkI);
                    }                    
                },10);
            },
            async selectGroup(id) {
                if (!this.projectTypeTemplate)
                    throw new Error("No projectType!", this.projectType);
                this.$router.push("/Admin/ProjectTypes/" + this.projectType._id + "/" + id)
            },
            async deleteGroup(i) {
                if (!this.projectType.selectedHazardGroups)
                    throw new Error("No Removed!", this.projectType);
                if (this.projectType.selectedHazardGroups.findIndex(g => g === i.id) == -1)
                    throw new Error("Not Removed!", i.id);
                this.projectType.selectedHazardGroups.splice(this.projectType.selectedHazardGroups.findIndex(g => g === i.id), 1);     
            },
            async addGroup(i) {
                if (!this.projectType.selectedHazardGroups)
                    throw new Error("No Removed!", this.projectType);
                if (this.projectType.selectedHazardGroups.findIndex(g => g === i.id) >= 0)
                    throw new Error("Already Removed!", i.id);
                this.projectType.selectedHazardGroups.push(i.id);        
            },
            async addHazard(hazard) {
                if (!this.group)
                    throw new Error("No Group!", this);
                if (this.projectType.removedHazards.includes(hazard.id))
                    this.projectType.removedHazards.splice(this.projectType.removedMeasures.indexOf(hazard.id), 1);
            },
            async removeHazard(hazard) {
                if (!this.group)
                    throw new Error("No Group!", this);
                if (!this.projectType.removedHazards.includes(hazard.id))
                    this.projectType.removedHazards.push(hazard.id);
            },
            async addMeasure(i) {
                if (!this.group)
                    throw new Error("No Group!", this);
                if (this.projectType.removedMeasures.includes(i))
                    this.projectType.removedMeasures.splice(this.projectType.removedMeasures.indexOf(i),1);
            },
            async removeMeasure(i) {
                if (!this.group)
                    throw new Error("No Group!", this);
                if (!this.projectType.removedMeasures.includes(i))
                    this.projectType.removedMeasures.push(i);
            },
            async saveGroup() {
                if (!this.projectType)
                    throw new Error("No projectTypeTemplate!", this);
                await projectTypeApi.save(this.projectType);
                await this.fetchData();
            }
        },
    });
</script>

<style>
    .admin-project-type-template-head {
        display: grid;
        grid-template-columns: max-content max-content minmax(0,1fr) max-content max-content;
        align-items: center;
        margin-bottom: 10px;
    }

        .admin-project-type-template-head > * {
            margin: 0 5px;
        }

        .admin-project-type-template-head .header-field-editor {
        }

    .admin-project-type-template {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: minmax(0,1fr);
    }

    .admin-project-type-hazard-group {
        display: grid;
        grid-template-rows: minmax(0,1fr) max-content;
        grid-template-columns: minmax(0,1fr);
        padding-right: 10px;
    }

    .hazards-list {
        overflow: auto;
        padding: 0 20px;
        background-color: var(--color-background-light);
        border-radius: var(--default-border-radius);
    }

    .admin-project-type-template-list {
        display: grid;
        grid-template-rows: max-content minmax(0,1fr);
        grid-template-columns: minmax(0,1fr);
    }

    .admin-project-type-template-list {
    }

    .admin-list-item {
        display: grid;
        align-items: center;
        grid-template-columns: max-content minmax(0,1fr) max-content;
        grid-auto-flow: column;
        padding: 5px;
    }

        .admin-list-item.active {
            background-color: var(--color-background-light);
        }

        .admin-list-item:hover {
            background-color: var(--color-background-lighter);
        }

    .admin-list-item-button {
        transition: all 0.1s;
        padding: 3px;
        color: var(--color-text);
        background: none;
        border-radius: var(--default-border-radius);
        opacity: 0;
    }

    .admin-list-item:hover .admin-list-item-button {
        opacity: 1;
    }

    .admin-list-item-button:hover {
        background: var(--color-background-light);
    }

    .admin-list-item-content {
        padding: 0 10px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .header-field-editor {
        font-size: 1rem;
        padding: 6px 11px;
        margin: 4px;
    }

    .admin-list-item-removed {
        color: var(--color-error)
    }
    .hazard-group-removed {
        opacity: 0.2;
        pointer-events: none;
        user-select: none;
    }
</style>
