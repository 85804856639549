<template>
    <DxLookup v-if="!view" :data-source="items" :display-expr="displayExpr" value-expr="_id" v-model:value="editableValue">
        <DxDropDownOptions :show-title="false" :hide-on-outside-click="true" />
    </DxLookup>
    <div v-else>{{text}}</div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import {
        DxLookup,
        DxDropDownOptions
    } from 'devextreme-vue/lookup';
    
    import userApi from "../services/UserApi";

    
    let users = null;

    export default defineComponent({
        components: {
            DxLookup,
            DxDropDownOptions
        },
        props: ['user', 'label', 'value','onValueChanged','view','filter'],
        data() {
            return {
                items: [],
                editableValue: null,
                text: null
            };
        },
        watch:{
            editableValue(){
                this.$emit('update:value', this.editableValue);
                let item = this.items.find(i => i._id === this.editableValue)
                this.text = this.displayExpr(item);
                if (this.onValueChanged)
                    this.onValueChanged(this.editableValue);
            }
        },
        async created() {
            if (!users) {
                let dbUsers = await userApi.getAll();
                dbUsers.sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName))
                users = dbUsers;
            }
                
            this.editableValue = this.value;
            if (this.filter) {
                this.items = users.filter(this.filter)
            } else {
                this.items = users;
            }            
            let item = this.items.find(i => i._id === this.editableValue)
            this.text = this.displayExpr(item);
        },
        methods:{
            displayExpr(r){
                if (!r)
                    return null;
                return r.firstName + ' ' + r.lastName;
            }
        }
    });
</script>

<style>
</style>
