import { isEqual, isPlainObject, reduce } from "lodash";

let guid = function() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

let askForImage = function (acceptAllInputs) {
    let i = document.createElement("input")
    return new Promise((res, rej) => {
        try {
            i.type = "file";
            i.accept = "image/*";
            if (!acceptAllInputs)
                i.capture = "environment";
            i.multiple = false;
            i.style.position = "absolute";
            i.style.width = "1px";
            i.style.height = "1px";
            i.style.padding = "0";
            i.style.margin = "-1px";
            i.style.overflow = "hidden";
            i.style.clip = "rect(0,0,0,0)";
            i.style.border = "0";
            i.addEventListener('change', () => {
                try {
                    res(i.files[0])
                } catch (e) {
                    rej(e);
                }
                i.remove();
            });
            i.addEventListener('cancel', () => {
                rej("cancel");
            });
            i.addEventListener('abort', () => {
                rej("abort");
            });

            document.body.appendChild(i);
            let evt = new MouseEvent("click");
            (evt) ? i.dispatchEvent(evt) : (i.click && i.click());
            // i.click();
        } catch (ex) {
            rej(ex);
        }
    }).finally(() => i.remove());
    
}
class LuceneConverter {
    toPascalCase(str) {
        if (!str)
            return str;
        return str[0].toUpperCase() + str.substring(1);
    }
    anyOf(fields) {
        if (!fields)
            return "";
        return "(" + fields.map(f => '"' + this.escape(f) + '"').join(" ") + ")"
    }
    escape(str) {
        if (!str)
            return str;
        return str.replace(/[+\-!(){}[\]^\\?*"~:&|]/g, r => "\\" + r);
    }
    handleItem(item) {
        let field = this.toPascalCase(item[0]);
        let operator = item[1];
        let value = item[2];

        if (operator == "=") {
            return `${field}:"${this.escape(value)}"`
        }
        if (operator == ":") {
            return `${field}:${value}`
        }
        if (operator == "contains") {
            return `${field}:*${this.escape(value)}*`
        }
        if (operator == "startswith") {
            return `${field}:${this.escape(value)}*`
        }
        if (operator == "endswith") {
            return `${field}:*${this.escape(value)}`
        }
        if (operator == "notcontains") {
            return `(${field}:* NOT ${field}:*${this.escape(value)}*)`
        }
        if (operator == "<>") {
            return `(${field}:* NOT ${field}:"${value}")`
        }
        if (operator.startsWith(">")) {
            return `${field}:[${this.toLucene(value)} TO *]`
        }
        if (operator.startsWith("<")) {
            return `${field}:[* TO ${this.toLucene(value)}]`
        }
        if (operator == "between") {
            try {
                value = JSON.parse(value);
                return `${field}:[${value[0]} TO ${value[1]}]`
            }
            catch {
                console.error("[ERR] Between not correct value!", item, value);
                throw new Error("Between not correct value!")
            }

        }
    }
    combineRanges(filter) {
        /*
            Expressions like:
            ["Price",">=",545],
            "and",
            ["Price","<=",566]
            Will be converted to:
            ["Price","between","[545,566]"]
            The 3rd part will be JSON
        */
        if (!Array.isArray(filter) || filter.length != 3)
            return filter;
        if (!Array.isArray(filter[0]) || filter[0].length != 3)
            return filter;
        if (!Array.isArray(filter[2]) || filter[2].length != 3)
            return filter;
        if (filter[1] != "and")
            return filter;
        if (typeof (filter[0][1]) !== "string" || !filter[0][1].startsWith(">"))
            return filter;
        if (typeof (filter[2][1]) !== "string" || !filter[2][1].startsWith("<"))
            return filter;
        if (typeof (filter[0][0]) !== "string" || typeof (filter[2][0]) !== "string")
            return filter;
        if (filter[0][0] != filter[2][0])
            return filter;
        return [filter[0][0], "between", JSON.stringify([this.toLucene(filter[0][2]), this.toLucene(filter[2][2])])]
    }
    toLucene(filter) {
        filter = this.combineRanges(filter);
        if (Array.isArray(filter)) {
            if (filter.length == 3 && filter.every(i => typeof (i) === 'string')) {
                return this.handleItem(filter)
            }
            return "(" + filter.map(item => {
                return this.toLucene(item);
            }).join("") + ")";
        }
        if (typeof (filter) == 'string') {
            if (filter === "or")
                return " OR ";
            else if (filter === "and")
                return " AND ";
            else if (/\d{4}-\d\d-\d\dT\d\d:\d\d:\d\dZ/.test(filter))
                return filter.replace(/[-T:]/g, "").substring(0, 12)
            else
                return filter;
        }
        if (typeof (filter) === "boolean")
            return filter ? "1" : "0";
        if (typeof (filter) === "object" && new Date(filter).toString() !== "Invalid Date")
            return new Date(filter).toJSON().replace(/[-T:]/g, "").substring(0, 12);
        console.error("[ToLucene] DID NOT HANDLE: ", filter);
    }
}

let luceneConverter = new LuceneConverter();

const diffrenceObjects = function (obj1, obj2) {
    return reduce(obj1, function (result, value, key) {
        if (isPlainObject(value)) {
            result[key] = diffrenceObjects(value, obj2[key]);
        } else if (!isEqual(value, obj2[key])) {
            result[key] = value;
        }
        return result;
    }, {});
};


export {
    diffrenceObjects,
    guid,
    askForImage,
    luceneConverter
}
