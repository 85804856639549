<template>
    <div v-if="formData" class="area-content-body projects-notice">
        <div class="projects-notice-form" ref="form">
            <div class="project-head" :class="{dirty:$root.isDirty}">
                <header-field-editor class="project-number-edit" :input-hint="'Projekt-Nr.'" v-model:data-source="$root.project.number" :allow-edit="false" />
                <div class="v-line"></div>
                <span class="headline">Vorankündigung</span>
                <div>
                    <span class="head-toolbar-button-compact" @click="generateDocument" title="Vorankündigung erstellen">description</span>
                </div>
                <attachments-button />
            </div>
            <div class="div-project-action-buttons">
                <project-action-buttons ref="projectButtons" :project="$root.project._id" :project-notice="formData"></project-action-buttons>
            </div>
            <dx-form :form-data="formData" :show-validation-summary="true" label-mode="outside" label-location="top" :scrollingEnabled="true">
                <dx-group-item :col-count="2">
                    <dx-simple-item data-field="created" editor-type="dxDateBox" :value="now" :label="{text:'Erstell-Datum'}" :editor-options="{readOnly:true,dateSerializationFormat:'yyyy-MM-dd'}"/>
                    <dx-simple-item data-field="regionalCouncilLocationId" :label="{text:'Regierungspräsidium Ort'}">
                        <template #default>
                            <ListLookup list="project_regional_council_location" v-model:value="formData.regionalCouncilLocationId"></ListLookup>
                        </template>
                    </dx-simple-item>
                    <dx-simple-item editor-type="dxNumberBox" data-field="maxEmployees" :label="{text:'Höchstzahl Beschäftigte'}" />
                    <dx-simple-item editor-type="dxNumberBox" data-field="employerQuantity" :label="{text:'Arbeitgeber Anzahl'}" />
                    <dx-simple-item editor-type="dxTagBox" :editor-options="{value:selectedContacts,readOnly:true}" :col-span="2" :label="{text:'Bereits ausgewählte Arbeitgeber und Unternehmer ohne Beschäftigte'}" />
                    
                    <dx-simple-item data-field="regionalCouncilLocationId" :col-span="2" :label="{text:'Unterschriebene Dokumente'}">
                        <template #default>
                            <attachment-list :attachment-query="{assignmentType:'project',assignment:$root.project._id,type:'Unterschrieben'}" :allow-upload="true" :allow-download="true" :allow-delete="true"></attachment-list>
                        </template>
                    </dx-simple-item>                    
                </dx-group-item>
            </dx-form>
        </div>
        <div class="projects-notice-preview">
            <image-viewer ref="noticePreview"
                          :attachment="{assignment:$root.project._id,assignmentType:'project',name:'Vorankündigung_verschickt.pdf'}"
                          :alternatives="[{assignment:$root.project._id,assignmentType:'project',name:'Vorankündigung.docx'}]"
                          allowedTypes="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          :allowDownload="true"
                          :allowUpload="false"   
                          :disableDrop="true"
                          style="height:calc(100% - 10px);"></image-viewer>
        </div>
    </div>
    <div v-else class="area-content-body">
        Daten werden geladen...
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import HeaderFieldEditor from '../components/header-field-editor.vue';
    import projectNoticeApi from '../services/ProjectNoticeApi';
    import AttachmentsButton from '../components/project-attachments-button.vue';
    import ImageViewer from '../components/image-viewer.vue';
    import ListLookup from "../components/list-lookup.vue";
    import AttachmentList from "../components/attachment-list.vue";
    import projectActionButtons from '../components/project-action-buttons.vue';
    import {
        DxForm,
        DxGroupItem,
        DxSimpleItem
    } from 'devextreme-vue/form';
    import { addProcess } from '../services/Processor';
    import listApi from '../services/ListApi';
    import organizationApi from '../services/OrganizationApi';
    import attachmentApi from '../services/AttachmentApi';
import notify from 'devextreme/ui/notify';

    export default defineComponent({
        components: {
            DxForm,
            DxGroupItem,
            DxSimpleItem,
            ListLookup,
            HeaderFieldEditor,
            AttachmentsButton,
            ImageViewer,
            projectActionButtons,
            AttachmentList
        },
        watch: {
            '$root.project': {
                async handler() {
                    this.projectNotice = await projectNoticeApi.get(this.$route.params.id);
                    if (this.projectNotice == null) {
                        this.projectNotice = await projectNoticeApi.createNew();
                        this.projectNotice.projectId = this.$route.params.id;
                    }
                    this.formData = structuredClone(this.projectNotice);
                }
            },
            'formData': {
                handler() {
                    if (this.formData) {
                        this.$root.fileDirectory = "notice";
                        if (this.process)
                            this.process();
                        this.process = addProcess("Save ProjectNotice", true);
                        Object.assign(this.projectNotice, structuredClone(this.formData));
                        this.process();
                        this.refreshItems();
                    }
                },
                deep: true
            }
        },
        data() {
            return {
                formData: null,
                process: null,
                projectNotice: null,
                selectedContacts:[],
                currentMailOwnerStatus: false
            };
        },
        async created() {
            if (this.$root.project && this.$route.params.id == this.$root.project._id) {
                this.fetchData();
            } else {
                this.formData = null;
                this.$root.loadProject(this.$route.params.id);
            }
        },
        methods: {
            async generateDocument() {
                try {
                    let att = await projectNoticeApi.generateDocument(this.$root.project._id);
                    if (this.$refs.projectButtons?.reloadExists)
                        await this.$refs.projectButtons?.reloadExists();                    
                    await this.$refs.noticePreview?.setAttachment(att);
                    await this.fetchData();

                    this.$root.toast("Vorankündigung", [{
                        icon: "download",
                        hint: "Vorankündigung herunterladen",
                        onClick: async (t) => {
                            await attachmentApi.download(att._id);
                            t.remove();
                        }
                    }], 5000);
                } catch {
                    notify("Fehler beim Erstellen der Unterlage", "error");
                }
            },
            async refreshItems(){
                let nListItemId = await listApi.getByName("project_assignments");
                let item = await listApi.getListItems(nListItemId._id).then(d => d && d.find(i => i.data && i.data.includes("#selected")));
                if(item){
                    this.selectedContacts = await Promise.all(this.$root.project.involvedContacts?.filter(c=>c.listItemIds && c.listItemIds.includes(item._id)).map(sc=>
                        sc.contactId?organizationApi.getContact(sc.contactId).then(c=>c.firstName+" "+c.lastName):(sc.organizationId?organizationApi.get(sc.organizationId).then(o=>o.name):"")
                    ));
                }
            },
            async fetchData() {
                if (this.$route.params.id) {
                    this.projectNotice = await projectNoticeApi.get(this.$route.params.id);
                    if (this.projectNotice == null) {
                        this.projectNotice = await projectNoticeApi.createNew();
                        this.projectNotice.projectId = this.$route.params.id;
                    }
                    this.formData = structuredClone(this.projectNotice);
                }
            }
        }
    });
</script>


<style>
    .projects-notice {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
    }

        .projects-notice .project-head {
            grid-template-columns: max-content max-content 1fr max-content;
        }

    .headline {
        font-size: 1.5rem;
        align-self: center;
        padding: 5px 15px;
    }

    .div-project-action-buttons {
        height: 100px;
    }
    .status_v {
        width: 181px;
        top: -59px;
        left: 640px;
        position: relative;
    }
</style>

