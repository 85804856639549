<template>
    <div class="head-default">
        <span :title="$root.isDirty?($root.isUploading?'Änderungen werden übernommen':'Änderungen nicht übernommen'):'Alle Anpassungen gespeichert'" class="head-toolbar-button-compact">{{$root.isDirty?($root.isUploading?'cloud_upload':'rule'):'cloud_done'}}</span>
        <span v-if="!$root.isLoggedIn" class="head-toolbar-button-compact" @click="$root.login()" title="Anmelden">no_accounts</span>
        <span v-else class="head-toolbar-button-compact" @click="$root.logout()" :title="'Abmelden - '+$root.currentUser">account_circle</span>
    </div>
</template>

<script lang="js">

</script>

<style>

    .area-content-head.head-overview {
        border: none;
    }

    .head-toolbar {
        padding: 5px;
        margin: 0 10px;
    }

    .head-toolbar-large {
        padding: 0 5px;
        margin: 0 10px;
        display: grid;
        grid-auto-columns: minmax(62px,200px);
        grid-auto-flow: column;
        grid-column-gap: 10px;
        overflow: auto;
    }

    .head-toolbar-item {
        transition: all .3s ease-in-out;
        display: grid;
        grid-template-columns: max-content 1fr;
        background: var(--color-background-light);
        padding: 0px 5px;
        border-radius: 3px;
        text-decoration: none;
        color: inherit;
        margin: 4px;
    }

        .head-toolbar-item.router-link-active {
            background: var(--color-accent);
        }

    .head-toolbar-item-icon {
        font-size: 40px;
        margin: 3px;
        grid-row: 1/span 2;
    }

    .head-toolbar-item-label {
        font-size: .9rem;
        margin: 3px;
        grid-row: 1 /span 2;
        align-self: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .head-toolbar-item-label-main {
        font-size: .9rem;
        margin: 3px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .head-toolbar-item-label-sub {
        font-size: .9rem;
        font-weight: 300;
        grid-column: 2;
        grid-row: 2;
        margin: 3px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .head-toolbar-item.disabled {
        opacity: 0.3;
        pointer-events: none;
    }

    .head-toolbar-button-compact {
        color: var(--color-text);
        padding: 4px;
        margin: 0 5px;
        background: var(--color-dx-dark);
        border: 1px solid var(--color-background-light);
        border-radius: var(--default-border-radius);
        cursor: pointer;
        user-select: none;
        /* MATERIAL ICON */
        font-family: 'Material Symbols Outlined';
        font-weight: normal;
        font-style: normal;
        font-size: 26px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-smoothing: antialiased;
        vertical-align: middle;
    }
        .head-toolbar-button-compact.no-margin {
            margin-left: 0;
            margin-right: 0;
        }
        .head-toolbar-button-compact:hover {
            background: rgba(255,255,255,0.03);
        }

</style>
