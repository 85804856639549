<template>
    <div class="area-content-head" :class="{'head-overview':$route.params.id}">
        <div class="head-title">Karte</div>      
        <div class="head-toolbar">
            <div class="head-toolbar-button-compact material-symbols-outlined" :style="{'background-color':$route.path.match(new RegExp('/Map/full(/$|$)','i'))?'var(--color-accent)':''}" @click="toggleFull" title="Map Anzeigen">map</div>
        </div>
        <head-component />
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import headComponent from '../components/head-component.vue';        

    const items = [
        {
            label: "Info",
            icon: "info",
            context: "",
            enabled: true,
        },
        {
            label: "Vorankündigung",
            icon: "description",
            stageName: 'Notice',
            context: "notice",
            enabled: true,
        },
        {
            label: "Planungsphase",
            subLabel: "SiGe-Plan",
            stageName: 'Planning',
            icon: "space_dashboard",
            context: "sige-plan-planning",
            enabled: true
        },
        {
            label: "Planungsphase",
            subLabel: "Spätere Arbeiten",
            stageName: 'Planning',
            icon: "event_available",
            context: "later-work-planning",
            enabled: true
        },
        {
            label: "SiGe-Plan",
            icon: "assignment_late",
            stageName: 'SiGePlan',
            context: "sige-plan",
            enabled: true
        },
        {
            label: "Protokolle",
            icon: "content_paste",
            stageName: 'Inspection',
            context: "protocol",
            enabled: true
        },
        {
            label: "Finale Unterlage",
            icon: "history_edu",
            stageName: 'SubsequentWorkDocs',
            context: "final",
            enabled: true
        }
    ]

    export default defineComponent({
        components:{
            headComponent
        },
        data() {
            return {
                items,
                showArchived:false,
                setArchived:false
            };
        },
        watch:{
            "$root.project.stages"() {
                if (this.$root?.project?.stages) {
                    this.items.forEach(i => {
                        i.enabled = !i.stageName || this.$root.project.stages.includes(i.stageName);
                    })
                }
            }
        },
        created() {
           
        },
        methods: {
            changeTab(id, tab) {
                this.$router.push("/Projects/" + id + "/" + tab);
            },
            async addProject() {
                this.$router.push("/Projects/new");
            },
            async toggleFull() {
                console.log(this.$route.path,this.$route.path.match(new RegExp('/Map(/$|$)', 'i')))
                if (this.$route.path.match(new RegExp('/Map(/$|$)', 'i')))
                    this.$router.push("/Map/full");
                else
                    this.$router.push("/Map");
            }
        }
    });
</script>

<style>


</style>
