import { createApp } from 'vue'
import App from '../App.vue'
import { authService } from './AuthService.js';
import { router } from './router.js'
class Init {
    constructor() {

    }
    async initializeApp() {
        console.log("[INIT] auth:", authService);
        authService.checkUserAuthentication().then(u => {
            if (!u.isAuthenticated) {
                console.log("[INIT] Redirect to login provider");
                return authService.login();
            }
            else {
                console.log("[INIT] User is authenticated. Start building app.", u);
                createApp(App).use(router).mount('#app')
            }                       
        })
        
    }
}

let init = new Init();

export { init }