<template>
    <div class="area-content-body">
        <DxDataGrid class="admin-tenants-grid" :focused-row-enabled="true"
                    :auto-navigate-to-focused-row="autoNavigateToFocusedRow"
                    key-expr="_id"
                    :data-source="tenants"
                    :searchPanel="{visible:true}"
                    @cell-prepared="cellPrepared"
                    @saving="saving">
            <DxEditing :allow-updating="true"
                       :allow-deleting="true"
                       :allow-adding="true"
                       mode="row"                       
                       new-row-position="last"
                       :useIcons="true" />
            <DxColumn caption="Mandant">
                <DxColumn data-field="name" caption="Firma" />
            </DxColumn>
            <DxColumn caption="Mandanten Admin">
                <DxColumn data-field="adminFirstName" caption="Vorname" />
                <DxColumn data-field="adminLastName" caption="Nachname" />
                <DxColumn data-field="adminEMail" caption="E-Mail" />
            </DxColumn>
            <DxColumn caption="Lizenzen">
                <DxColumn data-field="licenceTotal" caption="Verfügbar" />
                <DxColumn data-field="licenceUsed" caption="Verwendet" />
            </DxColumn>
            <DxColumn caption="">
                <DxColumn data-field="status" caption="Status" />
            </DxColumn>
</DxDataGrid>
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import DxDataGrid,{        
        DxColumn,
        DxEditing
    } from 'devextreme-vue/data-grid';

    import TenantApi from '../services/TenantApi';

    let tenantApi = new TenantApi();

    export default defineComponent({
        components: {
            DxDataGrid,
            DxColumn,
            DxEditing
        },
        data() {
            return {
                tenants:[]
            };
        },
        async created() {
            await this.fetchData();
        },
        methods: {
            async fetchData(){
                this.tenants = await tenantApi.getAll();
            },
            async saving(e){
                for(let change of e.changes){
                    if(change.type === "update"){
                        let tenant = this.tenants.find(u=>u._id===change.key);
                        Object.assign(tenant,change.data);
                    } else if(change.type === "insert") {
                        await tenantApi.save(change.data);
                    } else if(change.type === "remove") {
                        await tenantApi.delete(change.key);
                    }
                }
                if(e.changes.some(c=>c.type==="insert")){
                    await this.fetchData();
                }
            },
            cellPrepared(e){
                if(e.column.dataField === '_id' && e.rowType === 'data'){
                    e.cellElement.style.padding = "0";
                }
            }

        },
    });
</script>

<style>
    .admin-tenants-grid .dx-datagrid {
        padding: 10px;
        background-color: #2a2a2a;
    }
</style>
