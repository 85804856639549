<template>
    <span v-show="!hideLogo" class="area-logo" :class="{dirty:isDirty}"></span>
    <Menu v-show="!hideMenu" />

    <router-view v-slot="{ Component }" v-show="!hideHead" name="head">
        <component :is="Component" ref="head" />
    </router-view>

    <router-view v-slot="{ Component }">
        <transition name="fade">
            <keep-alive include="ProjectsRoot">
                <component :is="Component" />
            </keep-alive>
        </transition>
    </router-view>
    <div id="toasts"><div v-for="toast in toasts" :key="toast.id" class="a-toast" @pointerenter="if(!toast.hideClose){toast.progress=1;toast.dontRemove=true;}" :class="{'a-toast-hidden':toast.hide}"><div v-if="toast.progress>0" class="a-toast-progress" :style="{width:(toast.progress*100)+'%'}"></div><div class="a-toast-title">{{toast.message}}</div><div class="a-toast-buttons" v-if="toast.buttons && toast.buttons.length > 0"><div class="a-toast-button head-toolbar-button-compact" v-for="button in toast.buttons" :key="button.icon" @click="button.onClick(toast)" :title="button.hint">{{button.icon}}</div></div></div></div>
    <DxLoadPanel ref="loadPanel"
                 :visible="false"
                 :show-indicator="true"
                 :show-pane="true"
                 :hide-on-outside-click="false" />
</template>

<script>
    import Menu from './components/navigation-component.vue'
    import DxLoadPanel from 'devextreme-vue/load-panel'
    import { confirm } from 'devextreme/ui/dialog'
    import notify from 'devextreme/ui/notify'    

    import projectApi from './services/ProjectApi'
    import attachmentApi from './services/AttachmentApi'
    import { processor } from './services/Processor'
import { guid } from './services/Util'
import { ref } from 'vue'
import { authService } from './services/AuthService'
import CustomStore from 'devextreme/data/custom_store'
import projectProtocolApi from './services/ProjectProtocolApi'

    export default {
        name: 'App',
        components: {
            Menu,
            DxLoadPanel
        },
        data() {
            return {
                toasts: [],
                project: null,
                projectMeta: null,
                isDirty: processor.isDirty,
                isUploading: processor.isUploading,
                tmpRemoveMeFileHanler: null,
                currentUser: null,
                currentSub: null,
                accessToken: null,
                accessTokenExpired: false,
                isLoggedIn: false,
                hideLogo: false,
                hideMenu: false,
                hideHead: false,
                fieldOptions: {},
                fileDirectory: null,
                fileManagerOpen: false
            };
        },
        mounted() {
            window["APP"] = this;
            authService.getUser().then((user) => {
                console.log("authService USER",user)
                if (!user) {
                    this.currentUser = null;
                    this.currentSub = null;
                    this.isLoggedIn = false;
                    authService.login();
                    return;
                }
                this.currentUser = user.profile.name;
                this.accessToken = user.access_token;
                this.currentSub = user.profile.sub;
                this.accessTokenExpired = user.expired;
                this.isLoggedIn = (user !== null && !user.expired);
            });
        },
        async created() {
            console.log(`THIS IS ${((function (parameterName) { return parameterName }).toString().includes("parameterName") ? 'DEBUG' : 'LIVE')}`);
            // Für Gefas:
            /*
            this.fieldOptions.project = {
                clientId: { readOnly: true },
                kindId: { readOnly: true },
                buildingOwnerId: { readOnly: true },
                stages: { readOnly: true }
            }
            */
            document.body.addEventListener("dragenter", (e) => {
                e.preventDefault();
            });
            document.body.addEventListener("dragover", (e) => {
                e.preventDefault();
            });
            document.body.addEventListener("dragleave", (e) => {
                e.preventDefault();
            });
            document.body.addEventListener("drop", async (e) => {
                e.preventDefault();
                if (this.fileManagerOpen)
                    return;
                if (e.dataTransfer && e.dataTransfer.files) {
                    let uploadCount = 0;
                    let files = Array.from(e.dataTransfer.files); // SOMETHING WITH STREAMS?
                    for (let file of files) {
                        if (this.tmpRemoveMeFileHanler) {
                            this.tmpRemoveMeFileHanler(file);
                            return;
                        }
                        let key = file.name.substring(0, 8);
                        let att = await attachmentApi.getByShort(key);
                        if (att) {
                            if (att.assignmentType == "project") {
                                let project = await projectApi.get(att.assignment);
                                                                
                                if (await confirm(`Soll die bestehende Datei '${att.name}' im Project '${project.number}' ersetzt werden?`, `Datei ersetzen?`)) {
                                    let projectProtocol = await projectProtocolApi.get(att.assignment);
                                    if (att.type === "protocol") {
                                        if (projectProtocol) {
                                            projectProtocol.isCustomized = true;
                                            await projectProtocolApi.save(projectProtocol);
                                        }
                                    }                                    
                                    await attachmentApi.saveBlob(att._id, file);
                                    console.log("Uploaded file:", att);
                                    if (att.type === "protocol") {
                                        // automatically (re)create the associated pdf document for the protocoll                                        
                                        await attachmentApi.getPdfAssignment(att._id);                                                                                
                                    }
                                    notify("Hochgeladen", "success");
                                }
                            }
                            else {
                                if (await confirm(`Soll die bestehende Datei '${att.name}' im ${att.assignmentType} ${att.assignment} ersetzt werden?`, `Datei ersetzen?`)) {
                                    await attachmentApi.saveBlob(att._id, file);                                                                       
                                    notify("Hochgeladen", "success");
                                }
                            }                            
                        } else {
                            if (!this.project) {
                                await confirm(`Kein Projektzuweisung möglich für die Datei ${file.name}`, "Keine Projektzuweisung");
                                continue;
                            }
                            let a = await attachmentApi.save({ name: file.name, assignment: this.project._id, assignmentType: 'project', type: this.fileDirectory })                            
                            await attachmentApi.saveBlob(a._id, file);                            
                            uploadCount++;
                            notify(`${uploadCount}/${files.length} Dateien hochgeladen.`, "success");
                        }
                    }
                }
            });
        },
        methods: {
            async loadProject(id) {
                this.project = await projectApi.get(id);
            },
            getLookupDs(type) {
                let cache = {};
                let metaTask = projectApi.getMeta();
                return new CustomStore({
                    load: () => {
                        if (cache[type])
                            return cache[type];
                        return metaTask.then(c => {
                            let data = c[type];
                            cache[type] = Object.keys(data).map(k => { return { id: k, name: data[k] } }).concat([{ id: "", name:"Kein Wert"}]);
                            return cache[type];
                        })
                    },
                    byKey: (key) => {
                        console.log(key);
                        if (cache[type])
                            return cache[type].find(i => i.id == key);
                        return metaTask.then(c => {
                            let data = c[type];
                            cache[type] = Object.keys(data).map(k => { return { id: k, name: data[k] } }).concat([{ id: "", name: "Kein Wert" }]);
                            return cache[type].find(i => i.id == key);
                        })
                    },
                    key: "id"
                });
            },
            async getMeta(type) {
                if (!this.$root.projectMeta) {
                    this.$root.projectMeta = await projectApi.getMeta();
                }  
                return this.$root.projectMeta[type];
            },
            async getLookup(type) {
                let data = await this.getMeta(type);
                return Object.keys(data).map(k => { return { id: k, name: data[k] } });
            },
            addGridTitle(e, title) {
                e.toolbarOptions.items.unshift({
                    location: "before",
                    html: title,
                    cssClass: 'grid-title'
                });
            },            
            async login() {
                authService.login();
            },
            async logout() {
                authService.logout();
            },
            async toast(message, buttons, ms, hideClose, autoRemove) {
                var toast = {
                    hideClose,
                    message,
                    buttons,
                    id: guid(),
                    hide: ref(false),
                    iv: null,
                    dontRemove: false,
                    progress: ref(0),
                    remove: () => {
                        clearInterval(toast.iv);
                        toast.hide.value = true;
                        setTimeout(() => {
                            this.toasts = this.toasts.filter(t => t.id != toast.id);
                        }, 300);
                    }
                };
                if (!hideClose)
                    buttons.push({ icon: "close", onClick:() => { toast.remove() },hint:"Schließen" })
                this.toasts.push(toast);
                if (autoRemove) {
                    toast.iv = setInterval(() => {
                        toast.progress.value += (100 / ms);
                        if (toast.progress.value > 1) {
                            toast.progress.value = 1;
                        }
                    }, 100);
                    await new Promise(r => setTimeout(r, ms));
                    if (!toast.dontRemove)
                        toast.remove();
                }
            }
        }
    }
</script>

<style>

    .slide-fade-enter-active {
        transition: all 0.1s ease-out;
    }

    .slide-fade-leave-active {
        transition: all 0.1s ease-out;
    }

    .slide-fade-enter-from {
        transform: translateX(-220px);
        opacity: 0;
    }

    .slide-fade-leave-to {
        transform: translateX(220px);
        opacity: 0;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.2s ease-in-out;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    body, html {
        margin: 0;
        padding: 0;
        height: 100%;
    }

    #app {
        font-family: var(--font-text);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: var(--color-text);
        background: var(--color-background);
        height: 100%;
        position: relative;
        display: grid;
        grid-template-columns: min-content max-content 1fr;
        grid-template-rows: max-content 1fr;
        grid-template-areas: 'logo logo c-head' 'menu c-body c-body' 'menu c-body c-body';
        overflow: hidden;
    }

    .dirty {
    }

    .area-menu {
        grid-area: menu;
    }

    .area-logo {
        grid-area: logo;
        padding: 10px;
        margin: 10px;
        font-size: 1.3rem;
        font-weight: bold;
        color: white;
        background-image: url(assets/sm_logo_wide.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 115px;
        margin-left: 20px;
    }

    .area-content-body {
        grid-area: c-body;
        padding: 7px 10px;
    }

    .area-content-head {
        grid-area: c-head;
        text-align: left;
        font-size: 1.7rem;
        border-bottom: 2px solid var(--color-background-light);
        padding: 5px;
        display: grid;
        grid-template-columns: 1fr max-content max-content;
        align-content: end;
        align-items: center;
    }

    /* Default Stuff - Maybe in own CSS */
    .grid-title {
        padding-left: 10px !important;
    }

    div#toasts {
        display: block;
        position: fixed;
        right: 0;
        bottom: 0;
        overflow: hidden;
    }

    .a-toast {
        position: relative;
        background: var(--color-background);
        padding: 10px;
        border-radius: 5px;
        margin: 10px;
        border: 3px solid white;
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-columns: max-content;
        grid-gap: 10px;
        grid-auto-flow: column;
        align-items: center;
        box-shadow: 0 0 10px rgba(0,0,0,0.5);
        opacity: 1;
        transition: opacity 300ms;
    }
    .a-toast-progress {
        position: absolute;
        left: 0;
        height: 3px;
        bottom: -3px;
        background: var(--color-accent);
        transition: width 100ms;
    }

    .a-toast-hidden{
        opacity: 0;
    }
</style>
