<template>
    <div class="area-content-head">
        <div class="head-title">Stammdaten</div>
        <div class="head-toolbar">
            <div class="head-toolbar-button-compact material-symbols-outlined" title="Archivieren (TODO)">archive</div>
        </div>
        <head-component />
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import headComponent from '../components/head-component.vue'

    export default defineComponent({
        components: {
            headComponent
        },
        data() {
            return {
            };
        },
        created() {
        },
        methods: {
        }
    });
</script>

<style>
</style>
