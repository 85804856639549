import { UserManager, WebStorageStateStore } from 'oidc-client';

export default class AuthService {
    constructor() {
    }
    async getUserManager() {
        if (this._userManager)
            return this._userManager; 
        let config = await this.getConfig();
        let settings = {
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            authority: config.oidc.sts_Domain,
            client_id: config.oidc.client_Id,
            redirect_uri: `https://${location.host}/signin.html`,
            automaticSilentRenew: true,
            silent_redirect_uri: `https://${location.host}/signin-silent.html`,
            response_type: 'code',
            scope: 'openid profile',
            post_logout_redirect_uri: `https://${location.host}/`,
            filterProtocolClaims: false,
        };
        window.auth = this;
        this._userManager = new UserManager(settings);
        return this._userManager;
    }
    async getConfig() {
        return await fetch('/api/config')
            .then(response => response.text())
            .then(configText => {
                let config = JSON.parse(configText);
                window.config = config;
                return config;
            }).catch(error => {
                console.error("[CONFIG] Failed to load app configuration: ", error);
            });
    }

    getUser() {
        if (!navigator.onLine && "offlineUser" in localStorage) {
            let user = JSON.parse(localStorage.offlineUser);
            user.expired = false;
            user.isOffline = true;
            console.log("USING OFFLINE USER", user)
            return new Promise(res=>res(user));
        }
        return this.getUserManager().then(u => u.getUser().then(user => {
            localStorage.offlineUser = JSON.stringify(user);
            return user;
        }));
    }

    login() {
        return this.getUserManager().then(u => u.signinRedirect({ state: location.pathname + location.search }));
    }

    logout(state) {
        return this.getUserManager().then(u => u.signoutRedirect({ state }));
    }

    async getAccessToken() {
        return this.getUser().then((data) => {
            return data?.access_token;
        });
    }
    async postLogoutOperations() {
        // perform cleanup
        console.log("[AUTH] User logged out. Cleaning up...");
        localStorage.clear();
    }

    async checkUserAuthentication() {
        
        try {
            const user = await this.getUser();
            if (user && !user.expired) {
                return {
                    isAuthenticated: true,
                    user: user
                };
            }
            return {
                isAuthenticated: false,
                user: null
            };
        } catch (error) {
            console.error('[AUTH] Failed to check the user authentication status', error);
            return {
                isAuthenticated: false,
                user: null
            };
        }
    }
}
let authService = new AuthService();
window.auth = authService;
export { authService }