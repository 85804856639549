<template>
    <div :id="id">
        <i v-if="icon" :class="iconClass" />{{ data.text }}
    </div>
</template>
<script>
export default {
  props: {
        data: {
            type: Object,
            default: () => { }
        },
        icon: {
            type: String,
            default: 'info'
        },
        id: {
            type: String,
            default: null
        }

  },
  data() {
    return {
      iconClass: `dx-icon dx-icon-${this.icon}`,
    };
  },
};
</script>
