<template>
    <div ref="hazardsList" class="hazards-list">
        <div v-for="(subCategory,index) in subCategories" v-bind:key="subCategory.id" class="hazard-item" :class="{'hazard-item-disabled':enabledExpression?!enabledExpression(subCategory):false}">
            <div class="hazards-list-form-contain">
                <DxForm v-if="subCategory" :form-data="subCategory" :show-validation-summary="true" label-mode="outside" label-location="top" :col-count="6" @field-data-changed="formFieldDataChanged">
                    <DxSimpleItem data-field="name" :col-span="6" editor-type="dxTextBox" :label="{text:'Name'}" :editor-options="{readOnly:readOnlyFields&&readOnlyFields.includes('name')}"></DxSimpleItem>
                    <DxSimpleItem data-field="occurrenceId" :col-span="2" :label="{text:'Häufigkeit'}" :editor-options="{readOnly:readOnlyFields&&readOnlyFields.includes('occurrenceId')}">
                        <template #default>
                            <ListLookup list="later_work_occurrences" v-model:value="subCategory.occurrenceId" @value-changed="v=>fixScroll($refs.hazardsList.scrollTop)"></ListLookup>
                        </template>
                    </DxSimpleItem>
                    <DxSimpleItem data-field="planId" :col-span="2" :label="{text:'Pläne'}" :editor-options="{readOnly:readOnlyFields&&readOnlyFields.includes('planId')}">
                        <template #default>
                            <ListLookup list="later_work_plans" v-model:value="subCategory.planId" @value-changed="v=>fixScroll($refs.hazardsList.scrollTop)"></ListLookup>
                        </template>
                    </DxSimpleItem>
                    <DxSimpleItem data-field="dangerIds" :col-span="2" :label="{text:'Gefährdung'}" :editor-options="{readOnly:readOnlyFields&&readOnlyFields.includes('dangerId')}">
                        <template #default>
                            <ListLookup list="later_work_dangers" v-model:value="subCategory.dangerIds" @value-changed="v=>fixScroll($refs.hazardsList.scrollTop)" :tags="true"></ListLookup>
                        </template>
                    </DxSimpleItem>
                    <DxSimpleItem data-field="securities" :col-span="6" editor-type="dxTagBox" :label="{text:'Sicherheitstechnische Einrichtung'}" :editor-options="{ displayExpr: 'name', valueExpr: valueExpr, showSelectionControls: showSelectionControls, acceptCustomValue: acceptCustom, onCustomItemCreating: addSecurity, onPaste: paste, dataSource: (getAllowedOptions&&getAllowedOptions(subCategory)) || subCategory.allowedOptions || [], onValueChanged: onSecuritiesChanged || null }" />
                    <DxSimpleItem data-field="note" :col-span="6" editor-type="dxTextArea" :editor-options="{maxHeight:200,autoResizeEnabled:true,minHeight:100,maxLength:2048,readOnly:readOnlyFields&&readOnlyFields.includes('note')}" :label="{text:'Hinweis'}"></DxSimpleItem>

                </DxForm>
                <div class="hazards-list-image-contain">
                    <image-viewer :attachment="(assignmentOverride?Object.assign(clone(assignmentOverride),{name:subCategory.id+'_1.png'}):null)" :alternatives="altAssignmentOverrides&&altAssignmentOverrides.map(p=>Object.assign(clone(p),{name:subCategory.id+'_1.png'}))" allowed-types="image/*" :allow-upload="true" :allow-description="true" :allow-drop="true" :allow-edit="true" :allow-clear="true" :allow-full-screen="true" height="200px"></image-viewer>
                    <image-viewer :attachment="(assignmentOverride?Object.assign(clone(assignmentOverride),{name:subCategory.id+'_2.png'}):null)" :alternatives="altAssignmentOverrides&&altAssignmentOverrides.map(p=>Object.assign(clone(p),{name:subCategory.id+'_2.png'}))" allowed-types="image/*" :allow-upload="true" :allow-description="true" :allow-drop="true" :allow-edit="true" :allow-clear="true" :allow-full-screen="true" height="200px"></image-viewer>
                    <image-viewer :attachment="(assignmentOverride?Object.assign(clone(assignmentOverride),{name:subCategory.id+'_3.png'}):null)" :alternatives="altAssignmentOverrides&&altAssignmentOverrides.map(p=>Object.assign(clone(p),{name:subCategory.id+'_3.png'}))" allowed-types="image/*" :allow-upload="true" :allow-description="true" :allow-drop="true" :allow-edit="true" :allow-clear="true" :allow-full-screen="true" height="200px"></image-viewer>
                </div>
                <div v-if="showTemplate" class="hazards-list-template-contain">
                    <image-viewer :attachment="{assignment:'laterWorkTemplate',assignmentType:'system',type:$parent.laterWorkTemplate._id,name:subCategory.id+'.docx'}" :alternatives="[{assignment:'template',assignmentType:'system',name:'later_work_planning_step.docx'}]" :img-trim="true" allowed-types="application/vnd.openxmlformats-officedocument.wordprocessingml.document" :allow-upload="true" :allow-download="true" :allow-drop="true" :allow-clear="true" height="200px"></image-viewer>
                </div>
            </div>
            
            <div class="hazard-buttons">
                <div v-if="!enabledExpression" class="hazard-button material-symbols-outlined" @click="deleteItem(subCategory)" title="Löschen">delete</div>
                <div v-if="enabledExpression && enabledExpression(subCategory)" class="hazard-button material-symbols-outlined" @click="removeItem(subCategory)" title="Löschen">delete</div>
                <div v-if="enabledExpression && !enabledExpression(subCategory)" class="hazard-button material-symbols-outlined" @click="addItem(subCategory)" title="Wiederherstellen">restore</div>

                <div :class="{'hazard-button-inactive':index==0}" class="hazard-button material-symbols-outlined" @click="moveToTop(subCategory)" title="An den Anfang verschieben">keyboard_double_arrow_up</div>
                <div :class="{'hazard-button-inactive':index==0}" class="hazard-button material-symbols-outlined" @click="moveUp(subCategory)" title="Nach oben verschieben">keyboard_arrow_up</div>
                <div :class="{'hazard-button-inactive':index==subCategories.length-1}" class="hazard-button material-symbols-outlined" @click="moveDown(subCategory)" title="Nach unten verschieben">keyboard_arrow_down</div>
                <div :class="{'hazard-button-inactive':index==subCategories.length-1}" class="hazard-button material-symbols-outlined" @click="moveToBottom(subCategory)" title="An das Ende verschieben">keyboard_double_arrow_down</div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import { confirm } from 'devextreme/ui/dialog'
    import {
        DxForm,
        DxSimpleItem
    } from 'devextreme-vue/form';
    import {guid} from '../services/Util'
    import ListLookup from '../components/list-lookup.vue';
    import ImageViewer from '../components/image-viewer.vue'
    import { afterProcessing } from '../services/Processor';
    export default defineComponent({
        components: {
            ListLookup,
            DxForm,
            DxSimpleItem,
            ImageViewer
        },
        props: ['items', 'showTemplate', 'assignmentOverride', 'altAssignmentOverrides','onRemove', 'getAllowedOptions', 'acceptCustom', 'valueExpr', 'showSelectionControls', 'onSecurityRemoved', 'onSecurityAdded', 'onSubCategoryRemoved', 'onSubCategoryAdded', 'enabledExpression', 'readOnlyFields' ],
        data() {
            return {
                subCategories: [],
                anyOpened: false
            };
        },
        watch: {
            'items'() {
                this.subCategories = this.items;
            }
        },
        created() {
            this.subCategories = this.items;
        },
        methods:{
            addSecurity(e){
                if (!e.text) {
                    return false;
                }
                e.customItem = { id: guid(), name: e.text };
                return e.customItem;                
            },
            onSecuritiesChanged(e) {
                if (!e.value || !e.previousValue)
                    return;
                let added = e.value.filter(t => !e.previousValue.includes(t))
                let removed = e.previousValue.filter(t => !e.value.includes(t));
                if (typeof (this.onSecurityAdded) === "function")
                    added.forEach(i => this.onSecurityAdded(i));
                if (typeof (this.onSecurityRemoved) === "function")
                    removed.forEach(i => this.onSecurityRemoved(i));
            },
            paste(e) {
                if(!this.acceptCustom)
                    return;
                let text = e.event?.originalEvent?.clipboardData?.getData("text");
                if (text && text.includes("\n")) {
                    let items = text.split("\n");
                    e.event.preventDefault();
                    let val = e.component.option("value").concat(items.map(i => ({id:guid(),name:i})));
                    e.component.option("value", val);
                }
            },
            async addItem(subCategory) {
                if (typeof (this.enabledExpression) === "function") {
                    if (typeof (this.onSubCategoryAdded) === "function") {
                        this.onSubCategoryAdded(subCategory);
                    }
                }
            },
            async removeItem(subCategory) {
                if (typeof (this.enabledExpression) === "function") {
                    if (typeof (this.onSubCategoryRemoved) === "function") {
                        this.onSubCategoryRemoved(subCategory);
                    }
                }
            },
            async deleteItem(subCategory) {
                if (await confirm(`Soll '${subCategory.name}' wirklich gelöscht werden?`, 'Element Löschen?')) {
                    if (typeof (this.onRemove) === 'function')
                        this.onRemove(subCategory)
                    let i = this.subCategories.findIndex(h => h.id === subCategory.id);
                    this.subCategories.splice(i, 1);
                }
            },
            moveToTop(subCategory) {
                let i = this.subCategories.findIndex(h => h.id === subCategory.id)
                let item = this.subCategories.splice(i, 1);
                this.subCategories.splice(0, 0, item[0]);
            },
            moveUp(subCategory) {
                let i = this.subCategories.findIndex(h => h.id === subCategory.id)
                let item = this.subCategories.splice(i, 1);
                this.subCategories.splice(i - 1, 0, item[0]);
            },
            moveDown(subCategory) {
                let i = this.subCategories.findIndex(h => h.id === subCategory.id);
                let item = this.subCategories.splice(i, 1);
                this.subCategories.splice(i + 1, 0, item[0]);
            },
            moveToBottom(subCategory) {
                let i = this.subCategories.findIndex(h => h.id === subCategory.id)
                let item = this.subCategories.splice(i, 1);
                this.subCategories.push(item[0]);
            },
            formFieldDataChanged(e) {
                console.log("[NO UNUSED] formFieldDataChanged",e)
            },
            fixScroll(top) {
                if (top > 0) {
                    this.$nextTick(() => {
                        if(this.$refs.hazardsList)
                            this.$refs.hazardsList.scrollTop = top;
                    })
                    afterProcessing(() => {
                        this.$nextTick(() => {
                            if (this.$refs.hazardsList)
                                this.$refs.hazardsList.scrollTop = top;
                        })
                    })
                }
            },
            clone(obj) {
                return structuredClone(obj);
            }
        }
    });
</script>

<style>
    .hazard-item {
        border-bottom: 3px solid var(--color-background-lighter);
        padding: 20px 0;
        display: grid;
        grid-template-columns: minmax(0,1fr) max-content;
        grid-gap: 5px;
    }
    .hazard-buttons {
        display: grid;
        grid-auto-flow: row;
        grid-auto-rows: max-content;
        grid-gap: 5px;
    }
    .hazard-button {
        padding: 2px;
        margin: 3px;
        border-radius: var(--default-border-radius);
        background: var(--color-background);
        color: var(--color-text);
    }
        .hazard-button:not(.hazard-button-inactive):hover {
            background: var(--color-background-lighter);
        }
    .hazard-button-inactive{
        opacity: 0.1;
    }
    .hazard-item:last-child {
        border-bottom: none;
    }

    .hazard-item-disabled .dx-form {
        opacity: 0.2;
        pointer-events: none;
        user-select: none;
    }

    .hazards-list-image-contain {
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
        padding: 5px;
        grid-gap: 10px;
    }
</style>
