import { ref, watch } from 'vue'
import axios from './Axios';
import offlineService, { offlineUserApi } from './OfflineService';
import { addProcess } from './Processor';

class UserApi {
    constructor() {
        this.isDirty = ref(false);
        this.isLoading = ref(false);
        this.isSaving = false;

        if (!window.Api)
            window.Api = {};
        window.Api.UserAxi = axios;
        window.Api.User = this;
    }
    // eslint-disable-next-line
    async beforeSave(data) {        
    }
    // eslint-disable-next-line
    async afterLoad(data) {        
    }
    async get(id) {
        this.isLoading.value = true;
        let result = await axios.get('/api/users/' + id);
        await this.afterLoad(result.data);
        let dataRef = this.attachWatchers(result.data);
        return dataRef;
    }
    async getByMail(mail) {
        this.isLoading.value = true;
        let result = await axios.get('/api/users/byMail/' + mail);
        await this.afterLoad(result.data);
        let dataRef = this.attachWatchers(result.data);
        return dataRef;
    }
    async getBySubject(subject) {
        this.isLoading.value = true;
        let result = offlineService.checkIsOffline ? await offlineUserApi.getBySubject(subject):  (await axios.get('/api/users/bySubject/' + subject)).data;
        await this.afterLoad(result);
        console.log("GETBYSUB",subject,result)
        let dataRef = this.attachWatchers(result);
        return dataRef;
    }
    async reset(id) {
        this.isLoading.value = true;
        let result = await axios.post('/api/users/reset/' + id).finally(() => {
            this.isLoading.value = false;
        });        
        return result;
    }
    async getAll() {
        this.isLoading.value = true;
        let result = offlineService.checkIsOffline ? offlineUserApi.getAll() : (await axios.getBuffered('/api/users/')).data;
        await this.afterLoad(result);    
        this.isLoading.value = false;
        let dataRefs = this.attachWatchers(result);
        return dataRefs;
    }
    async sync() {
        this.isLoading.value = true;
        let result = await axios.post('/api/users/sync');        
        this.isLoading.value = false;
        return result;
    }
    async createNew(defaultValues) {
        let data = {
        };
        // ASK SERVER FOR DEFAULT
        // STUFF defaultValues can override
        if (defaultValues)
            Object.assign(data, defaultValues);
        // STUFF defaultValues cant override
        return this.attachWatchers(data);
    }
    async save(data) {
        let process = addProcess("Save User: " + data.name);
        await this.beforeSave(data);
        let result = await axios.post('/api/users/', data).finally(() => process());
        return result.data;
    }
    async delete(data) {
        let key = data;
        if (typeof (data) === "object")
            key = data._id;
        let process = addProcess("Delete User: " + data.name);
        let result = await axios.delete('/api/users/' + key).finally(() => process());
        return result.data;
    }
    attachWatchers(data) {
        if (Array.isArray(data))
            return data.map(i => this.attachWatchers(i));
        let dataRef = ref(data);
        this.isLoading.value = false;
        watch(dataRef.value, async () => {
            if (this.isSaving)
                return;
            this.isDirty.value = true;
            this.isSaving = true;
            let newData = await this.save(dataRef.value);
            Object.assign(dataRef.value, newData);
            setTimeout(() => {
                this.isSaving = false;
            }, 1)
            this.isDirty.value = false;   
        });
        return dataRef.value;
    }
}

let userApi = new UserApi();

export { userApi, UserApi }
export default userApi;
