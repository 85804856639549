<template>
    <div class="project-action-buttons">
        <div @click="download" class="action-buttons" :class="{'action-button-active':noticeExists,'action-button-inactive':!noticeExists}" title="Word herunterladen">Word erstellt</div>
        <div @click="generatePdf" class="action-buttons" :class="{'action-button-active':noticePdfExists,'action-button-inactive':!noticeExists}" title="PDF herunterladen">{{noticePdfExists?'PDF erstellt':'PDF erstellen'}}</div>
        <div @click="sendMailOwner" class="action-buttons" :class="{'action-button-active':projectNotice.builderSent}">{{projectNotice.builderSent?'Bauherrschaft versendet':'Bauherrschaft versenden'}}</div>
        <div @click="openMailCouncil" class="action-buttons" :class="{'action-button-active':projectNotice.regionalCouncilSent}">{{projectNotice.regionalCouncilSent?'Regierungsp. versendet':'Regierungsp. versenden'}}</div>
    </div>
    <dx-popup v-model:visible="attachmentSelectVisible" :width="900" :height="450" title="Dokument zum Versenden auswählen" :show-close-button="true">
        <template #content>
            <attachment-list ref="attachmentList" :attachment-query="{assignmentType:'project',assignment:$root.project._id,type:'Unterschrieben'}" :allow-upload="false" :allow-download="false" :allow-delete="false" :allow-select="true" @select="sendMailCouncil" :attachment-filter="(a)=>a.name.toLowerCase().endsWith('.pdf')" @no-data="noSelectionFiles"></attachment-list>
        </template>
    </dx-popup>    
</template>
<script lang="js">
    import attachmentApi from '../services/AttachmentApi';
    import { defineComponent } from 'vue';
    import projectNoticeApi from '../services/ProjectNoticeApi';
    import attachmentList from '../components/attachment-list.vue';
    import notify from 'devextreme/ui/notify';
    import { DxPopup, } from 'devextreme-vue/popup';
    export default defineComponent({
        components: {
            attachmentList,
            DxPopup
        },
        props: {
            project: {},
            projectNotice: {},
        },
        data() {
            return {
                id: null,
                noticeExists: false,
                noticePdfExists: false,
                attachmentSelectVisible: false,
                attachmentSelectInitialiced: false
            }
        },
        watch:{
            async project() {
                if (this.reloadExists)
                    await this.reloadExists();
            }
        },
        async created() {
            if (this.reloadExists)
            await this.reloadExists();
        },
        methods: {
            async attachmentExists(name){
                let att = await attachmentApi.getByAssignment("project", this.project, "Vorankündigung"+name,true);
                if(att)
                    return true;
                return false;            
            },
            async download() {
                let att = await attachmentApi.getByAssignment("project", this.project, "Vorankündigung.docx", true);
                if(!att)
                    return notify("Vorankündigung wurde noch nicht generiert!","warning");
                await attachmentApi.download(att._id);
                if (this.reloadExists)
                    await this.reloadExists();
            },
            async generatePdf() {
                let att = await attachmentApi.getByAssignment("project", this.project, "Vorankündigung.docx", true);
                if(!att)
                    return notify("Vorankündigung wurde noch nicht generiert!","warning");

                let pdfAtt = await attachmentApi.getPdfAssignment(att._id);
                if (this.noticePdfExists) {
                    attachmentApi.download(pdfAtt._id);
                }
                if (this.reloadExists)
                    await this.reloadExists();
            },            
            async sendMailOwner() {
                if(this.projectNotice.builderSent){
                    notify("Mail an die Bauherrschaft wurde bereits versendet!", "error");
                    return;
                }
                let att = await attachmentApi.getByAssignment("project", this.$root.project._id, "Vorankündigung.docx");
                if (!att) {
                    notify("Vorankündigung wurde noch nicht generiert!", "error");
                    return;
                }
                await attachmentApi.getPdfAssignment(att._id);
                if (this.reloadExists)
                    await this.reloadExists();
                let resp = await projectNoticeApi.sendMailOwner(this.$root.project._id).catch(() => {
                    notify("Fehler beim Versenden!", "error");
                });
                if (resp.success) {
                    let pn = this.projectNotice;
                    pn.builderSent = true;
                } else if (resp.error) {
                    notify(resp.error, "error");
                }
            },
            async openMailCouncil() {
                if (this.projectNotice.regionalCouncilSent) {
                    notify("Mail an das Regierungspräsidium wurde bereits versendet!", "error");
                    return;
                }
                this.attachmentSelectVisible = true;
                if (this.attachmentSelectInitialiced) {
                    await this.$refs.attachmentList.loadAttachments();
                }
                this.attachmentSelectInitialiced = true;                
            },
            async sendMailCouncil(attachment) {
                this.attachmentSelectVisible = false;
                let resp = await projectNoticeApi.sendMailCouncil(this.$root.project._id, attachment._id).catch(() => {
                    notify("Fehler beim Versenden!", "error");
                });
                if (resp.success) {
                    let pn = this.projectNotice;
                    pn.regionalCouncilSent = true;
                    this.$parent.$refs.noticePreview.refresh();
                } else if (resp.error) {
                    notify(resp.error, "error");
                }
            },
            noSelectionFiles() {
                notify("Keine PDF-Dateien unterschrieben!", "error");
                this.attachmentSelectVisible = false;
            },
            async checkStatusOwner() {
                (await projectNoticeApi.get(this.project)).value;
            },
            async reloadExists(){
                [this.noticeExists, this.noticePdfExists]=await Promise.all([this.attachmentExists('.docx'),this.attachmentExists('.pdf')])
            }
        }
    });
</script>
<style>
    .project-action-buttons {
        background: #454545;
        font-family: "Work Sans", serif;
        line-height: 1.3;
        height: auto;
        padding: 8px 4px;
        white-space: pre-wrap;
        display: grid;
        grid-auto-columns: 1fr;
        border-radius: 2px;
        padding-right: 8px;
        grid-auto-flow: column;
    }

    .action-buttons {
        height: 51px;
        text-align: center;
        margin-left: 5px;
        align-items: center;
        font-size: inherit;
        display: grid;
        position: relative;
        border-radius: 2px;
        background: #fff;
        color: black;
    }


    .action-buttons:hover {
        cursor:pointer;
    }


    .create {
        background: #72bb53;
        color: black;
    }

    .create:hover {
        background-color: #8cd578;
        color: black !important;
    }

    .action-button-active {
        background-color: #72bb53;
        color: black;
    }

        .action-button-active:hover {
            background-color: #72bb53;
            color: black;
        }

    .action-button-inactive {
        background-color: var(--color-background-lighter);
        color: black;
    }

        .action-button-inactive:hover {
            background-color: var(--color-background-lighter);
            color: black;
        }

</style>