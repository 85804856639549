<template>
    <div v-if="$root.project&&formData" class="area-content-body projects-protocol">
        <div class="project-head" :class="{dirty:$root.isDirty}">
            <header-field-editor class="project-number-edit" :input-hint="'Projekt-Nr.'" v-model:data-source="$root.project.number" :allow-edit="false" />
            <div class="v-line"></div>
            <span class="headline">Protokoll <span @click="versionEditorVisible=!versionEditorVisible">{{formData?("(Nr.: "+ (formData.currentVersion)):""}})</span></span>
            <div>
                <span v-if="!formData.currentVersionIsSent" class="head-toolbar-button-compact" :class="{'edited': formData && formData.isCustomized }" @click="generateDocument" :title="formData.isCustomized?'Achtung: Das Protokoll wird neu erstellt. Manuelle Anpassungen gehen verloren!':'Protokoll erstellen'">{{formData&&formData.isCustomized?"edit_document":"description"}}</span>
            </div>
            <div>
                <span v-if="false && !formData.currentVersionIsSent" class="head-toolbar-button-compact" @click="generatePdf" title="PDF erstellen">picture_as_pdf</span>
            </div>
            <div>
                <span class="head-toolbar-button-compact" @click="sendMail" title="Protokoll verschicken">forward_to_inbox</span>
            </div>
            <attachments-button />
        </div>
        <div class="projects-protocol-contain">
            <div class="protocol-form">
                <span class="protocol-form-header">Erstell-Datum</span>
                <span class="protocol-form-header">Aktuelles Bild</span>
                <span class="protocol-form-header">Lageplan</span>
                <span class="protocol-form-header">Karte</span>
                <div class="form-vertical">
                    <dx-date-box v-model:value="formData.creationDate" type="datetime" dateSerializationFormat="yyyy-MM-ddThh:mmZ" />
                    <span v-if="versionEditorVisible" class="protocol-form-header">Aktuelle Protokoll-Nummer</span>
                    <dx-number-box v-if="versionEditorVisible" v-model:value="formData.currentVersion" :step="0"></dx-number-box>
                </div>
                <image-viewer :attachment="{assignmentType:'project',assignment:$route.params.id,name:'ProjektUebersicht.png'}" allowed-types="image/*" :allow-upload="true" :allow-clear="true" />
                <image-viewer :attachment="{assignmentType:'project',assignment:$route.params.id,type:'protocol',name:'Lageplan.png'}" allowed-types="image/*" :allow-upload="true" :allow-clear="true" />
                <ImageViewer v-if="addressMap" :attachment="addressMap._id" :allow-edit="true" :allow-full-screen="true" height="200px" :disable-drop="true" :buttons="[{onClick:()=>updateMap(true),icon:'refresh'}]"></ImageViewer>
            </div>
            <div class="deficiency-items">
                <div v-for="(deficiency,index) in formData.deficiencies" v-bind:key="deficiency.id" class="deficiency-item">
                    <div class="deficiency-actions">
                        <span class="deficiency-number">{{index+1}}</span>
                        <span class="head-toolbar-button-compact no-margin" @click="deleteDeficiency(deficiency)" title="Mangel löschen">delete</span>
                        <div :class="{'navigation-button-inactive':index==0}" class="navigation-button material-symbols-outlined" @click="moveToTop(deficiency)" title="An den Anfang verschieben">keyboard_double_arrow_up</div>
                        <div :class="{'navigation-button-inactive':index==0}" class="navigation-button material-symbols-outlined" @click="moveUp(deficiency)" title="Nach oben verschieben">keyboard_arrow_up</div>
                        <div :class="{'navigation-button-inactive':index==formData.deficiencies.length -1}" class="navigation-button material-symbols-outlined" @click="moveDown(deficiency)" title="Nach unten verschieben">keyboard_arrow_down</div>
                        <div :class="{'navigation-button-inactive':index==formData.deficiencies.length -1}" class="navigation-button material-symbols-outlined" @click="moveToBottom(deficiency)" title="An das Ende verschieben">keyboard_double_arrow_down</div>
                    </div>
                    <DxForm v-if="deficiency" :form-data="deficiency" :col-count="5" :show-validation-summary="true" label-mode="outside" label-location="top" :scrollingEnabled="true" class="deficiency-form">
                        <DxSimpleItem data-field="groupItemId" :label="{text:'Mangelgruppe'}">
                            <template #default>
                                <ListLookup list="project_deficiency" @manual-change="deficiency.itemId=null" :allow-empty="true" v-model:value="deficiency.groupItemId" :wrap-item-text="true"></ListLookup>
                            </template>
                        </DxSimpleItem>
                        <DxSimpleItem data-field="itemId" :label="{text:'Mangel'}">
                            <template #default>
                                <ListLookup v-if="deficiency.groupItemId" :list="'child_'+deficiency.groupItemId" v-model:value="deficiency.itemId" :wrap-item-text="true"></ListLookup>
                                <ListLookup v-if="!deficiency.groupItemId" parent-list="project_deficiency" v-model:value="deficiency.itemId" :wrap-item-text="true" @parent-child-changed="(e)=>deficiency.groupItemId=e"></ListLookup>
                            </template>
                        </DxSimpleItem>
                        <DxSimpleItem data-field="tradeId" :label="{text:'Gewerk'}">
                            <template #default>
                                <ListLookup v-model:value="deficiency.tradeId" list="project_trades" :allow-empty="true" @manual-change="handleTradeItemChanged(deficiency,$event)"></ListLookup>
                            </template>
                        </DxSimpleItem>
                        <DxSimpleItem data-field="organizationId" :label="{text:'Firma'}">
                            <template #default>
                                <OrganizationLookup v-model:value="deficiency.organizationId" :show-organization="true" @manual-change="handleOrganizationChanged(deficiency,$event)" :filteredIds="$root.project.involvedContacts.map(ic=>ic.organizationId)"></OrganizationLookup>
                            </template>
                        </DxSimpleItem>
                        <DxSimpleItem data-field="dueId" :label="{text:'Zu erledigen bis'}">
                            <template #default>
                                <ListLookup list="protocol_due" v-model:value="deficiency.dueId" default="umgehend"></ListLookup>
                            </template>
                        </DxSimpleItem>

                        <DxSimpleItem :label="{text:'Vorschau'}" :col-span="5">
                            <template #default>
                                <ListLookup style="white-space:pre-wrap;" :list="'child_'+deficiency.groupItemId" v-model:value="deficiency.itemId" :view="true" :display="item=>getListText(item)"></ListLookup>
                            </template>
                        </DxSimpleItem>
                        <DxSimpleItem data-field="description" editor-type="dxTextArea" :col-span="5" :editor-options="{maxHeight:200,autoResizeEnabled:true}" :label="{text:'Zusatzbemerkung'}" />
                    </DxForm>
                    <dx-sortable :data="deficiency.id" filter=".sortable-image" v-if="formData.locations && projectAttachments" class="deficiency-imgs" group="image-group" item-orientation="horizontal" @reorder="onImgDrop($event)" @add="onImgDrop($event)">
                        <image-viewer :attachment="{assignmentType:'project',assignment:$route.params.id,type:'deficiencies'}" :allow-upload="true" :clear-on-upload="true" :allow-clear="true" @upload="(att)=>addLocation(deficiency,att)" height="auto" allowed-types="image/*" />        
                        <image-viewer class="sortable-image" v-for="location in formData.locations.filter(l=>l.deficiencyId === deficiency.id && projectAttachments.some(pa=>pa._id===l.id))" v-bind:key="location.id" :data-id="location.id" :attachment="location.id" height="auto" allowed-types="image/*" :allow-clear="true" :disable-drop="true" :buttons="[{onClick:()=>{showEditLocation(deficiency,location)},icon:'edit'}]" />
                    </dx-sortable>
                </div>
            </div>
            <div style="text-align:center">
                <span class="head-toolbar-button-compact" @click="addDeficiency" title="Mangel hinzufügen">add</span>
                <span class="head-toolbar-button-compact" @click="undoDelete" title="Löschen rückgängig" :class="{ disabled: !canUndoDelete }">undo</span>
            </div>
        </div>
        <DxPopup v-model:visible="editPopupVisible"
                 :fullscreen="true"
                 :drag-enabled="false"
                 :hide-on-outside-click="true"
                 :show-close-button="true"
                 :show-title="true">
            <div class="projects-protocol-edit-popup">
                <image-viewer v-if="editDeficiency && editLocation" :attachment="editLocation.id" :allow-edit="true" :allow-clear="true" height="auto" :edit-in-place="true"></image-viewer>
                <dx-form v-if="editDeficiency && editLocation" :form-data="editLocation" :show-validation-summary="true" label-mode="outside" label-location="top" :scrollingEnabled="true">
                    <DxSimpleItem data-field="componentId" :label="{text:'Bauteil'}">
                        <template #default>
                            <ListLookup list="protocol_component" v-model:value="editLocation.componentId"></ListLookup>
                        </template>
                    </DxSimpleItem>
                    <DxSimpleItem data-field="floorId" :label="{text:'Geschoss'}">
                        <template #default>
                            <ListLookup list="protocol_floor" v-model:value="editLocation.floorId"></ListLookup>
                        </template>
                    </DxSimpleItem>
                    <DxSimpleItem data-field="directionId" :label="{text:'Himmelsrichtung'}">
                        <template #default>
                            <ListLookup list="protocol_direction" v-model:value="editLocation.directionId"></ListLookup>
                        </template>
                    </DxSimpleItem>
                </dx-form>
            </div>
        </DxPopup>



    </div>
    <div v-else-if="noProtocol" class="area-content-body">
        Bitte starte ein Protokoll über die Mobile APP.
    </div>
    <div v-else class="area-content-body">
        Daten werden geladen...
    </div>

   
    <EmailStatusPopup :visible="mailStatusPopupVisible"
                      :validEmails="validEmails"
                      :invalidEmails="invalidEmails" />       
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import { DxDateBox } from 'devextreme-vue/date-box'
    import { DxNumberBox } from 'devextreme-vue/number-box'
    import { DxTextArea } from 'devextreme-vue/text-area'
    import { DxSortable } from 'devextreme-vue/sortable'
    import HeaderFieldEditor from '../components/header-field-editor.vue';
    import AttachmentsButton from '../components/project-attachments-button.vue';
    import { confirm } from 'devextreme/ui/dialog'
    import projectProtocolApi from '../services/ProjectProtocolApi'
    import attachmentApi from '../services/AttachmentApi';
    import ImageViewer from '../components/image-viewer.vue';
    import {
        DxForm,
        DxSimpleItem
    } from 'devextreme-vue/form';
    import ListLookup from '../components/list-lookup.vue';
    import OrganizationLookup from '../components/organization-lookup.vue';
    import mapApi, { defaultMapStyle } from '../services/MapApi';
    import projectApi from '../services/ProjectApi';
    import { guid } from '../services/Util';
    import {isEqual } from 'lodash'
    import { afterProcessing } from '../services/Processor';
    import {
        DxPopup
    } from 'devextreme-vue/popup';
    import notify from 'devextreme/ui/notify';
    import EmailStatusPopup from '../components/email-status-popup.vue';


    export default defineComponent({
        components: {
            HeaderFieldEditor,
            AttachmentsButton,
            ImageViewer,
            DxForm,
            DxSimpleItem,
            ListLookup,
            OrganizationLookup,
            DxDateBox,
            DxPopup,
            DxNumberBox,
            // eslint-disable-next-line
            DxTextArea,
            EmailStatusPopup,
            DxSortable
        },
        watch: {
            async '$route.params'() {
                if(this.$route.path.match(/\/protocol(\/|$)/i))
                    this.fetchData();
            },
            '$root.project': {
                async handler() {
                    this.fetchData();
                }
            },
            editPopupVisible(){
                if(!this.editPopupVisible){
                    this.editDeficiency =null;
                    this.editLocation =null;                    
                }
            },
            'formData': {
                async handler() {
                    if (this.protocol && this.formData) {
                        if (!isEqual(this.protocol, this.formData)) {
                            Object.assign(this.protocol, this.formData)
                        }
                    }
                },
                deep: true
            }
        },
        data() {
            return {
                protocol: null,
                formData: null,
                projectAttachments: [],
                addressMap: null,
                editPopupVisible: false,
                editDeficiency: null,
                editLocation: null,
                versionEditorVisible: false,
                noProtocol: false,
                texts: {},
                mailStatusPopupVisible: false,
                validEmails: [],
                invalidEmails: [],
                stopWatcher: null,
                protocolAttachment:null
            };
        },
        computed: {
            canUndoDelete() {
                return this.protocol.deletedDeficiencies && this.protocol.deletedDeficiencies.length > 0;
            }
        },
        async created() {
            if (this.$root.project && this.$route.params.id == this.$root.project._id) {
                this.fetchData();
            } else {
                this.formData = null;
                this.$root.loadProject(this.$route.params.id);
            }
        },
        methods: {
            onImgDrop(e) {
                if (e.toData == e.fromData && e.toIndex == e.fromIndex)
                    return;
                // Get the current location for "toIndex"
                let toIndexLocation = this.formData.locations.filter(l => l.deficiencyId === e.toData && this.projectAttachments.some(pa => pa._id === l.id))[e.toIndex];
                // Get the locationId for the currently moved element
                let locationId = e.itemElement.getAttribute("data-id")
                let location = this.formData.locations.find(l => l.id == locationId);
                // Set the deficiencyId for the dragged element
                location.deficiencyId = e.toData
                // If an element to move to was present
                if (toIndexLocation) {
                    // Get the own index
                    let ownIndex = this.formData.locations.findIndex(l => l.id == locationId);
                    this.formData.locations.splice(ownIndex, 1);
                    // Get the index of the element to which to move to
                    let toIndex = this.formData.locations.findIndex(l => l.id == toIndexLocation.id);
                    this.formData.locations.splice(toIndex, 0, location);
                }
            },
            async attachProtocolWatcher() {
                if (this.protocolAttachment) {
                    if (this.stopWatcher)
                        this.stopWatcher();
                    console.log("CURRENT PROTOCOL ATTACHMENT:", this.protocolAttachment);
                    this.stopWatcher = attachmentApi.onVersionChange(this.protocolAttachment._id, async () => {
                        console.log("PROTOCOL HAS CHANGED!");
                        this.$nextTick(async () => {
                            this.protocol = await projectProtocolApi.get(this.$route.params.id)
                            this.formData = structuredClone(this.protocol);
                        });
                    })
                }
            },
            async fetchData() {
                if (this.$route.params.id) {
                    this.$root.fileDirectory = "protocol";
                    this.protocol = await projectProtocolApi.get(this.$route.params.id)
                    if (!this.protocol) {
                        this.formData = null;
                        this.projectAttachments = [];
                        this.protocol = null;
                        this.noProtocol = true;
                        console.log("NO PROTOCOLL")
                        return;
                    }
                    if (this.protocol.currentVersionIsSent) {
                        this.formData = null;
                        this.projectAttachments = [];
                        this.protocol = null;
                        this.noProtocol = true;
                        console.log("currentVersionIsSent")
                        return;
                    }
                    this.formData = structuredClone(this.protocol);
                    this.projectAttachments = await attachmentApi.getAllByAssignment("project", this.$route.params.id, 'deficiencies');
                    this.protocollAttachment = await attachmentApi.getByAssignment("project", this.$route.params.id, `Protokoll_${this.$root.project.number}_${this.protocol.currentVersion}.docx`);
                    this.attachProtocolWatcher();
                    this.updateMap(false);
                }
            },
            async addLocation(deficiency,att) {
                this.formData.locations.push({ id: att._id, deficiencyId: deficiency.id });
                afterProcessing(() => {
                    this.fetchData();
                })  
            },
            getListText(item) {
                if (!item)
                    return " - ";
                if (!item.data)
                    return " - ";
                try {
                    return JSON.parse(item.data).Text
                } catch { console.error("failed to fetch text from item"); }
                return " - ";
            },
            addDeficiency() {
                this.protocol.deficiencies.push({ id: guid() });
                afterProcessing(() => {
                    this.fetchData();
                })               
            },
            undoDelete() {
                if (this.protocol.deletedDeficiencies && this.protocol.deletedDeficiencies.length > 0) {
                  
                    let lastDeletedDeficiency = this.protocol.deletedDeficiencies.pop();
                   
                    this.protocol.deficiencies.push(lastDeletedDeficiency);
                }
                

                afterProcessing(() => {
                    this.fetchData();
                })
            },
            async deleteDeficiency(deficiency) {
                let i = this.protocol.deficiencies.findIndex(d => d.id === deficiency.id);
                if (!await confirm("Wollen Sie den Mangel löschen?", "Löschen"))
                    return;
                if (i >= 0) {
                    
                    if (!this.protocol.deletedDeficiencies) {
                        this.protocol.deletedDeficiencies = [];
                    }
                    let deletedDeficiency = this.protocol.deficiencies.splice(i, 1)[0];
                    this.protocol.deletedDeficiencies.push(deletedDeficiency); 
                   
                    afterProcessing(() => {
                        this.fetchData();
                    })
                }
            },
            async showEditLocation(deficiency,location){
                if(!deficiency){
                    this.editPopupVisible=false;
                    return;
                }
                    
                this.editDeficiency =deficiency;
                this.editLocation =location;
                this.editPopupVisible =true;
            },
            async generateDocument() {
                try {
                    let pId = this.$route.params.id;
                    let att = await projectProtocolApi.generateDocument(pId);                                       
                    let pdf = await attachmentApi.getPdfAssignment(att._id);
                    this.formData.isCustomized = false;        
                    this.protocolAttachment = att;
                    this.attachProtocolWatcher();
                    this.$root.toast("Protokoll", [{
                        icon: "download",
                        hint: "Protokoll herunterladen",
                        onClick: async (t) => {
                            await attachmentApi.download(att._id);
                            t.remove();
                        }
                    }, {
                        icon: "picture_as_pdf",
                        hint: "PDF herunterladen",
                        onClick: async (t) => {
                            await attachmentApi.download(pdf._id);
                            t.remove();
                        }
                    }], 5000);
                } catch {
                    notify("Fehler beim Erstellen der Unterlage", "error");
                }
            },
            async generatePdf(e, id) {
                console.log("[NO UNUSED] pdf", e);
                let att = await attachmentApi.getByAssignment("project", id || this.$route.params.id, `Protokoll_${this.$root.project.number}_${this.protocol.currentVersion}.docx`);
                if (!att)
                    att = await projectProtocolApi.generateDocument(id || this.$route.params.id);
                let pdf = await attachmentApi.getPdfAssignment(att._id);
                this.$root.toast("Protokoll PDF", [{
                    icon: "download",
                    hint: "Unterlage PDF herunterladen",
                    onClick: async (t) => {
                        await attachmentApi.download(pdf._id);
                        t.remove();
                    }
                }], 5000);
            },
            async finish(e, id) {
                console.log("[NO UNUSED] finish", e);
                let pdf = await projectProtocolApi.finishDocument(id || this.$route.params.id)
                this.fetchData()
                this.$root.toast("Protokoll PDF", [{
                    icon: "download",
                    hint: "Unterlage PDF herunterladen",
                    onClick: async (t) => {
                        await attachmentApi.download(pdf._id);
                        t.remove();
                    }
                },], 5000);
            },
            async sendMail(e, id) {              
                //console.log("[NO UNUSED] finish", e);
                

                if (this.protocol.currentVersionIsSent) {
                    if (!await confirm("Die aktuelle Version wurde schon versendet - wollen Sie diese erneut an den Verteiler senden?","Erneut versenden?")) {
                        return;
                    }
                }else
                {
                       if (!await confirm("Möchten Sie das Protokoll jetzt an den Verteiler senden?","Jetzt versenden?")) {
                         return;
                       }
                }

                if (this.formData.isCustomized) {
                    if (!await confirm("Die Word-Vorlage für das Protokoll wurde manuell bearbeitet.<br/> Möchten Sie diese bearbeitete Version senden, dann klicken sie auf <em>[Ja]</em>.\n <br/><br/>Mit Klick auf <em>[Nein]</em> wird das Dokument vor dem Versenden neu generiert. <br/>Manuelle Anpassungen werden dabei <i>überschrieben</i>.", "Bearbeitetes Protokoll versenden")) {
                        await this.generateDocument();
                    }
                } else {
                    // always re-generate when not customized
                    await this.generateDocument();
                }

                /*
                if (!await confirm("[DEBUG] Wirklich versenden?", "DEBUG"))
                    return;*/

                let response = await projectProtocolApi.sendMail(id || this.$route.params.id).catch(() => {
                    notify("Fehler beim Versenden!", "error");
                })    
                
                                
                if (response.success) {
                    notify("Mail versandt", "success"); 
                    console.log ("Response from send Mail:", response);
                    this.validEmails = response.receivers;
                    this.invalidEmails = response.invalids;
                    this.mailStatusPopupVisible  = !this.mailStatusPopupVisible;
                    this.fetchData();
                }                    

                if (response.error)
                    notify("Mail nicht versendet:" + response.error, "error");
            },
            async updateMap(deleteIfExists) {
                if (deleteIfExists && this.addressMap) {
                    await attachmentApi.delete(this.addressMap._id);
                    this.addressMap = null;
                }
                let project = await projectApi.get(this.$route.params.id);
                let address = project.streetAndNr + ";" + project.zipCode + ";" + project.city;
                let assignment = await mapApi.getAssignment(address)
                let attachment = await attachmentApi.getByAssignment(assignment.assignmentType, assignment.assignment, assignment.name);
                if (attachment) {
                    this.addressMap = attachment;
                } else {
                    this.addressMap = await mapApi.get(address, defaultMapStyle, 18);
                }
            },
            moveToTop(deficiencies) {
                let i = this.formData.deficiencies.findIndex(h => h.id === deficiencies.id)
                let item = this.formData.deficiencies.splice(i, 1);
                this.formData.deficiencies.splice(0, 0, item[0]);
            },
            moveUp(deficiencies) {
                let i = this.formData.deficiencies.findIndex(h => h.id === deficiencies.id)
                let item = this.formData.deficiencies.splice(i, 1);
                this.formData.deficiencies.splice(i - 1, 0, item[0]);
            },
            moveDown(deficiencies) {
                let i = this.formData.deficiencies.findIndex(h => h.id === deficiencies.id);
                let item = this.formData.deficiencies.splice(i, 1);
                this.formData.deficiencies.splice(i + 1, 0, item[0]);
            },
            moveToBottom(deficiencies) {
                let i = this.formData.deficiencies.findIndex(h => h.id === deficiencies.id);
                let item = this.formData.deficiencies.splice(i, 1);
                this.formData.deficiencies.push(item[0]);
            },
            async handleTradeItemChanged(deficiency,id) {                
                if (id) {
                    let contactByTrade = this.$root.project.involvedContacts.find(ic => ic.tradeId === id);
                    if (deficiency && contactByTrade) {
                        deficiency.organizationId = contactByTrade.organizationId;
                    }
                }
            },
            async handleOrganizationChanged(deficiency,id) {                
                if (id) {
                    let tradeByOrganization = this.$root.project.involvedContacts.find(ic => ic.organizationId === id);
                    if (deficiency) {
                        if (tradeByOrganization) {
                            deficiency.tradeId = tradeByOrganization.tradeId;
                        }
                        else {
                            deficiency.tradeId = null;
                        }
                    }
                }
            }
        }
    });
</script>


<style>
    .navigation-buttons {
        display: grid;
        grid-auto-flow: row;
        grid-auto-rows: max-content;
        grid-gap: 5px;
    }

    .navigation-button {
        padding: 2px;
        margin: 3px;
        border-radius: var(--default-border-radius);
        background: var(--color-background);
        color: var(--color-text);
    }
        .navigation-button:not(.navigation-button-inactive):hover {
            background: var(--color-background-lighter);
        }

    .navigation-button-inactive {
        opacity: 0.1;
    }

    .hazard-item:last-child {
        border-bottom: none;
    }

    .edited{
        color:var(--color-warn);
    }

    .projects-protocol-contain {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: minmax(0,max-content) 2fr max-content;
        grid-gap: 20px;
    }

    .protocol-form {
        display: grid;
        grid-auto-columns: 1fr;
        grid-template-columns: 1fr 1fr 1fr 215px;
        grid-template-rows: max-content minmax(0,1fr);
        align-items: start;
        grid-gap: 10px;
    }
    .form-vertical {
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-rows: max-content;
        grid-auto-flow: row;
        grid-gap: 10px;
    }

    .area-content-body.projects-protocol {
        display: grid;
        grid-template-rows: max-content minmax(0,1fr);
    }

    .deficiency-items {
        overflow: auto;
        height: 100%;
        padding: 5px;
        padding-right: 20px;
        box-shadow: inset 0 0 3px rgba(0,0,0,0.5);
        display: grid;
        grid-auto-rows: max-content;
        grid-gap: 5px;
    }

    .deficiency-item {
        display: grid;
        grid-template-columns: max-content 2fr 1fr;
        grid-gap: 10px;
        padding: 20px;
        background: var(--color-background-light);
        border-radius: var(--default-border-radius);
    }
    .deficiency-actions{
        display: grid;
        grid-template-rows: max-content max-content;
        align-items: start;
        grid-gap: 10px;
    }
    .deficiency-number {
        padding: 8px;
        background: var(--color-dx-dark);
        height: 19px;
        width: 19px;
        text-align: center;
        border-radius: var(--default-border-radius);
    }
    .deficiency-imgs {
        display: grid;
        grid-auto-columns: minmax(200px,1fr);
        grid-template-rows: 1fr;
        grid-auto-flow: column;
        grid-gap: 10px;
        background: var(--color-background);
        overflow-x: auto;
        padding: 10px;
    }
    .protocol-form-header-map{
        display:grid;
        grid-template-columns: 1fr max-content;
    }
    .protocol-form-header {
        align-self: end;
    }

    .projects-protocol-edit-popup {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        height: 100%;
        grid-gap: 10px;
    }
</style>

<style scoped>    
        .head-toolbar-button-compact.disabled {
            cursor: not-allowed;
            opacity: 0.5; /* You can adjust the style for disabled state */
        }
</style>

