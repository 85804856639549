<template>
    <div>
        <DxDataGrid ref="adminList"
                    class="admin-list-grid"
                    :focused-row-enabled="true"
                    :auto-navigate-to-focused-row="autoNavigateToFocusedRow"
                    key-expr="_id"
                    height="100%"
                    :scrolling="{mode: 'virtual'}"
                    :data-source="items"
                    @editingStart="editing=true"
                    @editCanceling="editing=false"
                    @saved="editing=false"
                    @saving="savingItem"
                    @focusedCellChanged="updateEditingState"
                    @toolbar-preparing="$root.addGridTitle($event,'');addApplySortButton($event)">
            <DxRowDragging :allow-reordering="true"
                           :on-reorder="reorder"
                           :show-drag-icons="true" />
            <DxColumn data-field="value" caption="Wert" />
            <DxColumn data-field="tooltip" caption="Tooltip" />
            <DxColumn v-if="list && !list.dataFields" data-field="data" caption="Metadaten" />
            <DxColumn type="buttons">
                <DxButton name="delete" />
                <DxButton v-if="(list && list.dataFields)?true:false"
                          hint="Formular"
                          icon="rowproperties"
                          @click="showForm" />
                <DxButton v-if="list && list.allowChildren && !hasUnsavedChanges"
                          hint="Unterelemente"
                          icon="bulletlist"
                          @click="subItems" />
                <DxButton v-if="list && list.allowImage"
                          hint="Bild Hinterlegen"
                          icon="image"
                          @click="showImage" />
            </DxColumn>
            <DxEditing :allow-updating="true"
                       :allow-deleting="true"
                       :allow-adding="true"
                       new-row-position="last"
                       mode="batch"                                             
                       :useIcons="true" />
        </DxDataGrid>
        <dx-popup v-model:visible="formVisible"
                  width="750px"
                  height="320px"
                  :fullscreen="true"
                  :drag-enabled="false"
                  :hide-on-outside-click="true"
                  :show-close-button="false"
                  :show-title="false">
            <div class="mobile-scroll">
                <dx-form :form-data="formElement" :show-validation-summary="true" label-mode="outside" label-location="top" :col-count="formSettings.colCount">
                    <dx-simple-item v-for="item in formItems" v-bind:key="item.id" :data-field="item.dataField" :col-span="item.colSpan" :editor-type="item.editorType" :label="item.label?{text:item.label}:null" :editor-options="item.editorOptions"></dx-simple-item>
                </dx-form>
            </div>
            <dx-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="{ icon: 'save', onClick: saveForm }" />
        </dx-popup>
        <dx-popup v-model:visible="imageUploadVisible"
                  width="300px"
                  height="300px"
                  :fullscreen="true"
                  :drag-enabled="false"
                  :hide-on-outside-click="true"
                  :show-close-button="false"
                  :show-title="false">
            <div class="mobile-scroll">
                <image-viewer v-if="list && imageListItem" :attachment="{assignmentType:'system',assignment:'listItems',type:list._id,name:imageListItem+'.png'}" allowed-types="image/*" :allow-upload="true" :allow-description="true" height="253px" />
            </div>
        </dx-popup>
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import DxDataGrid,{        
        DxColumn,
        DxEditing,
        DxRowDragging,
        DxButton
    } from 'devextreme-vue/data-grid';
    import listApi, { listSettings } from '../services/ListApi';
    import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
    import { DxForm,DxSimpleItem } from 'devextreme-vue/form';
    import ImageViewer from '../components/image-viewer.vue'
    export default defineComponent({
        components: {
            DxDataGrid,
            DxColumn,
            DxEditing,
            DxRowDragging,
            DxButton,
            DxPopup,
            DxForm,
            DxSimpleItem,
            DxToolbarItem,
            ImageViewer
        },
        data() {
            return {
                list:null,
                items: [],
                formItems: [],
                formElement: null,
                formElementId: null,
                formVisible: false,
                formSettings: { colCount: 3 },
                imageUploadVisible: false,
                imageListItem: null,
                editing: false
            };
        },
        watch: {
            'editing'() {
                console.log("editing", this.editing);
            },
            '$route.params.id'(){
                this.fetchData();
            },
            'imageUploadVisible'() {
                if (!this.imageUploadVisible)
                    this.imageListItem = null;
            }           
        },
        async created() {
            await this.fetchData();
        },      
        computed: {
            hasUnsavedChanges() {
                console.log("adminList editing state:", this.editing); // referenced to force updates to the computed property whenever the editing state changes                                
                return this.$refs.adminList && this.$refs.adminList.instance && this.$refs.adminList.instance.option("editing.changes").filter(c => c.type === 'insert').length  > 0;
            }
        },
        methods: {
            async fetchData(){
                // await this.clear();
                if (!this.$route.params?.id) {
                    console.error("[ERR] SHOULD NOT HAPPEN", this.$route)
                    return;
                }
                this.list = await listApi.get(this.$route.params.id);
                if (!this.$refs.adminList) {
                    console.error("[ERR] Adminlist not here?")
                    return;
                }
                    
                let titleElem = this.$refs.adminList.instance.element().querySelector(".grid-title");
                titleElem.innerText = "";      
                if (this.list.parentListItemId) {
                    let gridBack = document.createElement("span");
                    gridBack.className = "head-toolbar-button-compact no-margin";
                    gridBack.innerText = "arrow_back";
                    gridBack.title = "Zurück zur Liste";
                    gridBack.addEventListener("click", async () => {
                        let parentListItem = await listApi.getItem(this.list.parentListItemId)
                        this.$router.push("/Admin/Lists/" + parentListItem.listId)
                    });
                    titleElem.appendChild(gridBack);
                }
                
                let gridTitle = document.createElement("span");
                gridTitle.className = "grid-title-name"
                gridTitle.innerText = this.list.name;
                titleElem.appendChild(gridTitle)
                    
                this.items = await listApi.getListItems(this.$route.params.id);    
            },
            async reorder(e) {
                e.itemData.rank = e.toIndex;
                await listApi.saveItem(e.itemData);
                this.fetchData();
            },
            async addApplySortButton(e) {
                let op = {
                    locateInMenu:"auto",
                    location:"after",
                    name:"applySort",
                    options: {
                        icon: 'orderedlist',
                        disabled: false,
                        text: 'Neue Zeile',
                        onClick: async () => {
                            const grid = this.$refs.adminList.instance;
                            const filterExpr = grid.getCombinedFilter(true);
                            const dataSource = grid.getDataSource();
                            const loadOptions = dataSource.loadOptions();
                            this.$root.$refs.loadPanel.instance.option("visible", true);
                            let data = await dataSource.store().load({ filter: filterExpr, sort: loadOptions.sort, group: loadOptions.group });
                            let tasks = []
                            let d = 0;
                            for (let i in data) {
                                data[i].rank = parseInt(i);
                                tasks.push(listApi.saveItem(data[i], true).then(() => {
                                    d++;
                                    this.$root.$refs.loadPanel.instance.option("message", `Sortiere - ${d}/${data.length}`);
                                }))
                            }
                            await Promise.all(tasks);
                            this.$root.$refs.loadPanel.instance.option("visible", false);
                            this.fetchData();
                        }
                    },
                    showText:"inMenu",
                    sortIndex:20,
                    widget:"dxButton"
                }
                e.toolbarOptions.items.push(op);                

                /*
                    <DxButton v-if="(list && list.dataFields)?true:false"
                        hint="Aktuelle Sortierung übernehmen"
                        icon="orderedlist"
                                @click="applySort" />
                */
            },
            async showForm(e) {
                let formItems = [];
                let settings = {colCount:3};
                for (let key in this.list.dataFields) {
                    let dataField = this.list.dataFields[key];
                    // Filter out keys that are like _colCount but do for '_' (self reference / single value)
                    if (!key.startsWith("_") || key == "_") {
                        if (typeof (dataField) === "string" && dataField.startsWith("{"))
                            dataField = JSON.parse(dataField)
                        formItems.push({
                            id: key,
                            dataField: key,
                            colSpan: dataField.colSpan || 1,
                            editorType: dataField.editorType,
                            label: dataField.label,
                            editorOptions: dataField.editorOptions
                        })
                    } else {
                        if (key == "_colCount") {
                            settings.colCount = dataField;
                        }
                    }                    
                }
                this.formSettings = settings;
                this.formItems = formItems;
                let itemId = e.row.key;
                let itemMetaData = this.items.find(i => i._id == itemId).data;
                if (this.formItems.length == 1 && this.formItems[0].id == "_") {
                    // If the key is '_' then build the formElement as the data without parsing 
                    this.formElement = { "_": itemMetaData };
                } else {
                    // Otherwise parse the json
                    let dataString = itemMetaData;
                    let data = {};
                    if (dataString) {
                        try {
                            data=JSON.parse(dataString)
                        } catch (e) {
                            console.error("Could not parse ", dataString, e);
                        }
                    }
                    this.formElement = data;                    
                }
                this.formElementId = itemId;
                this.formVisible = true;
            },
            async saveForm() {
                let item = this.items.find(i => i._id == this.formElementId);
                if (this.formItems.length == 1 && this.formItems[0].id == "_") {
                    item.data = this.formElement["_"];
                    await listApi.saveItem(item);
                } else {
                    item.data = JSON.stringify(this.formElement)
                    await listApi.saveItem(item);
                }
                this.formVisible = false;
                console.log("DATA",item.data)
            },
            async subItems(e) {
                let itemId = e.row.key;
                let listName = "child_" + itemId;
                let subList = await listApi.getByName(listName);
                if (!subList) {
                    let dataQ = "." + this.list.internalName
                    let dataFields = dataQ in listSettings ? listSettings[dataQ].dataFields : null
                    let allowImage = dataQ in listSettings ? listSettings[dataQ].allowImage : false
                    let allowChildren = dataQ in listSettings ? listSettings[dataQ].allowChildren : false
                    subList = await listApi.save({
                        name: this.list.name + ' - ' + e.row.data.value,
                        internalName: listName,
                        parentListItemId: itemId,
                        dataFields,
                        allowImage,
                        allowChildren
                    })
                }
                this.$router.push("/Admin/Lists/" + subList._id);
            },
            async showImage(e) {
                let itemId = e.row.key;
                this.imageListItem = itemId;
                this.imageUploadVisible = true;
            },
            async savingItem(e){
                for(let change of e.changes){
                    let item = this.items.find(t=>t._id===change.key);
                    if(change.type === "update"){
                        Object.assign(item,change.data);
                        await listApi.saveItem(item);
                    }
                    else if(change.type === "insert"){
                        item = change.data;
                        item.rank = this.items.length;
                        item.listId = this.$route.params.id;
                        await listApi.saveItem(item);
                    }
                    else if(change.type === "remove"){
                        await listApi.deleteItem(change.key)
                    }
                    else {
                        console.log("DONT KNOW",change.type)
                    }
                }
                this.fetchData();
            },
            updateEditingState(e) {
                this.editing = e.component.option("editing.changes").length > 0;                
            }
        },
    });
</script>

<style>
    .admin-list-contain {
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-template-rows: minmax(0, 1fr);
        grid-gap: 10px;
    }
    .admin-list-grid .dx-datagrid {
        padding: 10px;
        background-color: #2a2a2a;
    }
    .grid-title-name{
        margin: 0 10px;
    }
</style>
