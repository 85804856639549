import {SvgHelper, ColorPickerPanel,LineWidthPanel,LineStylePanel,OpacityPanel, RectangleMarker } from 'markerjs2'

class FilledRectangularBoxMarker extends RectangleMarker {

    constructor(container, overlayContainer, settings) {
        super(container, overlayContainer, settings);

        this.strokeColor = settings.defaultColor;
        this.strokeWidth = settings.defaultStrokeWidth;
        this.strokeDasharray = settings.defaultStrokeDasharray;
        this.fillColor = settings.defaultFillColor;

        this.setOpacity = this.setOpacity.bind(this);
        this.createVisual = this.createVisual.bind(this);

        this.strokePanel = new ColorPickerPanel(
            'Line color',
            [...settings.defaultColorSet, 'transparent'],
            settings.defaultColor,
            undefined,
            'stroke-color-panel'
        );
        this.strokePanel.onColorChanged = this.setStrokeColor;

        this.fillPanel = new ColorPickerPanel(
            'Fill color',
            [...settings.defaultColorSet, 'transparent'],
            this.fillColor,
            '<svg viewBox="0 0 24 24"><path d="M19,11.5C19,11.5 17,13.67 17,15A2,2 0 0,0 19,17A2,2 0 0,0 21,15C21,13.67 19,11.5 19,11.5M5.21,10L10,5.21L14.79,10M16.56,8.94L7.62,0L6.21,1.41L8.59,3.79L3.44,8.94C2.85,9.5 2.85,10.47 3.44,11.06L8.94,16.56C9.23,16.85 9.62,17 10,17C10.38,17 10.77,16.85 11.06,16.56L16.56,11.06C17.15,10.47 17.15,9.5 16.56,8.94Z" /></svg>',
            'fill-color-panel'
        );
        this.fillPanel.onColorChanged = this.setFillColor;

        this.strokeWidthPanel = new LineWidthPanel(
            'Line width',
            settings.defaultStrokeWidths,
            settings.defaultStrokeWidth
        );
        this.strokeWidthPanel.onWidthChanged = this.setStrokeWidth;

        this.strokeStylePanel = new LineStylePanel(
            'Line style',
            settings.defaultStrokeDasharrays,
            settings.defaultStrokeDasharray
        );
        this.strokeStylePanel.onStyleChanged = this.setStrokeDasharray;

        this.opacityPanel = new OpacityPanel(
            'Opacity',
            settings.defaultOpacitySteps,
            this.opacity
        );
        this.opacityPanel.onOpacityChanged = this.setOpacity;

    }

    /**
   * Sets marker's opacity.
   * @param opacity - new opacity value (0..1).
   */
     setOpacity(opacity) {
        this.opacity = opacity;
         if (this.visual) {             
             this.visual.removeAttribute("opacity");
             SvgHelper.setAttributes(this.visual, [['fill-opacity', this.opacity.toString()], ['stroke-opacity', '1']]);
        }
        this.stateChanged();
    }

    createVisual() {
        this.visual = SvgHelper.createRect(1, 1, [
            ['fill', this.fillColor],
            ['stroke', this.strokeColor],
            ['stroke-width', this.strokeWidth.toString()],
            ['stroke-dasharray', this.strokeDasharray],
            ['fill-opacity', this.opacity.toString()],
            ['stroke-opacity', '1']
        ]);
        this.addMarkerVisualToContainer(this.visual);
    }


    static get fillColorIcon() {
        return '<svg viewBox="0 0 24 24"><path d="M19,11.5C19,11.5 17,13.67 17,15A2,2 0 0,0 19,17A2,2 0 0,0 21,15C21,13.67 19,11.5 19,11.5M5.21,10L10,5.21L14.79,10M16.56,8.94L7.62,0L6.21,1.41L8.59,3.79L3.44,8.94C2.85,9.5 2.85,10.47 3.44,11.06L8.94,16.56C9.23,16.85 9.62,17 10,17C10.38,17 10.77,16.85 11.06,16.56L16.56,11.06C17.15,10.47 17.15,9.5 16.56,8.94Z" /></svg>';
    }
    
    // Override typeName to set the custom type name
    static get typeName() {
        return 'FilledRectangularBox';
    }
    // Override title to set the custom title
    static get title() {
        return 'Filled Rectangular Box Marker';
    }
    // Override icon to set the custom icon
    static get icon() {
        // Return the URL or path to your custom icon image
        return '<svg viewBox="0 0 24 24"><path d="M4,6V19H20V6H4M18"/></svg>';
    }    

    get toolboxPanels()  {
        return [this.strokePanel, this.fillPanel, this.strokeWidthPanel, this.strokeStylePanel, this.opacityPanel];
    }

}

export default FilledRectangularBoxMarker;