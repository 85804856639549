import { ref, watch } from 'vue'
import axios from './Axios';
import { addProcess } from './Processor';

export default class TenantApi {
    constructor() {
        this.isDirty = ref(false);
        this.isLoading = ref(false);
        this.isSaving = false;
    }
    // eslint-disable-next-line
    async beforeSave(data) {        
    }
    // eslint-disable-next-line
    async afterLoad(data) {        
    }
    async get(id) {
        this.isLoading.value = true;
        let result = await axios.get('/api/tenants/' + id);
        await this.afterLoad(result.data);
        let dataRef = this.attachWatchers(result.data);
        return dataRef;
    }
    async getAll() {
        this.isLoading.value = true;
        let result = await axios.get('/api/tenants/' );
        await this.afterLoad(result.data);    
        this.isLoading.value = false;
        let dataRefs = this.attachWatchers(result.data);
        return dataRefs;
    }
    async createNew(defaultValues) {
        let data = {
        };
        // ASK SERVER FOR DEFAULT
        // STUFF defaultValues can override
        if (defaultValues)
            Object.assign(data, defaultValues);
        // STUFF defaultValues cant override
        return this.attachWatchers(data);
    }
    async save(data) {
        let process = addProcess("Save Tenant: " + data.name);
        await this.beforeSave(data);
        let result = await axios.post('/api/tenants/', data).finally(() => process());
        return result.data;
    }
    async delete(data) {
        let key = data;
        if (typeof (data) === "object")
            key = data._id;
        let process = addProcess("Delete Tenant: " + data.name);
        let result = await axios.delete('/api/tenants/' + key).finally(() => process());
        return result.data;
    }
    attachWatchers(data) {
        if (Array.isArray(data))
            return data.map(i => this.attachWatchers(i));
        let dataRef = ref(data);
        this.isLoading.value = false;
        watch(dataRef.value, async () => {
            if (this.isSaving)
                return;
            this.isDirty.value = true;
            this.isSaving = true;
            let newData = await this.save(dataRef.value);
            Object.assign(dataRef.value, newData);
            setTimeout(() => {
                this.isSaving = false;
            }, 1)
            this.isDirty.value = false;   
        });
        return dataRef.value;
    }
}
