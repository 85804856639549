<template>
    <div v-if="editableData" class="status-indicator" :class="statusClass()" title="Status ändern" @click="toggleState">
        <span class="status-label">{{statusName()}}</span>   
        <div class="status-box-outer">
            <div class="status-box-inner"></div>
        </div>
    </div>
</template>

<script lang="js">
    
    import { defineComponent} from 'vue';

    export default defineComponent({
        props: ['status','lookUp','allowEdit'],
        data() {
            return {
                dataSource: null,
                editableData: this.status
            }
        },
        async created() {
            this.dataSource = (await this.lookUp).dataSource;            
            if (!this.editableData)
                this.editableData = Object.keys(this.dataSource)[0]
        },
        methods: {
            statusName() {
                if (this.dataSource)
                    return this.dataSource[this.editableData];
                return null;
            },
            statusClass() {
                return "status-" + this.editableData.toLowerCase() + (this.allowEdit ? " editable" : "");
            },
            toggleState() {
                if (!this.allowEdit)
                    return;

                let keys = Object.keys(this.dataSource);
                let currentItemIndex = keys.indexOf(this.editableData);

                if (currentItemIndex == keys.length - 1)
                    currentItemIndex = 0;
                else
                    currentItemIndex++;

                this.editableData = keys[currentItemIndex];
                if (this.editableData != this.status) {
                    this.$emit('update:status', this.editableData);
                }                
            }         
        }      
    });


</script>

<style>
    .status-indicator {
        display: grid;
        grid-template-columns: max-content 1fr;
        place-items: center;
        cursor: default;
        user-select:none;
        font-size: 1.5rem;
    }    

    .status-indicator.editable {
        cursor: pointer;
    }

    .status-indicator.editable:hover{
        filter: brightness(1.5);
    }
        
    .status-indicator.status-idle {
        color: var(--color-accent);
    }
        .status-indicator.status-idle .status-box-outer .status-box-inner {
            background-color: var(--color-accent);
            border-color: var(--color-accent);
        }

        .status-indicator.status-active .status-box-outer .status-box-inner {
            background-color: var(--color-success);
            border-color: var(--color-success);
        }
        
        .status-indicator.status-inactive .status-box-outer .status-box-inner {
            background-color: var(--color-accent-contrast);
            border-color: var(--color-accent-contrast);
        }


        .status-indicator.status-active {
            color: var(--color-success);
        }

        .status-indicator.status-inactive {
            color: var(--color-accent-contrast);
        }
        .status-indicator .status-label {
            display: inline-block;
        }

    .status-indicator .status-box-outer {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        width: 24px;
        height: 24px;
        border: 2px solid white;
        margin-left: 10px;
        margin-right: 10px;
    }

        .status-indicator .status-box-outer .status-box-inner {
            display: inline-block;
            border-radius: 2px;
            width: 12px;
            height: 12px;
            border: none;       
            
        }
</style>
