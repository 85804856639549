import Dexie, { liveQuery } from 'dexie';
import { useObservable } from "@vueuse/rxjs";

export const mobileDb = new Dexie('mobile_db');
mobileDb.version(10).stores({
    contacts: '_id,_changed',
    users: '_id,_changed,subjectId',
    organizations: '_id,_changed',
    projects: '_id,_changed',
    protocols: '_id,_changed,projectId,version',
    protocols_archive: '[_id+version],_changed,projectId',
    lists: '_id,_changed,internalName,parentListItemId',
    listItems: '_id,_changed,listId',
    attachments: '_id,_changed,assignmentType,assignment,type,name',
    attachmentBlobs: '_id,_changed',
    deletedAttachments: '_id,collection',
    deletedDeficiencies: 'id,projectId',
    projectMeta: 'id'
});

window.Api.mobileDb = mobileDb;

export const wrapObserve = (query) => {
    return useObservable(liveQuery(query))        
}