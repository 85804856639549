<template>
    <div ref="hazardsList" class="hazards-list">
        <div v-for="(hazard,index) in hazards" :key="hazard.id" class="hazard-item" :class="{'hazard-item-disabled':enabledExpression?!enabledExpression(hazard):false}">
            <DxForm v-if="hazard" :form-data="hazard" :show-validation-summary="true" label-mode="outside" label-location="top" :col-count="3">
                <DxSimpleItem data-field="name" :col-span="3" editor-type="dxTextBox" :label="{text:'Name'}" :editor-options="{readOnly:readOnlyFields&&readOnlyFields.includes('name')}"></DxSimpleItem>
                <DxSimpleItem data-field="measures" :col-span="3" editor-type="dxTagBox" :label="{text:'Schutzeinrichtung bzw. Schutzmaßnahmen'}" :editor-options="{ displayExpr: 'name', valueExpr: valueExpr, showSelectionControls: showSelectionControls, acceptCustomValue: acceptCustom, onCustomItemCreating: addMeasure, onPaste: paste, dataSource: (getAllowedOptions&&getAllowedOptions(hazard)) || hazard.allowedOptions || [], onValueChanged: onMeasuresChanged || null }" />
                <DxSimpleItem data-field="responsibilityCreateId" :label="{text:'Erstellen'}" :editor-options="{readOnly:readOnlyFields&&readOnlyFields.includes('responsibilityCreateId')}">
                    <template #default>
                        <ListLookup list="project_trades" v-model:value="hazard.responsibilityCreateId" @manual-change="setEqual(hazard,$event)" @value-changed="v=>fixScroll($refs.hazardsList.scrollTop)" :allowEmpty="true"></ListLookup>
                    </template>
                </DxSimpleItem>
                <DxSimpleItem data-field="responsibilityControlId" :label="{text:'Kontrolle'}"  :editor-options="{readOnly:readOnlyFields&&readOnlyFields.includes('responsibilityControlId')}">
                    <template #default>
                        <ListLookup list="project_trades" v-model:value="hazard.responsibilityControlId" @value-changed="v=>fixScroll($refs.hazardsList.scrollTop)" :allowEmpty="true"></ListLookup>
                    </template>
                </DxSimpleItem>
                <DxSimpleItem data-field="responsibilityMaintenanceId" :label="{text:'Instandhaltung'}"   :editor-options="{readOnly:readOnlyFields&&readOnlyFields.includes('responsibilityMaintenanceId')}">
                    <template #default>
                        <ListLookup list="project_trades" v-model:value="hazard.responsibilityMaintenanceId" @value-changed="v=>fixScroll($refs.hazardsList.scrollTop)" :allowEmpty="true"></ListLookup>
                    </template>
                </DxSimpleItem>
                <DxSimpleItem data-field="safetyRegulation" :col-span="2" editor-type="dxTextArea" :editor-options="{maxHeight:200,autoResizeEnabled:true,minHeight:100,maxLength:2048,readOnly:readOnlyFields&&readOnlyFields.includes('safetyRegulation')}" :label="{text:'Arbeitsschutzvorschriften'}">
                    <DxRequiredRule></DxRequiredRule>
                </DxSimpleItem>
                <DxSimpleItem data-field="note" :col-span="1" editor-type="dxTextArea" :editor-options="{maxHeight:200,autoResizeEnabled:true,minHeight:100,maxLength:2048,readOnly:readOnlyFields&&readOnlyFields.includes('note')}" :label="{text:'Hinweis'}"></DxSimpleItem>
            </DxForm>
            <div class="hazard-buttons">
                <div v-if="!enabledExpression" class="hazard-button material-symbols-outlined" @click="deleteItem(hazard)" title="Löschen">delete</div>
                <div v-if="enabledExpression && enabledExpression(hazard)" class="hazard-button material-symbols-outlined" @click="removeItem(hazard)" title="Löschen">delete</div>
                <div v-if="enabledExpression && !enabledExpression(hazard)" class="hazard-button material-symbols-outlined" @click="addItem(hazard)" title="Wiederherstellen">restore</div>

                <div :class="{'hazard-button-inactive':index==0}" class="hazard-button material-symbols-outlined" @click="moveToTop(hazard)" title="An den Anfang verschieben">keyboard_double_arrow_up</div>
                <div :class="{'hazard-button-inactive':index==0}" class="hazard-button material-symbols-outlined" @click="moveUp(hazard)" title="Nach oben verschieben">keyboard_arrow_up</div>
                <div :class="{'hazard-button-inactive':index==hazards.length-1}" class="hazard-button material-symbols-outlined" @click="moveDown(hazard)" title="Nach unten verschieben">keyboard_arrow_down</div>
                <div :class="{'hazard-button-inactive':index==hazards.length-1}" class="hazard-button material-symbols-outlined" @click="moveToBottom(hazard)" title="An das Ende verschieben">keyboard_double_arrow_down</div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import { confirm } from 'devextreme/ui/dialog'
    import {
        DxForm,
        DxSimpleItem,   
        DxRequiredRule
    } from 'devextreme-vue/form';
    import {guid} from '../services/Util'
    import ListLookup from '../components/list-lookup.vue';
import { afterProcessing } from '../services/Processor';
    export default defineComponent({
        components: {
            ListLookup,
            DxForm,
            DxSimpleItem,
            DxRequiredRule
        },
        props: {
            'items': Array,
            'onRemove': Function,
            'getAllowedOptions': Function,
            'acceptCustom': Boolean,
            'valueExpr': String,
            'showSelectionControls': Boolean,
            'onMeasureRemoved': Function,
            'onMeasureAdded': Function,
            'onHazardRemoved': Function,
            'onHazardAdded': Function,
            'enabledExpression': Function,
            'readOnlyFields': Array
        },
        data() {
            return {
                hazards: [],
                anyOpened: false
            };
        },
        watch: {
            'items'() {
                this.hazards = this.items;
            }
        },
        created() {
            this.hazards = this.items;
        },
        methods:{
            addMeasure(e){
                if (!e.text) {
                    return false;
                }
                e.customItem = { id: guid(), name: e.text };
                return e.customItem;                
            },
            onMeasuresChanged(e) {
                if (!e.value || !e.previousValue)
                    return;
                let added = e.value.filter(t => !e.previousValue.includes(t))
                let removed = e.previousValue.filter(t => !e.value.includes(t));
                if (typeof (this.onMeasureAdded) === "function")
                    added.forEach(i => this.onMeasureAdded(i));
                if (typeof (this.onMeasureRemoved) === "function")
                    removed.forEach(i => this.onMeasureRemoved(i));
            },
            paste(e) {
                if(!this.acceptCustom)
                    return;
                let text = e.event?.originalEvent?.clipboardData?.getData("text");
                if (text && text.includes("\n")) {
                    let items = text.split("\n").filter(t=> t);
                    e.event.preventDefault();
                    let val = e.component.option("value").concat(items.map(i => ({id:guid(),name:i})));
                    e.component.option("value", val);
                }
            },
            setEqual(hazard,id) {                
                hazard.responsibilityControlId = id;
                hazard.responsibilityMaintenanceId = id;                
            },
            async addItem(hazard) {
                if (typeof (this.enabledExpression) === "function") {
                    if (typeof (this.onHazardAdded) === "function") {
                        this.onHazardAdded(hazard);
                    }
                }
            },
            async removeItem(hazard) {
                if (typeof (this.enabledExpression) === "function") {
                    if (typeof (this.onHazardRemoved) === "function") {
                        this.onHazardRemoved(hazard);
                    }
                }
            },
            async deleteItem(hazard) {
                if (await confirm(`Soll '${hazard.name}' wirklich gelöscht werden?`, 'Element Löschen?')) {
                    if (typeof (this.onRemove) === 'function')
                        this.onRemove(hazard)
                    let i = this.hazards.findIndex(h => h.id === hazard.id);
                    this.hazards.splice(i, 1);
                }
            },
            moveToTop(hazard) {
                let i = this.hazards.findIndex(h => h.id === hazard.id)
                let item = this.hazards.splice(i, 1);
                this.hazards.splice(0, 0, item[0]);
            },
            moveUp(hazard) {
                let i = this.hazards.findIndex(h => h.id === hazard.id)
                let item = this.hazards.splice(i, 1);
                this.hazards.splice(i - 1, 0, item[0]);
            },
            moveDown(hazard) {
                let i = this.hazards.findIndex(h => h.id === hazard.id);
                let item = this.hazards.splice(i, 1);
                this.hazards.splice(i + 1, 0, item[0]);
            },
            moveToBottom(hazard) {
                let i = this.hazards.findIndex(h => h.id === hazard.id)
                let item = this.hazards.splice(i, 1);
                this.hazards.push(item[0]);
            },
            fixScroll(top) {
                if (top > 0) {
                    this.$nextTick(() => {
                        if(this.$refs.hazardsList)
                            this.$refs.hazardsList.scrollTop = top;
                    })
                    afterProcessing(() => {
                        this.$nextTick(() => {
                            if (this.$refs.hazardsList)
                                this.$refs.hazardsList.scrollTop = top;
                        })
                    })
                }
            }
        }
    });
</script>

<style>
    .hazard-item {
        border-bottom: 3px solid var(--color-background-lighter);
        padding: 20px 0;
        display: grid;
        grid-template-columns: minmax(0,1fr) max-content;
        grid-gap: 5px;
    }
    .hazard-buttons {
        display: grid;
        grid-auto-flow: row;
        grid-auto-rows: max-content;
        grid-gap: 5px;
    }
    .hazard-button {
        padding: 2px;
        margin: 3px;
        border-radius: var(--default-border-radius);
        background: var(--color-background);
        color: var(--color-text);
    }
        .hazard-button:not(.hazard-button-inactive):hover {
            background: var(--color-background-lighter);
        }
    .hazard-button-inactive{
        opacity: 0.1;
    }
    .hazard-item:last-child {
        border-bottom: none;
    }

    .hazard-item-disabled .dx-form {
        opacity: 0.2;
        pointer-events: none;
        user-select: none;
    }

</style>
