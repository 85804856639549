<template>
    <div v-if="formData" class="area-content-body area-content-body-info">
        <div class="project-head" :class="{dirty:$root.isDirty}">
            <header-field-editor class="project-number-edit" :inputHint="'Projekt-Nr.'" :allowEdit="true" v-model:dataSource="formData.number" />
            <div class="v-line"></div>
            <header-field-editor class="project-name-edit" :inputHint="'Projekt-Name'" :allowEdit="true" v-model:dataSource="formData.name" />
            <status-indicator class="project-status-indicator" v-model:status="formData.status" :allowEdit="true" :lookUp="getLookup('status')" />            
            <project-involved-button/>
            <attachments-button />
        </div>
        <DxForm :form-data="formData" :repaintChangesOnly="true" @initialized="formInitialized" @contentReady="formContentReady" :show-validation-summary="true" label-mode="outside" label-location="top" :scrollingEnabled="true">
            <DxGroupItem :col-count="2">
                <DxGroupItem :col-count="1">
                    <DxGroupItem :col-count="2">
                        <DxSimpleItem :label="{text:'Auftraggeber'}">
                            <template #default>
                                <contact-organization-lookup v-model:contactValue="formData.clientId" v-model:organizationValue="formData.clientOrganizationId" :show-organization="true" :view="$root.fieldOptions?.project?.clientId?.readOnly"></contact-organization-lookup>
                            </template>
                        </DxSimpleItem>
                        <DxSimpleItem data-field="kindId" :label="{text:'Art des Bauvorhabens'}">
                            <template #default>
                                <ListLookup list="project_building_kind" v-model:value="formData.kindId" :view="$root.fieldOptions?.project?.kindId?.readOnly"></ListLookup>
                            </template>
                        </DxSimpleItem>
                        <DxSimpleItem :label="{text:'Bauherrschaft'}">
                            <template #default>
                                <contact-organization-lookup v-model:contactValue="formData.buildingOwnerId" v-model:organizationValue="formData.buildingOwnerOrganizationId" :show-organization="true" :view="$root.fieldOptions?.project?.buildingOwnerId?.readOnly"></contact-organization-lookup>
                            </template>
                        </DxSimpleItem>
                        <DxSimpleItem data-field="projectTypeTemplateId" :label="{text:'Projekttyp'}">
                            <template #default>
                                <ListLookup list="project_type_template" v-model:value="formData.projectTypeTemplateId"></ListLookup>
                            </template>
                        </DxSimpleItem>
                    </DxGroupItem>
                    <DxGroupItem :col-count="2" caption="Ort der Baustelle">
                        <DxSimpleItem data-field="streetAndNr" :col-span="2" :label="{text:'Straße / Nr',location:'left'}" />
                        <DxSimpleItem data-field="zipCode" :label="{text:'PLZ',location:'left',template:'zipCodeLabel'}" />
                        <DxSimpleItem data-field="city" :label="{text:'Ort',location:'left'}" />
                    </DxGroupItem>
                    <DxGroupItem :col-count="2">
                        <DxSimpleItem data-field="shortDescription" :col-span="2" editor-type="dxTextArea" :editor-options="{maxHeight:200,autoResizeEnabled:true,minHeight:100,maxLength:16000}" :label="{text:'Kurze Objektbeschreibung'}">
                        </DxSimpleItem>
                        <DxSimpleItem data-field="longDescription" :col-span="2" editor-type="dxTextArea" :editor-options="{maxHeight:400,autoResizeEnabled:true,minHeight:186,maxLength:16000}" :label="{text:'Lange Objektbeschreibung'}">
                        </DxSimpleItem>
                        <DxSimpleItem data-field="stages" :col-span="2" editor-type="dxTagBox" :editor-options="{placeholder:'Projektphasen auswählen',
                                                                                                                 searchEnabled: true,
                                                                                                                 items:stages,
                                                                                                                 displayExpr: 'name', 
                                                                                                                 valueExpr: 'id', readOnly: $root.fieldOptions?.project?.stages }" 
                                                                                                :label="{text:'Projektphasen'}"/>
                    </DxGroupItem>
                </DxGroupItem>
                <DxGroupItem :col-count="2">
                    <DxSimpleItem data-field="start" editor-type="dxDateBox" :label="{text:'Beginn des Projekts'}" :editor-options="{dateSerializationFormat:'yyyy-MM-dd'}" />
                    <DxSimpleItem data-field="end" editor-type="dxDateBox" :label="{text:'Ende des Projekts'}" :editor-options="{dateSerializationFormat:'yyyy-MM-dd'}" />

                    <DxSimpleItem data-field="projectManagerId" :label="{text:'Projektleiter'}">
                        <template #default>
                            <user-lookup v-model:value="formData.projectManagerId" :filter="(u)=>u.pl"></user-lookup>
                        </template>
                    </DxSimpleItem>

                    <DxSimpleItem data-field="coordinatorId" :label="{text:'Koordinator'}">
                        <template #default>
                            <user-lookup v-model:value="formData.coordinatorId"></user-lookup>
                        </template>
                    </DxSimpleItem>

                    <DxEmptyItem></DxEmptyItem>
                    <DxSimpleItem data-field="substituteCoordinatorId" :label="{text:'Vertreter'}">
                        <template #default>
                            <user-lookup v-model:value="formData.substituteCoordinatorId"></user-lookup>
                        </template>
                    </DxSimpleItem>
                    <DxSimpleItem :col-span="2">
                        <template #default>
                            <image-viewer :attachment="{assignment:formData._id,assignmentType:'project',name:'ProjektUebersicht.png'}" allowed-types="image/*" :allow-upload="true" :allow-drop="true" :allow-edit="true" :allow-clear="true" :allow-full-screen="true" height="400px"></image-viewer>
                        </template>
                    </DxSimpleItem>

                    <DxSimpleItem :col-span="2" data-field="internalDescription" editor-type="dxTextArea" :editor-options="{maxHeight:200,autoResizeEnabled:true}" :label="{text:'Baustellen-Info intern'}" />
                </DxGroupItem>
            </DxGroupItem>
            <template #clientLabel=" { data }">
                <FieldLabelTemplate :data="data" icon="" id="client_label" />
            </template>

            <template #zipCodeLabel=" { data }">
                <FieldLabelTemplate :data="data" icon="" id="zip_code_label" />
            </template>
        </DxForm>
    </div>
    <div v-else class="area-content-body">
        Daten werden geladen...
    </div>
</template>

<script lang="js">
    import { confirm } from "devextreme/ui/dialog"
    import notify from "devextreme/ui/notify"
    import hideToasts from 'devextreme/ui/toast/hide_toasts';
    import {
        DxForm,
        DxSimpleItem,
        DxEmptyItem,
        DxGroupItem,
        DxLabel   
    } from 'devextreme-vue/form';
    import  DxDateBox  from 'devextreme-vue/date-box';
    import  DxSelectBox  from 'devextreme-vue/select-box';
    import  DxTextArea  from 'devextreme-vue/text-area';
    import { DxTextBox }  from 'devextreme-vue/text-box';
    import DxTagBox from "devextreme-vue/tag-box";
    import ImageViewer from "../components/image-viewer.vue";
    import HeaderFieldEditor from "../components/header-field-editor.vue";
    import StatusIndicator from "../components/status-indicator.vue";
    import FieldLabelTemplate from "../components/field-label-template.vue";
    import ListLookup from "../components/list-lookup.vue";
    import UserLookup from "../components/user-lookup.vue";
    import ContactOrganizationLookup from "../components/contact-organization-lookup.vue";
    import AttachmentsButton from "../components/project-attachments-button.vue";
    import ProjectInvolvedButton from "../components/project-involved-button.vue";

    import projectApi from "../services/ProjectApi";
    import listApi from "../services/ListApi";
    import { addProcess } from "../services/Processor";
import { diffrenceObjects } from "../services/Util";
    
    let form = null;
    let initializing = false;
    
    export default {
        components: {
            DxForm,
            DxSimpleItem,
            DxEmptyItem,
            DxGroupItem,
            ImageViewer,
            HeaderFieldEditor,
            StatusIndicator,
            FieldLabelTemplate,
            ListLookup,
            UserLookup,
            ContactOrganizationLookup,
            AttachmentsButton,
            ProjectInvolvedButton,

            // ESLINT cannot handle DX-Component attribute usage - remove the ignore, if any of these get used normally in the future.
            // eslint-disable-next-line
            DxSelectBox,
            // eslint-disable-next-line
            DxTextBox,
            // eslint-disable-next-line
            DxLabel,
            // eslint-disable-next-line
            DxDateBox,
            // eslint-disable-next-line
            DxTextArea,
            // eslint-disable-next-line
            DxTagBox
        },
        data() {
            return {
                formData: null,
                popupVisible: false,
                process: null,
                testList: null,
                stages: null,
                groupsVisible: false
            }            
        },
        watch: {
            '$root.project._id': {
                handler() {
                    if(this.$root.project?._id)
                        this.projectLoaded();
                }
            },
            '$root.project': {
                handler() {
                    let pClone = structuredClone(this.$root.project);
                    if (pClone?._id && this.formData) {
                        let diff = diffrenceObjects(pClone, this.formData)
                        Object.assign(this.formData, diff);
                    }                    
                },
                deep:true
            },
            'formData._id'() {
                if (this.$route.params.id === "new" && this.formData._id && this.formData._id != "new")  {
                    this.$router.push("/Projects/" + this.formData._id);
                }
            },
            'formData': {
                async handler() {
                    if (initializing) {
                        console.log("[ProjectInfo] skipping form handler while initializing...");
                        return;
                    }
                                                       
                    if (this.formData && form && form.isReady() && form.option("formData")) {
                        if (this.process)
                            this.process();
                        this.process = addProcess("Save Project", true);
                        if (form.validate().isValid) {
                            if (!this.formData.name) {
                                notify("Name muss ausgefüllt sein.", "warning");
                                return;
                            }
                            if (!this.formData.number) {
                                notify("Nummer muss ausgefüllt sein.", "warning");
                                return;
                            }
                            let collision = await projectApi.getByNumber(this.formData.number);
                            if (collision && collision._id != this.$root.project._id) {
                                notify("Projekt mit gleicher Nummer existiert bereits.", "warning");
                                return;
                            }
                            hideToasts();
                            let clone = structuredClone(this.formData)
                            let pClone = structuredClone(this.$root.project);
                            let diff = Object.keys(diffrenceObjects(clone, pClone));
                            if (diff.length > 5) {
                                Object.assign(this.$root.project, clone);
                            } else if(diff.length > 0){
                                let e = {}
                                diff.forEach(k => e[k] = clone[k]);
                                Object.assign(this.$root.project, e);
                            }
                            if(this.process)
                                this.process();
                        }
                    }                    
               
                },
                deep:true
            }
        },
        async beforeRouteLeave() {
            let diff = diffrenceObjects(structuredClone(this.$root.project), this.formData)
            if (Object.keys(diff).length > 0 || this.$root.isUploading) {
                if (this.$root.isUploading) {
                    notify("Wird noch gespeichert!", "warning");

                    await new Promise(res => {
                        let i = setInterval(() => {
                            if (!this.$root.isUploading) {
                                clearInterval(i);
                                hideToasts();
                                notify("Gespeichert", "success", 500);
                                res();
                            }
                        }, 10)
                    });
                    if (this.process())
                        this.process();
                    return true;
                }
                if (await confirm("Es gibt ungespeicherte Änderungen - sollen diese verworfen werden?", "Änderungen verwerfen?")) {
                    if(this.process)
                        this.process();
                    return true;
                }
                return false;
            }
            return true;
        },
        async mounted() {
            form = null;
            this.stages = await this.$root.getLookup('stages');
            if (this.$route.params.id === "new") {
                this.$root.project = await projectApi.createNew();
                this.formData = {};
            }
            else if (this.$root.project && this.$route.params.id == this.$root.project._id) {
                this.projectLoaded();
            } else {
                this.$root.project = null;
                this.formData = null;
                this.$root.loadProject(this.$route.params.id);
            }
        },
        methods: {
            async projectLoaded() {
                initializing = true;
                if(this.$root.project){
                    var clone = structuredClone(this.$root.project);
                    if (this.formData == null) {
                        this.formData = clone
                    }                        
                    else {
                        let diff = Object.keys(diffrenceObjects(clone, this.formData))
                        if (diff.length > 0) {
                            let e = {}
                            diff.forEach(k => e[k] = clone[k]);
                            Object.assign(this.formData, e);
                        }
                    }
                        
                }
                setTimeout(() => {
                    initializing = false;
                }, 1)                                
            },
            async getLookup(type) {
                return { dataSource: await this.$root.getMeta(type) };
            },
            async getListItems(i) {
                let list = await listApi.getByName(i);
                if (!list)
                    return {}
                let items = await listApi.getListItems(list._id);
                return items;
            },
            formInitialized(e) {
                form = e.component;                
            },
            formContentReady(e) {
                e.component._scrollable.option("useNative", "true");  
            }
        }
    }
</script>

<style>
    .area-content-body-info {
        display: grid;
        grid-template-rows: max-content 1fr;
    }

    .project-head {
        border-bottom: 2px solid var(--color-background-light);
        margin-bottom: 10px;
        text-align: left;
        padding: 15px 0;
        position: relative;
        font-size: 2rem;
        display: grid;
        grid-template-columns: max-content max-content 1fr;
        grid-auto-flow: column;
        grid-auto-columns: max-content;
    }

        .project-head .project-number-edit .header-field-editor {
            font-size: 1.5rem;
            min-width: 100px;
        }

        .project-head .project-name-edit .header-field-editor {
            font-size: 1.5rem;
            min-width: 100px;
        }

        .project-head::after {
            transition: all 0.3s ease-in-out;
            content: '';
            position: absolute;
            opacity: 0;
            height: 10px;
            bottom: 0;
            left: 0;
            background: linear-gradient(45deg, #ffff0022 25%, #333333 25%, #333333 50%, #ffff0022 50%, #ffff0022 75%, #333333 75%, #333333 100%);
            background-size: 56.57px 56.57px;
            right: 0;
        }

        .project-head.dirty::after {
            opacity: 1;
        }

        #zip_code_label{
            width: 98px;
        }

    .area-content-body-info .dx-form > .dx-scrollable-wrapper > .dx-scrollable-container {
        padding-right: 10px;
    }
    .v-line{
        border: 1px solid var(--color-text);
        width: 0px;
        margin: 5px;
    }
</style>
