<template>
    <div class="area-content-body admin-system-templates">
        <div v-for="template in templates" v-bind:key="template.fileName" class="admin-system-template">
            <div class="admin-system-template-title">{{template.title}}</div>
            <image-viewer :attachment="{assignment:'template',assignmentType:'system',name:template.fileName}"
                          :allowed-types="template.docMimeType"
                          :allow-upload="true"
                          uploaded-icon="mark_email_read"
                          :allow-download="true"
                          :allow-drop="true"
                          height="400px"></image-viewer>
        </div>       
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import ImageViewer from '../components/image-viewer.vue';
    
    export default defineComponent({
        components: {
            ImageViewer
        },
        data() {
            return {
                templates: [
                    {
                        docMimeType: "",
                        fileName: "Vorankündigung_Bauherrschaft.msg",
                        title: "Vorankündigung Bauherrschaft"
                    },
                    {
                        docMimeType: "",
                        fileName: "Vorankündigung_Regierungspräsidium.msg",
                        title: "Vorankündigung Regierungspräs."
                    },
                    {
                        docMimeType: "",
                        fileName: "Protokoll.msg",
                        title: "Protokoll"
                    },
                ]
            };
        },
        async created() {
        },
        methods: {
        },
    });
</script>

<style>
    .admin-system-templates{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        grid-template-rows: max-content;
        overflow: auto;
    }
        .admin-system-templates .image-viewer {
            background-color: transparent;
        }
    .admin-system-template{
        background-color: var(--color-accent);
        padding: 10px;
        margin: 10px;
        border-radius: var(--default-border-radius);
    }

    .admin-system-template-title {
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 10px;
    }
</style>
