<template>
    <div class="area-content-body admin-templates">
        <div class="admin-templates-list-contain admin-templates-list-project-types">
            <div class="admin-templates-list-head">
                <div class="admin-templates-list-title">SiGe-Plan Projekttypen</div>
                <div class="admin-templates-list-icon material-symbols-outlined">maps_home_work</div>
            </div>
            <div class="admin-templates-list">
                <div v-for="projectTypeTemplate in projectTypeTemplates" :key="projectTypeTemplate._id">
                    <div class="admin-templates-list-item" @click="$router.push('/Admin/ProjectTypeTemplates/'+projectTypeTemplate._id)" title="Vorlage öffnen">{{projectTypeTemplate.name}}</div>
                        <div class="admin-templates-with-delete" v-for="projectType in projectTypes.filter(pt=>pt.projectTypeTemplateId == projectTypeTemplate._id)" :key="projectType._id">
                            <div class="admin-templates-list-item sub-item" @click="$router.push('/Admin/ProjectTypes/'+projectType._id)" title="Projekttyp öffnen">{{projectType.name}}</div>
                            <div class="head-toolbar-button-compact" @click="deleteProjectType(projectType)" title="Projekttyp Löschen">delete</div>
                        </div>
                    <div class="admin-templates-list-item sub-item-inline" @click="$router.push('/Admin/ProjectTypes/new:' + projectTypeTemplate._id)" title="Neuer Projekttyp">+</div>
                </div>
            </div>
        </div>
        <div class="admin-templates-list-contain admin-templates-list-later-work">
            <div class="admin-templates-list-head">
                <div class="admin-templates-list-title">Spätere Arbeiten</div>
                <div class="admin-templates-list-icon material-symbols-outlined">article</div>
            </div>
            <div class="admin-templates-list">
                <div v-for="laterWorkTemplate in laterWorkTemplates" :key="laterWorkTemplate._id">
                    <div class="admin-templates-list-item" @click="$router.push('/Admin/LaterWorkTemplates/'+laterWorkTemplate._id)" title="Vorlage öffnen">{{laterWorkTemplate.name}}</div>
                    <div class="admin-templates-with-delete" v-for="laterWorkType in laterWorkTypes.filter(pt=>pt.laterWorkTemplateId == laterWorkTemplate._id)" :key="laterWorkType._id">
                        <div class="admin-templates-list-item sub-item" @click="$router.push('/Admin/LaterWorkTypes/'+laterWorkType._id)" title="Projekttyp öffnen">{{laterWorkType.name}}</div>
                        <div class="head-toolbar-button-compact" @click="deleteLaterWorkType(laterWorkType)" title="Projekttyp löschen">delete</div>
                    </div>
                    <div class="admin-templates-list-item sub-item-inline" @click="$router.push('/Admin/LaterWorkTypes/new:' + laterWorkTemplate._id)" title="Neuer Projekttyp">+</div>
                </div>
            </div>
        </div>
        <div class="admin-templates-list-contain admin-templates-list-email">
            <div class="admin-templates-list-head" @click="$router.push('/admin/templates/files')" title="Dateivorlagen öffnen">
                <div class="admin-templates-list-title">Dokumente</div>
                <div class="admin-templates-list-icon material-symbols-outlined">snippet_folder</div>
            </div>
            <div></div>
            <div class="admin-templates-list-head" @click="$router.push('/admin/templates/mails')" title="Mailvorlagen öffnen">
                <div class="admin-templates-list-title">E-Mail</div>
                <div class="admin-templates-list-icon material-symbols-outlined">mail</div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import projectTypeTemplateApi from '../services/ProjectTypeTemplateApi';
    import projectTypeApi from '../services/ProjectTypeApi';
    import laterWorkTemplateApi from '../services/LaterWorkTemplateApi';
    import laterWorkTypeApi from '../services/LaterWorkTypeApi';

    import {confirm} from 'devextreme/ui/dialog'
    
    export default defineComponent({
        components: {
        },
        data() {
            return {
                projectTypeTemplates: [],
                projectTypes: [],
                laterWorkTypes: [],
                laterWorkTemplates:[]
            };
        },
        async created() {
            this.fetchData();
        },
        methods: {
            async fetchData() {
                [this.projectTypeTemplates, this.projectTypes,
                this.laterWorkTemplates,this.laterWorkTypes] = await Promise.all([projectTypeTemplateApi.getAll(), projectTypeApi.getAll(),
                    laterWorkTemplateApi.getAll(), laterWorkTypeApi.getAll()])
                if (this.projectTypeTemplates.length == 0) {
                    await projectTypeTemplateApi.save({ name: "Hauptvorlage" })
                    this.projectTypeTemplates = await projectTypeTemplateApi.getAll();
                }
                if (this.laterWorkTemplates.length == 0) {
                    await laterWorkTemplateApi.save({ name: "Hauptvorlage" })
                    this.laterWorkTemplates = await laterWorkTemplateApi.getAll();
                }
            },
            async deleteProjectType(projectType){
                if (await confirm("Soll der Projekttyp gelöscht werden?", "Löschen?")) {
                    await projectTypeApi.delete(projectType);
                    await this.fetchData()
                }
            },
            async deleteLaterWorkType(laterWorkType){
                if (await confirm("Soll der Projekttyp gelöscht werden?", "Löschen?")) {
                    await laterWorkTypeApi.delete(laterWorkType);
                    await this.fetchData()
                }
            },
        },
    });
</script>

<style>
    .admin-templates {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: minmax(0,1fr);
        grid-gap: 50px;
        padding: 25px;
    }
    .admin-templates-list-contain {
        display: grid;
        grid-gap: 10px;
        grid-template-rows: 2fr minmax(0,3fr);
    }
        .admin-templates-list-contain.admin-templates-list-email {
            grid-template-rows: 2fr 1fr 2fr;
        }

    .admin-templates-list-head {
        display: grid;
        background: var(--color-accent);
        padding: 5px;
        grid-template-rows: max-content 1fr;
        justify-items: center;
        box-shadow: 0 0 5px rgba(0,0,0,0.5);
    }
    .admin-templates-list-title {
        color: white;
        margin: 10px;
        font-size: 2rem;
    }
    .admin-templates-list-icon {
        font-size: 200px;
        margin-top: 10px;
        color: var(--color-background);
    }
    .admin-templates-list {
        overflow: auto;
        padding:10px;
    }

    .admin-templates-list-item {
        background: var(--color-background-light);
        box-shadow: 0 0 5px rgba(0,0,0,0.5);
        margin: 5px 0;
        padding: 5px 10px;
        border-radius: 3px;
    }
        .admin-templates-list-item:hover {
            box-shadow: 0 0 2px rgba(0,0,0,0.5);
            filter: brightness(1.2);
        }
        .admin-templates-list-item.sub-item {
            margin-left: 20px;
        }
        .admin-templates-list-item.sub-item-inline {
            margin: 0 20px;
            display:inline-block;
        }
    .admin-templates-with-delete {
        display: grid;
        grid-template-columns: 1fr max-content;
        align-items: center;
    }
</style>
