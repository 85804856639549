import { ref, watch } from 'vue'
import axios from './Axios';
import { addProcess } from './Processor';

class ProjectSiGePlanPlanningApi {
    constructor() {
        this.isDirty = ref(false);
        this.isLoading = ref(false);
        this.isSaving = false;
        if (!window.Api)
            window.Api = {};
        window.Api.ProjectSiGePlanPlanningApi = this;
    }
    // eslint-disable-next-line
    async beforeSave(data) {
    }
    // eslint-disable-next-line
    async afterLoad(data) {
    }

    async get(id) {
        this.isLoading.value = true;
        let result = await axios.get('/api/ProjectSiGePlanPlanning/' + id);
        await this.afterLoad(result.data);
        return this.attachWatchers(result.data);
    }
    async getAll() {
        this.isLoading.value = true;
        let result = await axios.get('/api/ProjectSiGePlanPlanning/');
        await this.afterLoad(result.data);
        this.isLoading.value = false;
        return this.attachWatchers(result.data);
    }
    async createNew(defaultValues) {
        let data = {
        };
        if (defaultValues)
            Object.assign(data, defaultValues);
        return this.attachWatchers(data);
    }
    async save(data) {
        let process = addProcess("Save SiGePlanPlanning: " + data.name);
        await this.beforeSave(data);
        let result = await axios.post('/api/ProjectSiGePlanPlanning/', data).finally(() => process());
        return result.data;
    }
    async delete(id) {
        let process = addProcess("Delete SiGePlanPlanning: " + id);
        await axios.delete('/api/ProjectSiGePlanPlanning/' + id).finally(() => process());
    }
    async generateDocument(id) {
        let process = addProcess("Generating ProjectSiGePlanDoc: " + id);
        let attachment = await axios.post(`/api/ProjectSiGePlanPlanning/${id}/GenerateDocument`).finally(() => process());
        return attachment.data;
    }
    async finishDocument(id) {
        let process = addProcess("Generating ProjectSiGePlanDoc PDF: " + id);
        let attachment = await axios.post(`/api/ProjectSiGePlanPlanning/${id}/FinishDocument`).finally(() => process());
        return attachment.data;
    }
    attachWatchers(data) {
        if (Array.isArray(data))
            return data.map(i => this.attachWatchers(i));
        let dataRef = ref(data);
        this.isLoading.value = false;
        watch(dataRef.value, async () => {
            if (this.isSaving)
                return;
            this.isDirty.value = true;
            this.isSaving = true;
            let newData = await this.save(dataRef.value);
            Object.assign(dataRef.value, newData);
            setTimeout(() => {
                this.isSaving = false;
            }, 1)
            this.isDirty.value = false;
        });
        return dataRef.value;
    }
}

export { ProjectSiGePlanPlanningApi }
let projectSiGePlanPlanningApi = new ProjectSiGePlanPlanningApi();
export default projectSiGePlanPlanningApi