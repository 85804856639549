<template>
    <div class="area-menu">
        <router-link v-for="item in items" v-bind:key="item.context" :to="'/'+item.context" class="area-menu-item" :class="{'router-link-active':$route.path.match(new RegExp('/'+item.context+'(/|$)','i'))}">
            <div class="area-menu-item-icon material-symbols-outlined">{{item.icon}}</div>
            <div class="area-menu-item-label">{{item.label}}</div>
        </router-link>
        <a v-if="!items || (items.length == 0)" class="area-menu-item">
            <div class="area-menu-item-icon material-symbols-outlined">pending</div>
            <div class="area-menu-item-label">Lädt</div>
        </a>
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';

    let items = [{
        icon: 'architecture',
        label: 'Projekte',
        context: 'Projects'
    },{
        icon: 'contact_page',
        label: 'Stammdaten',
        context: 'MainData/Organizations'
    }, {
        icon: 'engineering',
        label: 'Admin',
        context: 'Admin'
    }, {
        icon: 'map',
        label: 'Karte',
        context: 'Map'
    }]   

    export default defineComponent({
        data() {
            return {
                items:[],
                context: null
            };
        },
        created() {
            setTimeout(()=>{
                this.items = items;
                if(this.context === null && this.items.length > 0)
                    this.context = items[0];
            },800)
        },
        methods:{
            changeContext(context){
                this.context = context
            }
        }
    });
</script>

<style>
    .area-menu {
        background: var(--color-background-light);
        color: var(--color-text);
        margin: 6px;
        border-radius: 4px;
    }

    .area-menu-item {
        margin: 5px;
        border-radius: 3px;
        color: inherit;
        margin-bottom: 18px;
        text-decoration: none;
        display:block;
    }

    .area-menu-item-icon {
        transition: all 0.3s ease-in-out;
        padding: 5px;
        background: var(--color-background);
        font-size: 50px;
        border-radius: 2px;
    }
    .area-menu-item-icon:hover {
        background: var(--color-background-lighter);
    }
    .router-link-active .area-menu-item-icon {
        background: var(--color-accent);
    }

    .area-menu-item-label {
        margin: 3px 5px;
        text-align: left;
        font-size: 0.5rem;
    }
</style>
