import { addProcess } from './Processor';
import projectApi from '../services/ProjectApi';
import attachmentApi from '../services/AttachmentApi';
import projectProtocolApi from '../services/ProjectProtocolApi';
import organizationApi from '../services/OrganizationApi';
import { mobileDb } from './MobileDb';
import { guid } from './Util';
import listApi from './ListApi';
import userApi from './UserApi';
import { ref, watch } from 'vue';
import { router } from './router';


class OfflineService {
    constructor() {
        this.currentLoading = {};
        let isOfflineInit = !navigator.onLine || ("isOffline" in localStorage);
        this.isOffline = ref(isOfflineInit);

        watch(this.isOffline, () => {
            if (this.isOffline.value) {
                localStorage["isOffline"] = "true";
            } else {
                delete localStorage["isOffline"];
            }
        })
        window.Api.offlineService = this;
    }
    get checkIsOffline() {
        // NON MOBILE SHOULD NOT BE OFFLINE
        if (!router.currentRoute.value.fullPath.toLowerCase().startsWith("/mobile")) {
            //this.isOffline.value = false;
            console.error("SETTING OFFLINE TO FALSE!!!", router.currentRoute.value.fullPath)
            return false;
        }
        return this.isOffline.value == true;
    }
    async handleItem(col, type, item) {
        if (Array.isArray(item))
            return Promise.all(item.map(i => this.handleItem(col, type, i)));
        if (!item) {
            console.error("DID NOT HANDLE", type , item);
            return -1;
        }
        item = structuredClone(item);
        item._changed = '';           
        try {
            let mId = await col.put(item);
            return mId;
        } catch(ex) {
            if (item.blob) {
                item.isBase64 = true;
                item.blob = await fileToBase64(item.blob);
                let mId = await col.put(item);
                return mId;
            }
            throw ex;
        }        
    } 
    async uploadData(progressHandler) {
        let splits_protocol = 0.8;
        let splits_attachments = 0.1;
        let progress = 0.01
        // TODO: we should get a list of all changed here to set _changed to the original value if some error occures
        if (offlineService.checkIsOffline) {
            progressHandler(1);
            throw new Error("SHOULD NOT BE OFFLINE!!");
        }
        if (progressHandler)
            progressHandler(progress);
        for (let contact of await mobileDb.contacts.where("_changed").notEqual('').toArray()) {
            console.error("[Sync] There should be no changes in contacts", contact);
        }
        for (let organization of await mobileDb.organizations.where("_changed").notEqual('').toArray()) {
            console.error("[Sync] There should be no changes in organizations", organization);
        }
        for (let project of await mobileDb.projects.where("_changed").notEqual('').toArray()) {
            console.error("[Sync] There should be no changes in projects", project);
        }
        let deletedDeficiencies = await mobileDb.deletedDeficiencies.toArray();
        let changedProtocols = await mobileDb.protocols.where("_changed").notEqual('').toArray();
        let currentIndex = 0;
        for (let protocol of changedProtocols) {
            console.log("[Sync] Changed", protocol);
            if (!protocol._id.startsWith("m_")) {
                console.log("[Sync] Loaded from DB", protocol);
                let dbProtocol = await projectProtocolApi.get(protocol.projectId)
                protocol.currentVersion = dbProtocol.currentVersion;

                // Get every deficiency from online, that is not already in the local instance and was not deleted by the app-client
                let externalyAddedAndNotDeleted = dbProtocol.deficiencies.filter(dbD => !protocol.deficiencies.some(d => d.id == dbD.id) && !deletedDeficiencies.some(dd => dd.id == dbD.id));
                // Get the deficiencies, that were deleted
                let usedDeleted = deletedDeficiencies.filter(dd => dbProtocol.deficiencies.some(dbD => dbD.id == dd.id)) 
                for (let used of usedDeleted) {
                    // TODO: dont do this here - do it in the end!
                    await mobileDb.deletedDeficiencies.delete(used.id)
                }
                // Concat those with the local ones
                protocol.deficiencies = protocol.deficiencies.concat(externalyAddedAndNotDeleted);
            }
            let locationAttachments = await Promise.all(protocol.locations.map(l => mobileDb.attachments.get(l.id))).then(atts => atts.filter(a => a && a._changed))
            let attIndex = 0;
            for (let attachment of locationAttachments) { 
                if (!attachment) {
                    continue;
                }
                let attId = attachment._id;
                if (attachment._id.startsWith("m_"))
                    delete attachment._id;
                let dbAttachment = await attachmentApi.save(attachment);
                let blob = await offlineAttachmentApi.getBlobItem(attId);
                if (blob._changed) {
                    await attachmentApi.saveBlob(dbAttachment._id, blob.blob);
                }
                let location = structuredClone(protocol.locations.find(l => l.id == attId))
                if (location.id != dbAttachment._id) {
                    protocol.locations.splice(protocol.locations.findIndex(l => l.id == attId), 1);
                    location.id = dbAttachment._id;
                    protocol.locations.push(location);
                }                
                // TODO: Check if this is needed only when _id startsWith "m_"
                if (attId.startsWith("m_")) {
                    await mobileDb.attachmentBlobs.delete(attId);
                    await mobileDb.attachments.delete(attId);
                }
                console.log("[Sync] Protocol attachment handled", attId);
                attIndex++;
                if (progressHandler)
                    progressHandler(progress, `Protokolle werden hochgeladen\t-\t${currentIndex}/${changedProtocols.length}\t-\tAnhänge\t-\t${attIndex}/${locationAttachments.length}`);
            }
            protocol.locations = protocol.locations.filter(l=>!l.id.startsWith("m_"))
            let protocolId = protocol._id;
            if (protocolId.startsWith("m_"))
                delete protocol._id;
            await projectProtocolApi.save(protocol);
            await mobileDb.protocols.delete(protocolId);
            currentIndex++;
            progress = splits_protocol * (currentIndex / changedProtocols.length);
            if (progressHandler)
                progressHandler(progress, `Protokolle werden hochgeladen\t-\t${currentIndex}/${changedProtocols.length}`);
        }
        for (let list of await mobileDb.lists.where("_changed").notEqual('').toArray()) {
            console.error("[Sync] There should be no changes in lists", list);
        }
        for (let listItem of await mobileDb.listItems.where("_changed").notEqual('').toArray()) {
            console.error("[Sync] There should be no changes in lists", listItem);
        }
        let changedAttachments = await mobileDb.attachments.where("_changed").notEqual('').toArray();
        currentIndex = 0;
        for (let attachment of changedAttachments) {
            let blob = await offlineAttachmentApi.getBlob(attachment._id);
            if (!blob) {
                console.log("[Sync] Blob null - " + attachment._id)
                try {
                    currentIndex++;
                    await mobileDb.attachments.delete(attachment._id);
                    progress = splits_protocol + splits_attachments * (currentIndex / changedAttachments.length);
                    if (progressHandler)
                        progressHandler(progress, `Anhänge werden hochgeladen\t-\t${currentIndex}/${changedAttachments.length}`);
                } catch (ex) {
                    console.error("[Sync] Blob Err", ex);
                }
                continue;
            }
                
            let origId = attachment._id;
            if (attachment._id.startsWith("m_")) {
                delete attachment._id
            }
            let att = await attachmentApi.save(attachment);
            await attachmentApi.saveBlob(att._id, blob);
            if (origId.startsWith("m_")) {
                await mobileDb.attachmentBlobs.delete(origId);
                await mobileDb.attachments.delete(origId);
            } 
            console.log("[Sync] Attachment handled", att._id);
            currentIndex++;
            progress = splits_protocol + splits_attachments * (currentIndex / changedAttachments.length);
            if (progressHandler)
                progressHandler(progress, `Anhänge werden hochgeladen\t-\t${currentIndex}/${changedAttachments.length}`);
        }
        for (let attachmentBlob of await mobileDb.attachmentBlobs.where("_changed").notEqual('').toArray()) {
            console.error("[Sync] There should be no changed attachmentBlob", attachmentBlob);
        }
        for (let deletedAttachment of await mobileDb.deletedAttachments.toArray()) {
            await attachmentApi.delete(deletedAttachment._id);
            console.log("[Sync] Deleted attachment handled", deletedAttachment);
        }
        for (let deletedDeficiency of await mobileDb.deletedDeficiencies.toArray()) {
            console.error("[Sync] There should not be any deletedDeficiency", deletedDeficiency);
        }
        if (progressHandler)
            progressHandler(1);
    }
    async loadAllData(progressHandler, userSubject) {
        if (progressHandler)
            progressHandler(0.01)

        await Promise.all([
            userApi.getAll().then(users => this.handleItem(mobileDb.users, "user", users)),
            projectApi.getMeta().then(project_metaData => this.handleItem(mobileDb.projectMeta, "projectMeta", Object.assign(project_metaData, { id: 1 }))),
            listApi.getByName("project_assignments").then(project_assignments => Promise.all([
                this.handleItem(mobileDb.lists, "project_assignments", project_assignments),
                listApi.getListItems(project_assignments._id).then(project_assignmentItems =>
                    this.handleItem(mobileDb.listItems, "project_assignments_item", project_assignmentItems)
                )
            ])),
            listApi.getByName("protocol_due").then(protocol_due => Promise.all([
                this.handleItem(mobileDb.lists, "protocol_due", protocol_due),
                listApi.getListItems(protocol_due._id).then(protocol_due_items =>
                    this.handleItem(mobileDb.listItems, "protocol_due_items", protocol_due_items)
                )
            ])),
            listApi.getByName("project_trades").then(project_trades => Promise.all([
                this.handleItem(mobileDb.lists, "project_trades", project_trades),
                listApi.getListItems(project_trades._id).then(project_trades_items =>
                    this.handleItem(mobileDb.listItems, "project_trades_items", project_trades_items)
                )
            ])),
            listApi.getByName("protocol_component").then(protocol_component => Promise.all([
                this.handleItem(mobileDb.lists, "protocol_component", protocol_component),
                listApi.getListItems(protocol_component._id).then(protocol_component_items =>
                    this.handleItem(mobileDb.listItems, "protocol_component_items", protocol_component_items)
                )
            ])),
            listApi.getByName("protocol_floor").then(protocol_floor => Promise.all([
                this.handleItem(mobileDb.lists, "protocol_floor", protocol_floor),
                listApi.getListItems(protocol_floor._id).then(protocol_floor_items =>
                    this.handleItem(mobileDb.listItems, "protocol_floor_items", protocol_floor_items)
                )
            ])),
            listApi.getByName("protocol_direction").then(protocol_direction => Promise.all([
                this.handleItem(mobileDb.lists, "protocol_direction", protocol_direction),
                listApi.getListItems(protocol_direction._id).then(protocol_direction_items =>
                    this.handleItem(mobileDb.listItems, "protocol_direction_items", protocol_direction_items)
                )
            ])),
            listApi.getByName("project_deficiency").then(project_deficiencyList =>
                Promise.all([
                    this.handleItem(mobileDb.lists, "project_deficiencyList", project_deficiencyList),
                    listApi.getListItems(project_deficiencyList._id).then(project_deficiencies =>
                        Promise.all([
                            this.handleItem(mobileDb.listItems, 'project_deficiencies', project_deficiencies),
                            project_deficiencies.map(def => listApi.getByParentListItemId(def._id).then(subList =>
                                Promise.all([
                                    this.handleItem(mobileDb.lists, 'project_deficiency_sub', subList),
                                    listApi.getListItems(subList._id).then(items => this.handleItem(mobileDb.listItems, 'project_deficiencies_item', items))
                                ])
                            ))
                        ])
                    )
                ])
            )
        ])

        let i = 1;
        let user = await userApi.getBySubject(userSubject);
        await this.handleItem(mobileDb.users, "user", user);
        let userId = user._id;
        let projects = (await projectApi.search(`Archived:0 AND Status:Active AND (_CoordinatorId:${userId} OR _SubstituteCoordinatorId:${userId})`,300)).data;
        for (let project of projects) {
            i++;
            if (progressHandler)
                progressHandler(i / projects.length);
            await this.handleItem(mobileDb.projects, "project", project);
            await this.loadData(project);
        }
    }
    async loadData(project) {
        if (project._id in this.currentLoading)
            return false;
        let start = new Date().getTime();
        this.currentLoading[project._id] = addProcess("Downloading Project data for Offline-Use", true);
        let protocol = await projectProtocolApi.get(project._id);
        if (!protocol)
            protocol = await projectProtocolApi.save({ projectId: project._id, deficiencies: [], currentVersion: 1 })
        let projectAttachments = (await Promise.all([attachmentApi.getByAssignment('project', project._id, 'ProjektUebersicht.png')].concat(protocol.locations == null ? [] :protocol.locations.map(l => attachmentApi.get(l.id))))).filter(notNull=>notNull);
        let organizations = [];
        let contacts = [];
        if (project.involvedContacts) {
            organizations = await Promise.all(project.involvedContacts.filter(notNull => notNull).map(ic => organizationApi.get(ic.organizationId)));
            contacts = await Promise.all(project.involvedContacts.filter(notNull => notNull).map(ic => organizationApi.getContact(ic.contactId)));
        }
        let attachmentsBlobs = await Promise.all(projectAttachments.map(a => offlineAttachmentApi.get(a._id).then(offlineA => (!offlineA || offlineA.lastWrite != a.lastWrite) ? attachmentApi.getBlob(a._id).then(b => ({ _id: a._id, blob: b })) : null))).then(attachments=>attachments.filter(notNull=>notNull));
        start = new Date().getTime();
        await this.handleItem(mobileDb.protocols, "protocol", protocol)

        await this.handleItem(mobileDb.organizations, "organization", organizations)

        await this.handleItem(mobileDb.contacts, "contact", contacts)

        await this.handleItem(mobileDb.attachments, "attachment", projectAttachments)

        await this.handleItem(mobileDb.attachmentBlobs, "attachmentBlob", attachmentsBlobs)

        console.log("[SAVE]", new Date().getTime() - start)
        this.currentLoading[project._id]();
        delete this.currentLoading[project._id]
    }
}
let offlineService = new OfflineService();
export { OfflineService, offlineService }
export default offlineService

const fileToBase64 = file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener("load", () => {
            resolve(reader.result);
        });
        reader.addEventListener("error", error => {
            reject(error);
        });
    });
};

let offlineAttachmentApi = {
    async get(id) {
        return await mobileDb.attachments.get(id)
    },
    async save(attachment,isUnchanged) {
        if (!attachment._id)
            attachment._id = "m_" + guid().replaceAll("-", "");
        attachment._changed = isUnchanged ?"":new Date().toJSON();
        await mobileDb.attachments.put(structuredClone(attachment))
        return attachment;
    },
    async saveBlob(_id, blob, isUnchanged) {
        let obj = { _id, blob, _changed: isUnchanged ?"":new Date().toJSON() };
        try {
            await mobileDb.attachmentBlobs.put(obj);
        } catch {
            obj.blob = await fileToBase64(blob);
            obj.isBase64 = true;
            await mobileDb.attachmentBlobs.put(obj);
        }        
        return obj;
    },
    async delete(_id) {
        if (await mobileDb.attachments.get(_id))
            await mobileDb.attachments.delete(_id);
        await mobileDb.deletedAttachments.put({ _id });
    },
    async getBlobFromItem(item) {
        if (!item)
            return null;
        if (item && item.isBase64) {
            let blob = await fetch(item.blob).then(res => res.blob());
            return blob;
        }
        return item.blob;
    },
    async getBlob(_id) {
        let item = (await mobileDb.attachmentBlobs.get(_id));
        return this.getBlobFromItem(item);
    },
    async getBlobItem(_id) {
        let item = (await mobileDb.attachmentBlobs.get(_id));
        if (!item)
            return null;
        if (item && item.isBase64) {
            item.blob = await fetch(item.blob).then(res => res.blob());
        }
        return item;
    },
    async getByAssignment(assignmentType, assignment, name, type) {
        if (type) {
            return await mobileDb.attachments.get({ assignmentType, assignment, type, name })
        } else {
            return await mobileDb.attachments.get({ assignmentType, assignment, name })
        }
    },
    async getAllByAssignment(assignmentType, assignment, type) {
        if (type) {
            return await mobileDb.attachments.where({ assignmentType, assignment, type }).toArray()
        } else {
            return await mobileDb.attachments.where({ assignmentType, assignment }).toArray()
        }
    }
}
export { offlineAttachmentApi }

let offlineListApi = {
    async get(id) {
        if (!id)
            return null;
        return await mobileDb.lists.get(id);
    },
    async getAll() {
        return await mobileDb.lists.toArray();
    },
    async getByName(internalName) {
        return await mobileDb.lists.get({ internalName });
    },
    async getByParentListItemId(parentListItemId) {
        return await mobileDb.lists.get({ parentListItemId });
    },
    async getListItems(listId) {
        return await mobileDb.listItems.where({ listId }).toArray();
    }
}
export { offlineListApi }

let offlineProjectApi = {
    async getAll() {
        return await mobileDb.projects.toArray();
    },
    async getMeta() {
        return await mobileDb.projectMeta.get(1);
    },
    async getForUser(userId) {
        return await mobileDb.projects.filter(p => p.coordinatorId == userId || p.substituteCoordinatorId == userId).toArray();
    },
    async get(id) {
        if (!id)
            return null;
        return await mobileDb.projects.get(id);
    },
    async save(project) {
        project._changed = new Date().toJSON();
        await mobileDb.projects.put(structuredClone(project));
        return project;
    }
}
export { offlineProjectApi }

let offlineProjectProtocolApi = {
    async get(id) {
        if (!id)
            return null;
        return await mobileDb.protocols.get({ projectId: id });
    },
    async deleteDeficiency(id,projectId) {
        await mobileDb.deletedDeficiencies.put({ id, projectId });
    },
    async save(protocol) {
        protocol._changed = new Date().toJSON();
        await mobileDb.protocols.put(structuredClone(protocol))
        return protocol;
    }
}
export { offlineProjectProtocolApi }

let offlineOrganizationApi = {
    async get(id) {
        if (!id)
            return null;
        return await mobileDb.organizations.get(id);
    },
    async getAll() {
        return await mobileDb.organizations.toArray();
    },

    async getContact(id) {
        if (!id)
            return null;
        return await mobileDb.contacts.get(id);
    },
    async getContacts() {
        return await mobileDb.contacts.toArray();
    }
}
export { offlineOrganizationApi }


let offlineUserApi = {
    async get(id) {
        if (!id)
            return null;
        return await mobileDb.users.get(id);
    },
    async getBySubject(subjectId) {
        return await mobileDb.users.get({ subjectId });
    },
    async getAll() {
        return await mobileDb.users.toArray();
    },
}
export { offlineUserApi }