<template>
    <div class="area-content-body admin-settings">
        <DxForm :form-data="settings" :show-validation-summary="true" label-mode="outside" label-location="top" :col-count="2">
            <DxGroupItem caption="Mail Versand" :col-count="3" :col-span="2">
                <DxSimpleItem data-field="AzureGraphTenantId" :label="{text:'Azure TenantId'}" :col-span="1">
                </DxSimpleItem>
                <DxSimpleItem data-field="AzureGraphClientId" :label="{text:'Azure ClientId'}" :col-span="1">
                </DxSimpleItem>
                <DxSimpleItem data-field="AzureGraphClientSecret" :label="{text:'Azure ClientSecret'}" :col-span="1">
                </DxSimpleItem>
                <DxSimpleItem data-field="AzureGraphDefaultSender" :label="{text:'Azure DefaultSender'}" :col-span="1">
                </DxSimpleItem>
            </DxGroupItem>
        </DxForm>
        <DxButton @click="save" hint="Einstellungen Speichern">Speichern</DxButton>
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';

    import DxForm, { DxSimpleItem, DxGroupItem } from 'devextreme-vue/form'
    import DxButton from 'devextreme-vue/button'
    import listApi from '../services/ListApi';

    export default defineComponent({
        components: {
            DxForm,
            DxSimpleItem,
            DxGroupItem,
            DxButton
        },
        data() {
            return {
                listId:null,
                settings: {}
            };
        },
        watch: {
        },
        async created() {
            this.fetchData();
        },
        methods: {
            async fetchData() {
                this.listId = (await listApi.getByName("sys_settings"))._id;
                let dict = {}
                for (let item of ["AzureGraphTenantId", "AzureGraphClientId", "AzureGraphClientSecret", "AzureGraphDefaultSender"]) {
                    dict[item] = (await listApi.getListItemByValue(this.listId, item)).data;
                }
                Object.assign(this.settings, dict)
            },
            async save() {
                for (let item of ["AzureGraphTenantId", "AzureGraphClientId", "AzureGraphClientSecret", "AzureGraphDefaultSender"]) {
                    let listItem = await listApi.getListItemByValue(this.listId,item)
                    if (!listItem) {
                        await listApi.saveItem({ listId: this.listId, value: item, data: this.settings[item] })
                    } else {
                        await listApi.saveItem({ _id: listItem._id, listId: this.listId, value: item, data: this.settings[item] })
                    }
                }
            }
        },
    });
</script>

<style>
</style>
