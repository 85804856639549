import { ref } from 'vue'
import axios from './Axios';

class MapApi {
    constructor() {
        this.isDirty = ref(false);
        this.isLoading = ref(false);
        this.isSaving = false;
        if (!window.Api) {
            window.Api = {};
        }
        window.Api.Map = this;
    }
    async get(address, style, zoom) {
        this.isLoading.value = true;
        let propString = "";
        if (typeof (zoom) !== "undefined") {
            propString += `&zoom=${zoom}`;
        }
        if (typeof (style) !== "undefined") {
            propString += `&style=${encodeURIComponent(this.mapStylesUrlArgs(style || []))}`;
        }
        let result = await axios.get(`/api/maps?address=${encodeURIComponent(address)}${propString}`);
        return result.data;
    }
    async getKey(){
        let result = await axios.get(`/api/maps/token`);
        return result.data;
    }
    async getAssignment(address) {        
        let result = await axios.get(`/api/maps/assignment?address=${encodeURIComponent(address)}`);
        return result.data;
    }
    mapStylesUrlArgs(mapStyleJson) {
        var params = [];

        mapStyleJson.forEach((style) => {
            var styleString = '';

            if (style.stylers && style.stylers.length > 0) {
                styleString += (style.featureType ? ('feature:' + style.featureType) : 'feature:all') + '|';
                styleString += (style.elementType ? ('element:' + style.elementType) : 'element:all') + '|';

                style['stylers'].forEach(function (styler) {
                    var propertyname = Object.keys(styler)[0];
                    var propertyval = String(styler[propertyname]).replace('#', '0x');
                    styleString += propertyname + ':' + propertyval + '|';
                });
            }

            styleString = styleString.substring(0, styleString.length - 1);

            params.push('style=' + styleString);
        });

        return params.join('&');
    }
}


let defaultMapStyle = [
    {
        "elementType": "geometry.fill",
        "stylers": [
            {
                "saturation": -100
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#5c5c5c"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels",
        "stylers": [
            {
                "saturation": -100
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#383838"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "labels.icon",
        "stylers": [
            {
                "color": "#9c9c9c"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#454545"
            }
        ]
    },
    {
        "featureType": "landscape.man_made.building",
        "elementType": "geometry",
        "stylers": [
            {
                "lightness": -4
            },
            {
                "saturation": -100
            }
        ]
    },
    {
        "featureType": "landscape.man_made.business_corridor",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#e3e3e3"
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.icon",
        "stylers": [
            {
                "color": "#9c9c9c"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#454545"
            }
        ]
    },
    {
        "featureType": "poi.attraction",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.business",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.business",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.government",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "poi.medical",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.medical",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.place_of_worship",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.school",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.school",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.sports_complex",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.sports_complex",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
            {
                "saturation": -100
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#828282"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#383838"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels.icon",
        "stylers": [
            {
                "lightness": 25
            },
            {
                "saturation": -100
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "saturation": -100
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "transit.station",
        "elementType": "labels.text",
        "stylers": [
            {
                "color": "#8f8f8f"
            }
        ]
    },
    {
        "featureType": "transit.station",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#f5f5f5"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#919191"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    }
];

let mapApi = new MapApi();
export { defaultMapStyle, MapApi, mapApi }

export default mapApi;