<template>
    <div v-if="laterWorkTemplate" class="area-content-body admin-project-type-template">
        <div class="admin-project-type-template-list">
            <div class="admin-project-type-template-head">
                <DxButton icon="back" @click="back" hint="Zurück"/>
                <span class="admin-project-type-template-title">Späteren Arbeiten Vorlage | </span>
                <HeaderFieldEditor inputHint="Name" :allowEdit="true" v-model:dataSource="laterWorkTemplate.name" />
                <DxButton v-if="false" icon="trash" @click="deleteTemplate" hint="Vorlage löschen" />
                <DxButton icon="add" @click="addCategory" hint="Kategorie hinzufügen" />
                <DxButton icon="save" @click="saveCategory" :disabled="!hasChanges" hint="Kategorie Speichern" />
            </div>
            <div class="admin-list">
                <div v-for="templateCategory in laterWorkTemplate.categories" :key="templateCategory.id" class="admin-list-item" @click.prevent="selectCategory(templateCategory.id)" :class="{'active':category&&category.id===templateCategory.id}">
                    <div class="admin-list-item-button material-symbols-outlined" @click.prevent.stop="deleteCategory(templateCategory)" title="Löschen">delete</div>
                    <div class="admin-list-item-content">{{templateCategory.name}}</div>
                </div>
            </div>
        </div>
        <div v-if="category" class="admin-project-type-hazard-group hazard-group-with-name">
            <HeaderFieldEditor inputHint="Name" :allowEdit="true" v-model:data-source="category.name"></HeaderFieldEditor>
            <sub-categories-list
                :assignmentOverride="{assignment:'laterWorkTemplateImages',assignmentType:'system',type:laterWorkTemplate._id}"
                v-model:items="category.subCategories"
                :show-template="true"
                :accept-custom="true" />
            <DxButton icon="add" @click="addSubCategory" hint="Unterkategorie hinzufügen" />
        </div>
    </div>
    <div v-else>Keine Daten</div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import SubCategoriesList from '../components/sub-categories-list.vue'
    import HeaderFieldEditor from '../components/header-field-editor.vue'
    import laterWorkTemplateApi from '../services/LaterWorkTemplateApi'
    import DxButton from 'devextreme-vue/button'
    import { confirm } from 'devextreme/ui/dialog'
    import { guid } from '../services/Util';

    export default defineComponent({
        components: {
            SubCategoriesList,
            HeaderFieldEditor,
            DxButton
        },
        data() {
            return {
                laterWorkTemplate: null,
                laterWorkTemplateOrig: null,
                category: null,
                allowEdit: null,
                hasChanges: false
            };
        },
        async beforeRouteUpdate() {
            if (this.hasChanges) {
                return await confirm("Es gibt ungespeicherte Änderungen - sollen diese verworfen werden?", "Änderungen verwerfen?")
            }
            return true;
        },
        watch: {
            async '$route.params'() {
                this.fetchData();
            },
            'laterWorkTemplate': {
                handler() {
                    if (this.laterWorkTemplate && this.laterWorkTemplateOrig) {
                        this.hasChanges = this.laterWorkTemplateOrig != JSON.stringify(this.laterWorkTemplate);                     
                    }
                },
                deep: true
            },
            'laterWorkTemplate.categories'() {
                this.category = this.laterWorkTemplate?.categories?.find(g => g.id === this.$route.params.category);
            }
        },
        async created() {
            this.fetchData();
        },
        methods: {
            async fetchData() {
                if (this.$route.params.id) {
                    this.laterWorkTemplate = structuredClone(await laterWorkTemplateApi.get(this.$route.params.id));
                    this.laterWorkTemplateOrig = JSON.stringify(this.laterWorkTemplate);
                    if (this.$route.params.category) {
                        if (this.$route.params.category == "new") {
                            let g = { name: 'Neu', id: guid(), subCategories: [{ name: '', id: guid() }] };
                            this.laterWorkTemplate.categories.push(g);
                            await laterWorkTemplateApi.save(this.laterWorkTemplate);
                            this.hasChanges = false;
                            this.selectCategory(g.id);
                        } else {
                            this.category = this.laterWorkTemplate.categories.find(g => g.id === this.$route.params.category);
                        }
                    } else {
                        let g = this.laterWorkTemplate.categories.find(() => true);
                        if (g)
                            this.selectCategory(g.id)
                    }
                } else {
                    this.laterWorkTemplate = null;
                    this.category = null;
                    this.laterWorkTemplates = await laterWorkTemplateApi.getAll();
                }
            },
            back() {
                this.$router.push("/Admin/Templates");
            },
            async selectTemplate(i) {
                this.$router.push("/Admin/LaterWorkTemplates/" + i.itemData._id)
            },
            async deleteTemplate() {
                if (await confirm("Soll die Vorlage gelöscht werden?", "Vorlage Löschen?")) {
                    await laterWorkTemplateApi.delete(this.laterWorkTemplate);
                    this.back();
                }
            },
            async add() {
                let item = await laterWorkTemplateApi.createNew();
                item.name = 'Neu'
                setTimeout(() => { this.fetchData(); }, 1)
            },
            async selectCategory(id) {
                if (!this.laterWorkTemplate)
                    throw new Error("No laterWorkTemplate!", this.laterWorkTemplate);
                this.$router.push("/Admin/LaterWorkTemplates/" + this.laterWorkTemplate._id + "/" + id)
            },
            async addCategory() {
                if (!this.laterWorkTemplate.categories)
                    throw new Error("No Categories!", this.laterWorkTemplate);
                await this.selectCategory("new");
            },
            async deleteCategory(i) {
                if (!this.laterWorkTemplate.categories)
                    throw new Error("No Categories!", this.laterWorkTemplate);
                if (await confirm("Soll die Gruppe gelöscht werden?", "Gruppe Löschen?")) {
                    let index = this.laterWorkTemplate.categories.findIndex(g => g.id === i.id)
                    this.laterWorkTemplate.categories.splice(index, 1);
                }
            },
            async addSubCategory() {
                if (!this.category)
                    throw new Error("No Category!", this);
                this.category.subCategories.push({ name: '', id: guid() })
            },
            async saveCategory() {
                if (!this.laterWorkTemplate)
                    throw new Error("No laterWorkTemplate!", this);
                await laterWorkTemplateApi.save(this.laterWorkTemplate);
                await this.fetchData();
            },
            async createType() {
                this.$router.push("/Admin/LaterWorks/new:" + this.laterWorkTemplate._id)
            }
        },
    });
</script>

<style>
    .admin-project-type-template-head {
        display: grid;
        grid-template-columns: max-content max-content minmax(0,1fr);
        grid-auto-flow: column;
        grid-auto-columns: max-content;
        align-items: center;
        margin-bottom: 10px;
    }

        .admin-project-type-template-head > * {
            margin: 0 5px;
        }

        .admin-project-type-template-head .header-field-editor {
        }

    .admin-project-type-template {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: minmax(0,1fr);
    }

    .admin-project-type-hazard-group {
        display: grid;
        grid-template-rows: minmax(0,1fr) max-content;
        grid-template-columns: minmax(0,1fr);
        padding-right: 10px;
    }

        .admin-project-type-hazard-group.hazard-group-with-name {
            grid-template-rows: max-content minmax(0,1fr) max-content;
        }

    .hazards-list {
        overflow: auto;
        padding: 0 20px;
        background-color: var(--color-background-light);
        border-radius: var(--default-border-radius);
    }

    .admin-project-type-template-list {
        display: grid;
        grid-template-rows: max-content minmax(0,1fr);
        grid-template-columns: minmax(0,1fr);
    }

    .admin-project-type-template-list {
    }

    .admin-list-item {
        display: grid;
        align-items: center;
        grid-template-columns: max-content minmax(0,1fr);
        grid-auto-flow: column;
        padding: 5px;
    }

        .admin-list-item.active {
            background-color: var(--color-background-light);
        }

        .admin-list-item:hover {
            background-color: var(--color-background-lighter);
        }

    .admin-list-item-button {
        transition: all 0.1s;
        padding: 3px;
        color: var(--color-text);
        background: none;
        border-radius: var(--default-border-radius);
        opacity: 0;
    }

    .admin-list-item:hover .admin-list-item-button {
        opacity: 1;
    }

    .admin-list-item-button:hover {
        background: var(--color-background-light);
    }

    .admin-list-item-content {
        padding: 0 10px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .header-field-editor {
        font-size: 1rem;
        padding: 6px 11px;
        margin: 4px;
    }
</style>
