import {SvgHelper,EllipseMarker } from 'markerjs2'

class FilledEllipseMarker extends EllipseMarker {

    constructor(container, overlayContainer, settings) {
        super(container, overlayContainer, settings);  

   
        this.setOpacity = this.setOpacity.bind(this);    
        this.createVisual = this.createVisual.bind(this);
    }

    /**
   * Sets marker's opacity.
   * @param opacity - new opacity value (0..1).
   */
     setOpacity(opacity) {
        this.opacity = opacity;
         if (this.visual) {             
             this.visual.removeAttribute("opacity");
             SvgHelper.setAttributes(this.visual, [['fill-opacity', this.opacity.toString()], ['stroke-opacity', '1']]);
        }
        this.stateChanged();
    }

    createVisual() {
        this.visual = SvgHelper.createEllipse(this.width / 2, this.height / 2, [
            ['fill', this.fillColor],
            ['stroke', this.strokeColor],
            ['stroke-width', this.strokeWidth.toString()],
            ['stroke-dasharray', this.strokeDasharray],
            ['fill-opacity', this.opacity.toString()],
            ['stroke-opacity', '1'],
        ]);
        this.addMarkerVisualToContainer(this.visual);
    }

    // Override typeName to set the custom type name
    static get typeName() {
        return 'FilledEllipseMarker';
    }
    // Override title to set the custom title
    static get title() {
        return 'Filled Ellipse Marker';
    }   
}

export default FilledEllipseMarker;