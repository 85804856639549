import { ref,watch } from "vue";
import { guid } from "./Util";

let processStack = {};
let processor = {
    isDirty: ref(false),
    isUploading: ref(false)
}


let update = () => {
    processor.isDirty.value = Object.values(processStack).length > 0;
    processor.isUploading.value = Object.values(processStack).filter(p => !p.isLocal).length > 0;
}

let afterProcessing = (cb) => {
    let watcher = watch(processor.isDirty, (d) => {
        if (!d) {
            watcher();
            cb();
        }
    })
}

let afterProcessingAsync = ()=>new Promise(res => {
    afterProcessing(res);
})

let addProcess = (info, isLocal) => {
    let id = guid();
    console.log("[P] Processing ", id, info)
    processStack[id] = {
        info: info,
        isLocal: isLocal
    };
    update();
    return () => {
        delete processStack[id];
        console.log("[P] Finished ", id)
        update();
    }
}

export {
    addProcess, processor, afterProcessing, afterProcessingAsync
}
