<template>
    <div class="attachment-list"
         :class="{'attachment-list-invalid':invalid,'attachment-list-over':over}"
         @dragenter.stop.prevent="dragenter"
         @dragleave.stop.prevent="dragleave"
         @dragover.stop.prevent="dragover"
         @drop.stop.prevent="drop">
        <div class="attachment-items">
            <div v-for="attachment in attachments" v-bind:key="attachment._id" class="attachment-item">
                <span class="attachment-item-text">{{attachment.name}}</span>
                <input @change="changeDescription($event,attachment)" :value="attachment.description" class="attachment-item-input" />
                <span class="attachment-item-text">{{new Date(attachment.lastWrite).toLocaleString()}}</span>
                <span class="head-toolbar-button-compact no-margin" v-if="allowDownload" @click="download(attachment)" title="Anhang herunterladen">download</span>
                <span class="head-toolbar-button-compact no-margin" v-if="allowDelete" @click="remove(attachment)" title="Anhang löschen">delete</span>
                <span class="head-toolbar-button-compact no-margin" v-if="allowSelect" @click="select(attachment)" title="Anhang Auswählen">check</span>
            </div>
        </div>
        <div class="attachment-list-upload">
            <span class="head-toolbar-button-compact no-margin" v-if="allowUpload" @click="openUpload()" title="Anhang hochladen">upload</span>
        </div>        
        <input ref="fileInput" type="file" :accept="acceptTypeString" hidden @input="fileInputChange" />
    </div>
</template>

<script lang="js">    

    // import { confirm } from 'devextreme/ui/dialog'
    import notify from 'devextreme/ui/notify';
    import { defineComponent } from 'vue';
    import attachmentApi from '../services/AttachmentApi'


    export default defineComponent({
        components: {
        },
        props: {  
            'attachmentQuery': Object,
            'attachmentFilter': Function,
            'allowUpload': Boolean,
            'allowSelect': Boolean,
            'allowDescription': Boolean,
            'allowDownload': Boolean,
            'allowDelete': Boolean,
            'disableDrop': Boolean,
            'allowedTypes': {},
            'onDelete': {},
            'onSelect': {},
            'onNoData': {}
        },
        data() {
            return {
                attachments: [],
                invalid: false,
                over: false,
                counter: 0,
                acceptTypeString: "",
                stopWatcher: null
            };
        },
        created() {           
            if (this.allowedTypes) {
                if (typeof (this.allowedTypes) === "string") {
                    this.acceptTypeString = this.allowedTypes;
                } else if (Array.isArray(this.allowedTypes)) {
                    this.acceptTypeString = this.allowedTypes.join(", ");
                } else {
                    this.acceptTypeString = "";
                }
            }
            if(this.attachments){
                this.loadAttachments();
            }
        },
        methods: {
            async getAttachments(attachmentQuery,attachmentFilter) {
                if (typeof (attachmentQuery) === "string") {
                    throw new Error("attachmentQuery cannot be string");
                }
                else if (typeof (attachmentQuery) === "object") {
                    let atts = await attachmentApi.getAllByAssignment(attachmentQuery.assignmentType,attachmentQuery.assignment,attachmentQuery.type);
                    if(attachmentFilter)
                        atts = atts.filter(attachmentFilter)
                    atts.sort((a, b) => new Date(b.lastWrite).getTime() - new Date(a.lastWrite).getTime());
                    if(atts.length == 0 && this.onNoData){
                        this.onNoData();
                    }
                    return atts;
                }
            },
            async loadAttachments() {
                this.attachments = await this.getAttachments(this.attachmentQuery,this.attachmentFilter)
            },
            async changeDescription(event, attachment) {
                attachment.description = event.target.value;
                //await attachmentApi.save(attachment);
            },
            dragover() {
            },
            dragenter(e) {
                if (this.disableDrop)
                    return;
                this.counter++;
                if (e.dataTransfer && e.dataTransfer.items) {
                    let types = Array.from(e.dataTransfer.items).map(n => n.type);

                    if (this.allowedTypes) {
                        if (typeof (this.allowedTypes) === "string" && types.every(t => t.toLowerCase().startsWith(this.allowedTypes.replace(/\*/g, '').toLowerCase()))) {
                            this.invalid = false;
                        } else if (Array.isArray(this.allowedTypes) && types.every(t => this.allowedTypes.map(at => at.toLowerCase()).includes(t.toLowerCase()))) {
                            this.invalid = false;
                        } else {
                            this.invalid = true;
                        }
                    } else {
                        this.invalid = false;
                    }
                    this.over = true;
                }
            },
            dragleave() {
                if (this.disableDrop)
                    return;
                this.counter--;
                if (this.counter < 0)
                    this.counter = 0;
                if (this.counter == 0) {
                    this.over = false;
                    this.invalid = false;
                } 
            },
            async saveFile(blob) {
                let saveData = structuredClone(this.attachmentQuery);
                saveData.name = blob.name;
                let att = await attachmentApi.save(saveData)                
                await attachmentApi.saveBlob(att._id, blob);
                await this.loadAttachments();
            },
            async drop(e) {
                if (this.disableDrop)
                    return;
                if (this.invalid) {
                    notify("Mindestens eine der Dateien ist vom falschen Typ.", "error");
                    this.over = false;
                    this.invalid = false;
                    return;
                }
                if (e.dataTransfer && e.dataTransfer.files) {                    
                    let files = Array.from(e.dataTransfer.files); // SOMETHING WITH STREAMS?
                    // Todo: allow multiple?
                    if (files.length > 0) {
                        let file = files[0];
                        await this.saveFile(file);
                    }
                }
                this.over = false;
                this.invalid = false;
            },
            openUpload() {
                if (this.$refs.fileInput) {
                    this.$refs.fileInput.value = null;
                    this.$refs.fileInput.click();
                }
                
            },
            select(attachment){
                if(this.onSelect)
                    this.onSelect(attachment)


            },
            async remove(attachment) {
                await attachmentApi.delete(attachment._id);
                await this.loadAttachments();
            },
            async download(attachment) {
                attachmentApi.download(attachment._id);
            },
            async fileInputChange() {
                if (this.$refs.fileInput && this.$refs.fileInput.files.length > 0) {
                    let file = this.$refs.fileInput.files[0];
                    await this.saveFile(file);
                }
            },
            
        }
    });
</script>

<style>
    .attachment-items {
        display: grid;
        grid-gap: 5px;
        padding: 5px;
        overflow: auto;
    }
    .attachment-item {
        display: grid;
        grid-template-columns: 3fr 3fr 2fr;
        grid-auto-columns: max-content;
        grid-auto-flow: column;
        align-items: center;
        background: var(--color-background);
        padding: 5px;
        grid-gap: 5px;
        align-self: start;
        border-radius: var(--default-border-radius);
    }
        .attachment-item-text{
            padding:5px 10px;

        }
    input.attachment-item-input {
        background: var(--color-dx-dark);
        border: none;
        border-radius: var(--default-border-radius);
        padding: 7px 10px;
        color: var(--color-text);
    }
    input.attachment-item-input:focus {
        border:0;
        outline: 1px solid var(--color-accent);
    }

    .attachment-list {
        display: grid;
        border: 1px solid var(--color-background-light);
        background-color: var(--color-background-light);
        padding: 5px 10px;
        border-radius: var(--default-border-radius);
        position: relative;
        min-height: 40px;
        grid-template-rows: minmax(0,1fr) max-content;
        max-height: 350px;
        align-items: start;
    }
    .attachment-list-over {
        box-shadow: inset 0 0 4px var(--color-success);
    }
    .attachment-list-invalid {
        box-shadow: inset 0 0 4px var(--color-error);
    }
    .attachment-list-upload{
        margin-top: 5px;
        text-align: center;
    }
</style>
