<template>
    <div v-if="project" class="area-content-body mobile-project">
        <div class="mobile-head" :class="{dirty:$root.isDirty}">
            <span class="head-toolbar-button-compact no-margin" @click="back">arrow_back</span>
            <span class="mobile-head-title">
                Mängel
            </span>
            <span class="head-toolbar-button-compact" @click="toggleOnline">{{isOffline?'cloud_off':'cloud'}}</span>
        </div>
        <div class="mobile-sub-head" @click="showInfo=true">
            {{project.number}} | {{project.name}} | {{protocol?("Protokoll-Nr.: "+protocol.currentVersion):""}}
        </div>
        <div v-if="project && showInfo" class="mobile-project-info">
            <div class="mobile-project-headline">
                <span class="mobile-project-headline-text">Projekt Info</span>
                <span class="head-toolbar-button-compact no-margin" @click="showInfo=false">close</span>
            </div>
            <DxForm :form-data="project" :show-validation-summary="true" label-mode="outside" label-location="top" :read-only="true" :scrollingEnabled="true" class="mobile-form">
                <DxSimpleItem data-field="streetAndNr" :label="{text:'Straße / Nr.'}"></DxSimpleItem>
                <DxSimpleItem data-field="zipCode" :label="{text:'PLZ'}"></DxSimpleItem>
                <DxSimpleItem data-field="city" :label="{text:'Ort'}"></DxSimpleItem>
                <DxSimpleItem data-field="internalDescription" :label="{text:'Baustellen-Info Intern'}"></DxSimpleItem>
                <DxSimpleItem :label="{text:'Objektüberwachung'}">
                    <template #default>
                        <dx-text-area :value="infoMonitoring" :readOnly="true" :autoResizeEnabled="true" :min-height="50" :max-height="300" />
                    </template>
                </DxSimpleItem>
                <DxSimpleItem :label="{text:'Verteiler'}">
                    <template #default>
                        <dx-text-area :value="infoMailReceivers" :readOnly="true" :autoResizeEnabled="true" :min-height="50" :max-height="300" />
                    </template>
                </DxSimpleItem>
            </DxForm>
        </div>
        <div v-if="!showInfo" class="mobile-project-attachments">
            <span class="mobile-flow-scroll mobile-images">
                <image-viewer v-for="attachment in attachments" v-bind:key="attachment._id" :class="{'mobile-attachment-selected': location && location.id == attachment._id}" :attachment="attachment._id" :imgWidth="200" :imgHeight="200" @click="setLocation(attachment._id)" height="120px" :allow-clear="true" :allow-full-screen="true" @clear="removeAttachment(attachment._id)"></image-viewer>
            </span>
            <div class="mobile-flow-row">
                <span class="head-toolbar-button-compact no-margin" @click="addLocation(false)">add_a_photo</span>
                <span class="head-toolbar-button-compact no-margin" @click="addLocation(true)">add_photo_alternate</span>
                <span v-if="unassignedAttachments && unassignedAttachments.length>0" class="head-toolbar-button-compact no-margin border-color-warn" @click="showImageAssignment=true">assignment_late</span>
            </div>
        </div>
        <DxForm v-if="!showInfo && deficiency && !location" :form-data="deficiency" :show-validation-summary="true" label-mode="outside" label-location="top" :scrollingEnabled="true" class="mobile-form">
            <DxSimpleItem data-field="groupItemId" :label="{text:'Mangelgruppe'}">
                <template #default>
                    <ListLookup list="project_deficiency" @manual-change="deficiency.itemId=null" :allow-empty="true" v-model:value="deficiency.groupItemId" :wrap-item-text="true"></ListLookup>
                </template>
            </DxSimpleItem>
            <DxSimpleItem data-field="itemId" :label="{text:'Mangel'}">
                <template #default>
                    <ListLookup v-if="deficiency.groupItemId" :list="'child_'+deficiency.groupItemId" v-model:value="deficiency.itemId" :wrap-item-text="true"></ListLookup>
                    <ListLookup v-if="!deficiency.groupItemId" parent-list="project_deficiency" v-model:value="deficiency.itemId" :wrap-item-text="true" @parent-child-changed="(e)=>deficiency.groupItemId=e"></ListLookup>
                </template>
            </DxSimpleItem>
            <DxSimpleItem data-field="tradeId" :label="{text:'Gewerk'}">
                <template #default>
                    <ListLookup v-model:value="deficiency.tradeId" list="project_trades" :allow-empty="true"  @manual-change="handleTradeItemChanged($event)"></ListLookup>
                </template>
            </DxSimpleItem>
            <DxSimpleItem data-field="organizationId" :label="{text:'Firma'}">
                <template #default>
                    <OrganizationLookup v-model:value="deficiency.organizationId" @manual-change="handleOrganizationChanged($event)" :filteredIds="project.involvedContacts.map(ic=>ic.organizationId).concat([project.buildingOwnerOrganizationId,project.clientOrganizationId])" :show-organization="true"></OrganizationLookup>
                </template>
            </DxSimpleItem>
            <DxSimpleItem data-field="dueId" :label="{text:'Zu erledigen bis'}">
                <template #default>
                    <ListLookup list="protocol_due" v-model:value="deficiency.dueId" default="umgehend" :wrap-item-text="true"></ListLookup>
                </template>
            </DxSimpleItem>
            <DxSimpleItem data-field="description" editor-type="dxTextArea" :editor-options="{maxHeight:200,autoResizeEnabled:true}" :label="{text:'Zusatzbemerkung'}" />
            <DxSimpleItem editor-type="dxButton" :editor-options="{onClick:removeDeficiency,icon:'trash',text:'Entfernen'}" />
        </DxForm>
        <DxForm v-if="!showInfo && deficiency && location" :form-data="location" :show-validation-summary="true" label-mode="outside" label-location="top" :scrollingEnabled="true" class="mobile-form">
            <DxSimpleItem>
                <template #default>
                    <span class="head-toolbar-button-compact no-margin" @click="setLocation(null)">close</span>
                    <span class="mobile-head-fill-text">Bildbeschreibung</span>
                </template>
            </DxSimpleItem>
            <DxSimpleItem data-field="componentId" :label="{text:'Bauteil'}">
                <template #default>
                    <ListLookup list="protocol_component" v-model:value="location.componentId"></ListLookup>
                </template>
            </DxSimpleItem>
            <DxSimpleItem data-field="floorId" :label="{text:'Geschoss'}">
                <template #default>
                    <ListLookup list="protocol_floor" v-model:value="location.floorId"></ListLookup>
                </template>
            </DxSimpleItem>
            <DxSimpleItem data-field="directionId" :label="{text:'Himmelsrichtung'}">
                <template #default>
                    <ListLookup list="protocol_direction" v-model:value="location.directionId"></ListLookup>
                </template>
            </DxSimpleItem>
        </DxForm>
        <div v-if="!showInfo && protocol" class="mobile-project-navi">
            <span class="head-toolbar-button-compact no-margin mobile-add-def" @click="addDeficiency">add</span>
            <span class="mobile-flow-scroll" ref="deficiencies">
                <span v-for="(def,index) in protocol.deficiencies" v-bind:key="def.id" @click="setDeficiency(project._id,def.id)" class="mobile-num-button" :class="{'mobile-num-button-active':deficiency&&(deficiency.id==def.id)}">{{index+1}}</span>
            </span>
            <span class="head-toolbar-button-compact no-margin mobile-add-def" @click="addImage">add_a_photo</span>
        </div>
        <DxPopup v-model:visible="showImageAssignment"
                 :fullscreen="true"
                 :drag-enabled="false"
                 :hide-on-outside-click="true"
                 :show-close-button="false"
                 :show-title="false">
            <div class="mobile-scroll">
                <image-viewer v-for="attachment in unassignedAttachments" v-bind:key="attachment._id" :attachment="attachment._id" :imgWidth="200" :imgHeight="200" @click="addAttachmentToDeficiency(attachment._id)" :allow-clear="true" :allow-full-screen="true" @clear="removeAttachment(attachment._id)"></image-viewer>
            </div>
        </DxPopup>
        <DxPopup v-model:visible="showBlockingProgress"
                 :fullscreen="true"
                 :drag-enabled="false"
                 :hide-on-outside-click="false"
                 :show-close-button="false"
                 :show-title="false">
            <div class="mobile-progess-info">{{progressInfo}}</div>
            <div class="mobile-progress" :style="{width:progress+'%'}">
            </div>
        </DxPopup>
    </div>
    <div v-else class="area-content-body mobile-project">
        Lade Daten
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import {
        DxForm,
        DxSimpleItem
    } from 'devextreme-vue/form';
    import { DxPopup } from 'devextreme-vue/popup'
    import { DxTextArea } from 'devextreme-vue/text-area'
    import { confirm } from 'devextreme/ui/dialog'
    import notify from 'devextreme/ui/notify'
    import { askForImage, guid } from '../services/Util';
    import ImageViewer from '../components/image-viewer.vue';
    import ListLookup from '../components/list-lookup.vue';
    import OrganizationLookup from '../components/organization-lookup.vue';
    import { offlineProjectProtocolApi, offlineService } from '../services/OfflineService'
    import { isEqual } from 'lodash'
import projectApi from '../services/ProjectApi';
import projectProtocolApi from '../services/ProjectProtocolApi';
import attachmentApi from '../services/AttachmentApi';
import listApi from '../services/ListApi';
import organizationApi from '../services/OrganizationApi';

    export default defineComponent({
        components: {
            DxForm,
            DxSimpleItem,
            DxPopup,
            ImageViewer,
            ListLookup,
            OrganizationLookup,
            DxTextArea
        },
        data() {
            return {
                project: null,
                protocol:null,
                deficiency:null,
                location: null,
                showImageAssignment: false,
                unassignedAttachments: [],
                attachments: [],
                showInfo:false,
                infoMonitoring:"",
                infoMailReceivers: "",
                progress: 0,
                progressInfo: "",
                showBlockingProgress: false,
                isOffline: offlineService.isOffline,
            };
        },
        async created() {
            this.$root.hideMenu = true;
            this.$root.hideHead = true;
            this.$root.hideLogo = true;
            this.fetchData()
        },
        watch:{
            '$route.params.id'() {
                if (this.$route.params.id)
                    this.fetchData();
            },
            '$route.params.deficiency'() {
                if (this.$route.params.deficiency)
                    this.fetchDeficiency();
            },
            deficiency:{
                async handler(){
                    if(!this.deficiency)
                        return;
                    let def = this.protocol?.deficiencies.find(d => d.id == this.deficiency.id);
                    if (def && !isEqual(def, this.deficiency)) {
                        if (def.itemId != this.deficiency.itemId) {
                            let protocolItem = this.protocol?.previousDeficiencies?.find(d => d.itemId == this.deficiency.itemId)
                            if (protocolItem) {
                                if (await confirm("Aus vorherigem Protokoll übernehmen?", "Übernehmen?")) {
                                    this.deficiency.groupItemId = protocolItem.groupItemId;
                                    this.deficiency.itemId = protocolItem.itemId;
                                    this.deficiency.organizationId = protocolItem.organizationId;
                                    this.deficiency.dueId = protocolItem.dueId;
                                    this.deficiency.description = protocolItem.description;
                                    /* Keep until 02.02.2024 (Maybe revert because of Gefas)
                                    let locations = this.protocol?.previousLocations?.filter(l => l.deficiencyId == protocolItem.id);
                                    for (let location of locations) {
                                        location.deficiencyId = this.deficiency.id;
                                        if (!this.protocol?.locations?.find(l => l.id == location.id)) 
                                            this.protocol?.locations?.push(location);
                                        if (!this.attachment?.find(a => a._id == location.id)) 
                                            this.attachments?.push({ _id: location.id })
                                        if (this.unassignedAttachments.find(a => a._id == location.id))
                                            this.unassignedAttachments.splice(this.unassignedAttachments.findIndex(a => a._id == location.id), 1);
                                    }
                                    */
                                }                                
                            }                            
                        }
                        Object.assign(def,this.deficiency);
                    }
                },
                deep:true
            },
            location:{
                handler(){
                    if(!this.location)
                        return;                        
                    let loc = this.protocol?.locations.find(l=>l.id==this.location.id);
                    if(loc && !isEqual(loc,this.location)){
                        Object.assign(loc,this.location);
                    }
                },
                deep:true
            }
        },
        methods: {
            async fetchData() {
                this.showInfo = false;
                if(!this.$route.params?.id){
                    this.protocol = null;
                    return;
                }
                let offlineData = await offlineProjectProtocolApi.get(this.$route.params.id);
                if (!offlineService.checkIsOffline &&(!navigator.onLine || (navigator['connection']?.downlink && navigator['connection'].downlink <= 1 && await confirm("Sie haben aktuell eine langsame Verbindung, wollen Sie offline arbeiten?", 'Offline arbeiten?')))) {
                    if (offlineData) {
                        offlineService.isOffline.value = true;
                    } else {
                        // UFF
                    }
                }                
                this.project = await projectApi.get(this.$route.params.id);
                if(!this.project){
                    return;
                }

                this.getInfoMonitoring();
                this.getInfoMailReceivers();

                this.protocol = await projectProtocolApi.get(this.$route.params.id);
                if (this.protocol == null || this.protocol.currentVersionIsSent) {
                    if (this.protocol?.currentVersionIsSent)
                        notify("Es muss ein neues Protokoll gestartet werden!", "warning")
                    this.back();
                    return;
                }

                if (!this.project.protocolInProgress)
                    this.project.protocolInProgress = true;

                if (this.protocol.deficiencies.length == 0) {
                    this.addDeficiency();
                    return;
                }
                if(!this.$route.params.deficiency){
                    this.setDeficiency(this.project._id, this.protocol.deficiencies[0].id);
                    return;
                } else {
                    this.fetchDeficiency();
                }
            },
            async fetchDeficiency(){
                this.deficiency = structuredClone(this.protocol.deficiencies.find(d=>d.id==this.$route.params.deficiency));
                if(!this.deficiency){
                    this.setDeficiency(this.project._id,this.protocol.deficiencies[0].id);
                    return;
                }
                let allAttachments = await attachmentApi.getAllByAssignment("project", this.project._id, 'deficiencies');
                this.attachments = allAttachments.filter(a => this.protocol.locations.find(l => l.id == a._id)?.deficiencyId == this.deficiency.id);
                this.unassignedAttachments = allAttachments.filter(a => !this.protocol.locations.find(l => l.id == a._id));
                if (this.$refs.deficiencies)
                    this.$refs.deficiencies.querySelector(".mobile-num-button-active")?.scrollIntoViewIfNeeded();
            },
            async toggleOnline() {
                try {
                    if (offlineService.checkIsOffline) {
                        if (!navigator.onLine) {
                            notify("Ohne Verbindung zum Internet können Sie nicht online gehen.")
                            return;
                        }
                        this.showBlockingProgress = true;
                        this.progressInfo = "Lade Protokolldaten hoch";
                        this.progress = 0;
                        offlineService.isOffline.value = false;
                        setTimeout(() => {
                            localStorage["isOffline"] = "true"
                        }, 10)
                        await offlineService.uploadData((p, info) => {
                            this.progress = Math.min(p * 100, 100);
                            if (info)
                                this.progressInfo = info;
                        });
                        this.progressInfo = null;
                        console.log("ISOFFLINE", localStorage["isOffline"])
                        this.showBlockingProgress = false;
                        delete localStorage["isOffline"]
                    } else {
                        if (await confirm("Möchten Sie in den Offline-Betrieb wechseln?", "Offline gehen?")) {
                            this.showBlockingProgress = true;
                            this.progressInfo = "Lade Daten für Offlinenutzung herunter";
                            await offlineService.loadAllData((p, info) => {
                                this.progress = Math.min(p * 100, 100);
                                if (info)
                                    this.progressInfo = info;
                            }, this.$root.currentSub)
                            this.progressInfo = null;
                            this.showBlockingProgress = false;
                            offlineService.isOffline.value = true;

                        }
                    }
                } catch (ex) {
                    notify("[ERR] " + ex, "error");
                    console.error(ex);
                }
            },
            back(){
                this.$router.push("/Mobile")
            },
            async getImage(id) {
                let image = await askForImage().catch(e => console.log("[ERR]", e))
                if (image) {
                    let att = await attachmentApi.getByAssignment('project', id,'ProjektUebersicht.png')
                    if (!att) {
                        att = await attachmentApi.save({ assignmentType: 'project', assignment: id, name: 'ProjektUebersicht.png' });
                    }
                    if(att)
                        await attachmentApi.saveBlob(att._id, image);
                }                
            },
            async setLocation(id){
                if(!id)
                {
                    this.location = null;
                    return;
                }
                if(!this.protocol)
                    throw new Error("Cannot set location without protocol!")
                this.location = this.protocol.locations.find(l => l.id == id)
            },
            async setDeficiency(projectId,id){
                this.location = null;
                this.$router.push("/Mobile/"+projectId+"/"+id);            
            },
            async addDeficiency(){
                let nId = guid();
                if(!this.protocol)
                    throw new Error("Cannot add deficiency without protocol");
                 
                this.protocol?.deficiencies.push({id:nId})
                this.$nextTick(() => { this.$router.push("/Mobile/" + this.project._id + "/" + nId); });    
                this.location = null;
            },
            async addLocation(fromGallery){
                let img = await askForImage(fromGallery).catch(e => console.log("[ERR]", e))
                if(img){
                    let att = await attachmentApi.save({assignmentType: 'project', assignment: this.project._id, type:'deficiencies','name':guid()+".png"});
                    await attachmentApi.saveBlob(att._id, img);
                    this.attachments.push(att);
                    this.protocol.locations.push({
                        id:att._id,
                        deficiencyId: this.deficiency.id
                    })
                }
            },
            async addAttachmentToDeficiency(attachmentId) {
                this.protocol.locations.push({
                    id: attachmentId,
                    deficiencyId: this.deficiency.id
                })

                this.attachments.push({ _id: attachmentId });
                this.unassignedAttachments.splice(this.unassignedAttachments.findIndex(a => a._id == attachmentId), 1);
                if (this.unassignedAttachments.length == 0)
                    this.showImageAssignment = false;
            },
            async addImage(){
                let img = await askForImage().catch(e => console.log("[ERR]", e))
                if(img){
                    let att = await attachmentApi.save({assignmentType: 'project', assignment: this.project._id, type:'deficiencies','name':guid()+".png"});
                    await attachmentApi.saveBlob(att._id,img);
                    this.unassignedAttachments.push(att);
                }
            },
            async removeAttachment(id) {
                if (this.protocol.locations.find(d => d.id == id)) {
                    this.protocol.locations.splice(this.protocol.locations.findIndex(d => d.id == id), 1);
                }                    
                if (this.attachments.find(a => a._id == id)) {
                    this.attachments.splice(this.attachments.findIndex(a => a._id == id), 1);
                }
                if (this.unassignedAttachments.find(a => a._id == id)) {
                    this.unassignedAttachments.splice(this.unassignedAttachments.findIndex(a => a._id == id), 1);
                    if (this.unassignedAttachments.length == 0)
                        this.showImageAssignment = false;
                }   
            },
            async removeDeficiency() {
                if (await confirm("Mangel wirklich entfernen?", "Mangel entfernen?")) {
                    offlineProjectProtocolApi.deleteDeficiency(this.deficiency.id,this.project._id);
                    this.protocol.deficiencies.splice(this.protocol.deficiencies.findIndex(d => d.id == this.deficiency.id), 1);
                    this.deficiency = null;
                    this.fetchDeficiency();
                }                
            },
            async getInfoMonitoring(){
                let assignmentList = await listApi.getByName("project_assignments");
                let listItems = await listApi.getListItems(assignmentList._id);
                let monitoringId = listItems.find(i=>i.data=='#monitoring')?._id;
                let items = await Promise.all(this.project.involvedContacts.filter(c=>c.listItemIds.includes(monitoringId)).map(c=>Promise.all([organizationApi.getContact(c.contactId),organizationApi.get(c.organizationId)])));
                this.infoMonitoring = items.map(i=>i[1].name  + (i[0]?(' | '+ i[0].firstName+' '+i[0].lastName+' | '+(i[0].mobileNumber || i[0].phoneNumber || "")):"") ).join("\n")
            },
            async getInfoMailReceivers(){
                let items = await Promise.all(this.project.involvedContacts.filter(c=>c.sendMail).map(c=>Promise.all([organizationApi.getContact(c.contactId),organizationApi.get(c.organizationId)])));
                this.infoMailReceivers = items.map(i=>i[1].name  + (i[0]?(' | '+ i[0].firstName+' '+i[0].lastName+' | '+(i[0].mobileNumber || i[0].phoneNumber || "")):"") ).join("\n")
            },
            async handleTradeItemChanged (id) {     
                
                if (id) {
                    let contactByTrade = this.project.involvedContacts.find(ic => ic.tradeId === id);
                    if (this.deficiency && contactByTrade) {
                        this.deficiency.organizationId = contactByTrade.organizationId;
                    }
                }
            },
            async handleOrganizationChanged(id) {
                if (id) {
                    let tradeByOrganization = this.project.involvedContacts.find(ic => ic.organizationId === id);
                    if (this.deficiency) {
                        if (tradeByOrganization) {
                            this.deficiency.tradeId = tradeByOrganization.tradeId;
                        }
                        else {
                            this.deficiency.tradeId = null;
                        }
                    }
                    

                }
            }
        }
    });
</script>


<style>

    .mobile-project {
        display: grid;
        grid-template-rows: max-content max-content max-content 1fr max-content;
        grid-auto-columns: minmax(0,1fr);
    }

    .mobile-form {
        padding: 5px 0;
    }
        .mobile-form .dx-scrollable-content {
            max-width: 100%;
        }
    .mobile-images {
        display: grid;
        grid-auto-columns: minmax(150px,1fr);
        grid-gap: 5px;
        grid-auto-flow: column;
    }
    .mobile-sub-head {
        padding: 10px;
        background: var(--color-accent);
        text-align: center;
    }
    .mobile-project-attachments {
        display: grid;
        grid-gap: 5px;
        grid-template-columns: 1fr max-content;
        align-items: center;
    }
    .mobile-num-button {
        display: inline-block;
        color: var(--color-text);
        padding: 4px;
        font-size: 21px;
        text-align: center;
        vertical-align: middle;
        width: 26px;
        height: 26px;
        margin: 0 5px;
        background: var(--color-dx-dark);
        border: 1px solid var(--color-background-light);
        border-radius: var(--default-border-radius);
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
    }
    .mobile-num-button-active{
        background-color: var(--color-accent);
    }
    .mobile-project-navi {
        display: grid;
        grid-template-columns: max-content 1fr max-content;
        grid-template-rows: max-content;
        grid-gap: 10px;
        align-items: flex-start;
    }
    .mobile-flow-scroll {
        overflow-x: auto;
        white-space: nowrap;
        padding: 5px;
    }
    .mobile-scroll {
        overflow-y: auto;
        padding: 5px;
        display:grid;
        grid-auto-rows: max-content;
        grid-gap: 10px;
        height: 100%;
    }
    .mobile-add-def {
        margin: 5px;
    }
    .mobile-flow-row {
        display: grid;
        grid-gap: 5px;
        grid-auto-rows: min-content;
        grid-auto-flow: row;
    }
    .border-color-warn {
        border-color: var(--color-warn);
    }
    .mobile-project-headline{
        display: grid;
        grid-template-columns: 1fr max-content;
    }
    .mobile-project-headline-text{
        text-align:center;
        padding: 5px;
    }
    .mobile-project-info {
        display: grid;
        grid-template-rows: max-content minmax(0,1fr);
        background: var(--color-accent);
        padding: 10px;
        grid-row: 3 / span 2;
        border-radius: 0 0 var(--default-border-radius) var(--default-border-radius);
    }
    .mobile-attachment-selected{
        border-color: var(--color-accent);
    }
    .mobile-head-fill-text {
        font-size: 1.2rem;
        padding-left: 10px;
        top: 3px;
        position: relative;
    }
</style>

