<template>
    <div>
        <span class="head-toolbar-button-compact" @click="popupVisible=!popupVisible" title="Ordner öffnen">folder</span>
        <DxPopup v-model:visible="popupVisible" :width="900" :height="450" :hide-on-outside-click="true" :show-close-button="true" title="Projektdateien">
            <template #content>
                <DxFileManager 
                               :file-system-provider="fileSystemProvider"
                               :item-view="{showParentFolder:false,showFolders:false,details:{columns:['thumbnail', 'name',{dataField: 'dateModified',sortIndex:1,sortOrder:'desc'}, 'size']}}">
                    <DxPermissions :download="true" :delete="true" :create="true" :upload="true" />
                </DxFileManager>
            </template>
        </DxPopup>
    </div>
</template>
<script>
    import projectAttachmentProvider from "../services/ProjectAttachmentProvider"    
    import { DxFileManager, DxPermissions } from 'devextreme-vue/file-manager';
    import { DxPopup, } from 'devextreme-vue/popup';
    export default {
        components:{
            DxPopup,
            DxFileManager,
            DxPermissions
        },
        data() {
            return {
                popupVisible:false,
                fileSystemProvider: null
            };
        },
        watch: {
            popupVisible() {
                this.$root.fileManagerOpen = this.popupVisible
            }
        },
        mounted() {
            this.fileSystemProvider = projectAttachmentProvider(this);
        },
    };
</script>
